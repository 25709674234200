import CCognations from "@/cognations/common/CCognations.vue";
import CCognationsLayout from "@/cognations/common/CCognationsLayout.vue";

// Мастерские
import WorkshopsLayoutVue from "@/workshops/WorkshopsLayout.vue";
import WorkshopBookingPage from "@/workshops/WorkshopBookingPage.vue";
import ChildWorkshops from "@/usersPages/ChildWorkshops.vue";
import WorkshopsBooking from "@/workshops/WorkshopsBooking.vue";

// Gallery
import GalleryLayout from "@/gallery/GalleryLayout.vue";
import AlbumbsList from "@/gallery/AlbumbsList.vue";
import PhotoGallery from "@/gallery/PhotoGallery.vue";

// reports
import WorkshopChildReport from "@/reports/workshops/WorkshopChildReport.vue";

// notes
import JournalsCommentsLayout from "@/components/notes/JournalsCommentsLayout.vue";
import JournalsCommentsList from "@/components/notes/JournalsCommentsList.vue";
import WorkshopsSchedule from "@/components/schedule/WorkshopsSchedule.vue"
import RReport from "@/reports/russian-literature/RReport.vue";

import CLayout from "@/consultations/CLayout.vue";
import CognationPage from "@/cognations/CognationPage.vue";

const consultations = {
  path: "/consultations",
  name: "consultations",
  component: CLayout,
};

const cognations = {
  path: "/cognations",
  name: "cognations",
  component: CCognationsLayout,

  children: [
    {
      path: "list",
      name: "common-cognations",
      component: CCognations,
    },
    {
      path: "cognation/:cognationId",
      name: "common-cognations-page",
      component: CognationPage,
    },
  ],
};

const workshopsScheduleRoute = {
  name: "workshops-timetable",
  path: "/workshops-timetable",
  component: WorkshopsSchedule,
};

const workshop = {
  path: "/workshop/:workshopId",
  component: WorkshopBookingPage,
  name: "workshop-page",
};

const workshops = {
  path: "/workshops",
  component: WorkshopsLayoutVue,
  children: [
    {
      name: "workshops-booking",
      path: "published/booking",
      component: WorkshopsBooking,
    },
    {
      name: "published-list",
      path: "published",
      component: ChildWorkshops,
    },
  ],
};

const gallery = {
  path: "/gallery",
  name: "gallery",
  component: GalleryLayout,

  children: [
    { path: "albums", name: "gallery-albums", component: AlbumbsList },
    {
      path: "album/:albumId/photos",
      name: "album-photos",
      component: PhotoGallery,
    },
  ],
};

const reports = [
  {
    name: "russian-literature-report",
    path: "/report/:subjectId",
    component: RReport,
  },
  {
    name: "workshop-child-report",
    path: "/workshop-child-report",
    component: WorkshopChildReport,
  },
];

const journalsComments = {
  name: "journals-coments",
  path: "/journals-comments",
  component: JournalsCommentsLayout,
  children: [
    {
      name: "child-journals-comments",
      path: "list",
      component: JournalsCommentsList,
    },
  ],
};

export const common = [
  consultations,
  workshops,
  gallery,
  journalsComments,
  cognations,
  workshopsScheduleRoute,
  ...reports,
  workshop,
];
