<template>
  <div class="compact-select">
    <select @change="$emit('update:modelValue', $event.target.value)">
      <option
        v-if="titleForEmptyOption && (modelValue === null || modelValue === '')"
        disabled
        selected
      >
        {{ titleForEmptyOption }}
      </option>

      <option
        v-for="opt in options"
        :key="opt.id"
        :value="opt.value ? opt.value : opt[valueField]"
        :selected="opt.value === modelValue"
      >
        <span v-if="opt.title">{{ opt.title }}</span>
        <span v-if="opt[titleFieldName]">{{ opt[titleFieldName] }}</span>
      </option>
    </select>

    <AppButton
      v-if="titleForEmptyOption && modelValue"
      @click="$emit('update:modelValue', null)"
    >
      <CIcon icon="cross-icon" />
    </AppButton>
  </div>
</template>

<script>
import AppButton from "./AppButton.vue";
import CIcon from "./CIcon.vue";

export default {
  components: { CIcon, AppButton },
  emits: ["update:modelValue"],
  props: [
    "modelValue",
    "options",
    "titleForEmptyOption",
    "titleFieldName",
    "valueField",
  ],

  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.compact-select {
  position: relative;
  background: #fff;
  
  button {
    position: absolute;
    top: 4px;
    right: 20px;
    font-weight: 600;

    svg {
      stroke: #000;
      height: 18px;
    }
  }

  select {
    width: 100%;
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    padding: 4px;
    padding-right: 20px;
    cursor: pointer;
    background: url("../assets/caret-down.png") no-repeat right 10px center /
      10px;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  option {
    color: inherit;
    background-color: #fff;
  }
}
</style>
