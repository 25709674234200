<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <router-link
        :to="{
          name: 'teacher-journal-edit',
          query: { filterByJournalId: $route.query.filterByJournalId },
        }"
        class="accent-button"
        >Редактировать</router-link
      >
    </div>
    <div class="page-wrapper">
      <h3 class="mb-3">Данные о журнале</h3>

      <ul class="list">
        <li class="my-2">
          <v-label class="mr-2">Название</v-label>
          <span>{{ journal.teacherLogName }}</span>
        </li>
        <li class="my-2">
          <v-label class="mr-2">Класс</v-label>
          <span>{{ journal.personGroup.personGroupName }}</span>
        </li>
        <li class="my-2">
          <v-label class="mr-2">Предмет</v-label>
          <span>{{ journal.scheduleClass.scheduleClassName }}</span>
        </li>
        <li class="my-2">
          <v-label class="mr-2">Описание</v-label>
          <span v-if="journal.teacherLogDescription" class="mb-3">
            {{ journal.teacherLogDescription }}
          </span>
          <span v-else>Нет описания</span>
        </li>
        <li class="my-2">
          <v-label class="mr-2">Период</v-label>
          <span>
            {{ getDateFormatWithoutTime(journal.beginDateTime) }} -
            {{ getDateFormatWithoutTime(journal.endDateTime) }}</span
          >
        </li>
        <li class="my-2">
          <v-label class="mr-2">Занятий</v-label>
          <span> {{ journal.days.length }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useDatesChanger } from "@/composables/datesChanger";

const { journal } = storeToRefs(useSubjectsJournalStore());

const { getDateFormatWithoutTime } = useDatesChanger();
</script>
