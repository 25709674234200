<template>
  <div class="table-wrapper">
    <table class="table-custom">
      <thead>
        <tr class="table-custom__headers">
          <th></th>
          <th v-for="(day, idx) in days" :key="idx">
            <p>{{ day.weekName }}</p>
            <p>{{ getDateDayMonth(day.date) }}</p>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="day in weekSchedule" :key="day.id">
          <td>
            <p>{{ day.time }}</p>
          </td>

          <td v-for="(events, idx2) in day.events" :key="idx2">
            <div v-for="(event, idx3) in events" :key="idx3">
              <p
                :style="{
                  backgroundColor: event.backgroundColor,
                  color: event.textColor,
                }"
                :class="{ hidden: hideEvent(event) }"
                class="rounded pa-1 font-weight-medium"
              >
                {{ event.title }}
              </p>

              <div v-if="event.groups && event.groups.length">
                <p
                  v-for="g in event.groups"
                  :key="g.id"
                  class="ma-1 bg-deep-purple-lighten-3 pa-1 rounded"
                >
                  {{ g.personGroupName }}
                </p>
              </div>

              <p v-if="event.subgroup && event.subgroup.subgroupName">Подгруппа: {{ event.subgroup.subgroupName }}</p>
              <p v-if="event.teacher" class="text-grey">{{ event.teacher }}</p>

              <v-divider
                v-if="events.length > 1 && idx3 < events.length - 1"
                class="my-3"
                :thickness="2"
                color="deep-purple"
              ></v-divider>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { shortNamesDaysDictionary } from "@/util/common";

const props = defineProps({
  headers: {
    type: Array,
    required: true,
  },
  weekSchedule: {
    type: Array,
    required: true,
  },
});

const { getDateDayMonth } = useDatesChanger();

const activities = [
  "прогулка",
  "обед",
  "завтрак",
  "полдник",
  "круг",
  "время прихода в школу",
];

const days = computed(() => {
  if (!props.headers) {
    return [];
  }
  return props.headers.map((v) => {
    return { date: v, weekName: getWeekName(v) };
  });
});

function getWeekName(date) {
  return shortNamesDaysDictionary[new Date(date).getDay()];
}

function hideEvent(event) {
  const isExisted = activities.indexOf(event.title.toLowerCase()) >= 0;

  return isExisted;
}
</script>
