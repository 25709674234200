import { defineStore } from "pinia";
import { useDatesChanger } from "@/composables/datesChanger";
export const useSubjectsJournalStore = defineStore("subjectsJournal", {
  state: () => ({
    ratingSheets: [],
    journal: null,
    journalDaysWithIds: [],
    dictations: [],
  }),
  getters: {
    dateFromJournal(state) {
      return (id) => {
        const { getDateFormatWithTime } = useDatesChanger();

        const day = state.journalDaysWithIds.find(
          (v) => v.teacherLogDayId === Number(id)
        );

        if (!day) {
          return null;
        }

        return getDateFormatWithTime(day.teacherLogDayDate);
      };
    },

    isForeignJournal() {      
      if (!this.journal) {
        return false;
      }

      return this.journal.scheduleClassId === 13;
    },
    allowedDates() {
      if (!this.journalDaysWithIds) {
        return [];
      }
      return this.journalDaysWithIds.map((v) => v.teacherLogDayDate);
    },
    minDate() {
      if (!this.journal) {
        return null;
      }
      const d = new Date(this.journal.days[0].dayDate);
      return d;
    },
    existedKids() {
      if (!this.journal || (this.journal && !this.journal.days.length)) {
        return [];
      }

      return this.journal.days[0].childs;
    },
    journalKidsForTable() {
      if (!this.journal || (this.journal && !this.journal.days.length)) {
        return [];
      }

      if (this.journal) {
        return this.journal.days[0].childs.map((child) => {
          return {
            childId: child.child.id,
            dates: setChildListOfDates(child.child.id, this.journal.days),
            name: `${child.child.lastname} ${child.child.firstname}`,
          };
        });
      }

      function setChildListOfDates(id, days) {
        const result = [];

        days.forEach((day) => {
          day.childs.forEach((child) => {
            if (id === child.childId) {
              result.push({
                date: day.dayDate,
                dayChildId: child.id,
                teacherComment: child.teacherComment,
                attendanceStatus: child.attendanceStatus,
              });
            }
          });
        });

        return result;
      }
    },
  },
  actions: {
    setDictations(list) {
      this.dictations = list;
    },
    setRatingSheets(list) {
      this.ratingSheets = list;
    },
    setJournal(journal) {
      this.journal = journal;
    },
    setJournalDaysWithIds(ids) {
      this.journalDaysWithIds = ids;
    },
  },
});
