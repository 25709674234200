<template>
  <v-autocomplete
    v-if="subjectsSelector.length"
    variant="outlined"
    :items="subjectsSelector"
    placeholder="Предмет"
    hide-details
    density="compact"
    bg-color="white"
    :modelValue="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="item.raw.title"></v-list-item>
    </template>
  </v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useSchoolSubjectsData } from "@/composables/schoolSubjects";
import { onMounted, defineProps, defineEmits } from "vue";
const { subjectsSelector, getSchoolSubjects } = useSchoolSubjectsData();

defineProps(["modelValue"]);
defineEmits(["update:modelValue"]);

onMounted(getSchoolSubjects);
</script>
