<template>
  <div>
    <PageTitle
      :title="`${isParent ? 'Мастерские ребёнка' : 'Мои мастерские'}`"
    />

    <div class="g-col g-col--2-1fr mb-3">
      <FilterByYear />
      <FilterByChild v-if="isParent" />
    </div>

    <StatusWrapper
      :is-data-existed="workshops.length > 0"
      :loading-status="loadingStatus"
    >
      <ul class="list">
        <li v-for="w in workshops" :key="w.id" class="gradient-list-item">
          <router-link
            :to="{
              name: 'workshop-page',
              params: { workshopId: w.id },
            }"
            class="gradient-list-item__link-with-period gradient-list-item__content"
          >
            <h4>
              <span v-if="w.workshopName">{{ w.workshopName }}</span>
              <span v-else>Мастерская {{ w.id }}</span>
            </h4>

            <div
              v-if="isExpiredDate(w.endDateTime)"
              class="gradient-list-item__period text-grey"
            >
              Завершена {{ getDateFormatWithoutTime(w.endDateTime) }}
            </div>

            <div v-else class="gradient-list-item__period">
              <CIcon icon="calendar-icon" />
              {{ getDateFormatWithoutTime(w.beginDateTime) }} -
              {{ getDateFormatWithoutTime(w.endDateTime) }}
            </div>
          </router-link>
        </li>
      </ul>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useAuthStore } from "@/store/auth";
import { useWorkshopStore } from "@/store/workshop";

import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { isExpiredDate } from "@/util/common";

import { useDatesChanger } from "@/composables/datesChanger";
import { pushFilterToURL } from "@/service/router";
import { customAxios } from "@/service/customAxios";

import { usePopupStore } from "@/store/popup";

import CIcon from "@/elements/CIcon.vue";
import PageTitle from "@/elements/PageTitle.vue";

import FilterByChild from "@/components/filtersElements/FilterByChild.vue";
import FilterByYear from "@/components/filtersElements/FilterByYear.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";

const { getDateFormatWithoutTime } = useDatesChanger();
const { workshops } = storeToRefs(useWorkshopStore());
const { setWorkshops } = useWorkshopStore();
const { showError } = usePopupStore();

const { isChild, userId, isParent } = useAuthStore();
const route = useRoute();

const loadingStatus = ref("nothing");

onMounted(() => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
    getWorkshops();
  }
});

watch(() => route.query.filterByChildId, getWorkshops);
watch(() => route.query.filterByYear, getWorkshops);

async function getWorkshops() {
  if (!route.query.filterByYear || !route.query.filterByChildId) {
    return;
  }
  loadingStatus.value = "loading";

  try {
    const r = await customAxios.post("/workshop/list_workshops_for_child", {
      studyYearPeriodId: Number(route.query.filterByYear),
      childId: Number(route.query.filterByChildId),
    });
    setWorkshops(r.data);
  } catch (error) {
    showError(error);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>
