<template>
  <div>
    <router-link
      :to="{
        name: 'dictations',
        query: {
          filterByJournalId: $route.query.filterByJournalId,
          dayId: $route.query.dayId,
        },
      }"
      class="bordered-item mb-3"
      >Назад</router-link
    >

    <div v-if="sheet">
      <DictationEditor :sheet="sheet" />
      <DictationTable :sheet="sheet" class="my-5" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { storeToRefs } from "pinia";
import { useJournalData } from "@/composables/journalData";

import DictationEditor from "./DictationEditor.vue";
import DictationTable from "./DictationTable.vue";

const { dictations } = storeToRefs(useSubjectsJournalStore());
const { updateDictations } = useJournalData();

const route = useRoute();

onMounted(updateDictations);

const sheet = computed(() => {
  if (!dictations.value.length) {
    return null;
  }

  return dictations.value.find((v) => v.id === Number(route.query.listId));
});
</script>
