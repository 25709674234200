<template>
  <div>
    <PageTitle title="Журналы посещения" />

    <div class="g-col g-col--2-1fr">
      <FilterByGroupId />
    </div>

    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="journals.length > 0"
      :key="version"
    >
      <ComponentWithPagination
        :list="filteredJournals"
        @changeList="paginatedData = $event"
      >
        <ul class="mt-4">
          <li v-for="j in paginatedData" :key="j.id" class="gradient-list-item">
            <router-link
              class="gradient-list-item__link-with-period gradient-list-item__content"
              :to="{ name: 'attendance-journal', params: { id: j.id } }"
            >
              <h4>{{ j.name }} ({{ j.group.personGroupName }})</h4>
              <div class="gradient-list-item__period">
                <CIcon icon="calendar-icon" />
                <span
                  >{{ getDateFormatWithoutTime(j.beginDateTime) }} -
                  {{ getDateFormatWithoutTime(j.endDateTime) }}</span
                >
              </div>
            </router-link>
          </li>
        </ul>
      </ComponentWithPagination>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { ref, computed, onMounted } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import { usePopupStore } from "@/store/popup";
import StatusWrapper from "@/components/StatusWrapper.vue";

import CIcon from "@/elements/CIcon.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";
import PageTitle from "@/elements/PageTitle.vue";
import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";
import { useRoute } from "vue-router";

const { getDateFormatWithoutTime } = useDatesChanger();
const { showError } = usePopupStore();

const loadingStatus = ref("loading");
const journals = ref([]);
const route = useRoute();
const paginatedData = ref([]);

onMounted(getAllJournals);

async function getAllJournals() {
  loadingStatus.value = "loading";

  try {
    const r = await getAtendanceJournalsData();
    journals.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getAtendanceJournalsData() {
  return customAxios.get(`/attendance`);
}

const filteredJournals = computed(() => {
  if (!journals.value.length) {
    return [];
  }

  return route.query.filterByGroupId
    ? journals.value.filter(
        (j) => j.userGroupId === Number(route.query.filterByGroupId)
      )
    : journals.value;
});
</script>
