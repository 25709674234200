<template>
  <div v-if="sortedChildren.length" class="page-wrapper">
    <div class="table-wrapper">
      <table class="table-custom">
        <thead>
          <tr class="table-custom__headers">
            <th></th>
            <th>Уровень</th>
            <th>Компетенции</th>
          </tr>
        </thead>
        <tbody>
          <DictationRow
            v-for="(c, i) in sortedChildren"
            :key="c.id"
            :idx="i"
            :child="c"
            @click="lastEditRowId = i"
            :class="{ 'table-custom__last-visited': lastEditRowId === i }"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import { sortByChildLastname } from "@/util/common";

import DictationRow from "./DictationRow.vue";

const props = defineProps({ sheet: { type: Object, required: true } });

const lastEditRowId = ref(null);

const sortedChildren = computed(() => {
  if (!props.sheet || !props.sheet.childList.length) {
    return [];
  }

  return sortByChildLastname(props.sheet.childList);
});
</script>
