<template>
  <div>
    <PageTitle title="Заметки из журналов по детям" />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>

      <template v-slot:content>
        <p class="mb-3">Здесь отобразятся Заметки.</p>

        <div v-if="isParent || isTutor">
          <v-label> Обязательно выставьте фильтры</v-label>
          <ol class="pl-5">
            <li v-if="isTutor"><strong>класс</strong></li>
            <li><strong>ребенок</strong></li>
          </ol>
        </div>

        <p>Опционально: можете пофильтровать по <strong>получателю</strong></p>
      </template>
    </TogglerOfWrapper>

    <FilterByChild v-if="isParent" />
    <FilterByGroupAndChild v-if="isTutor" />

    <FilterToggler v-if="notes.length > 0">
      <FilterByRecipient />
    </FilterToggler>

    <ComponentWithPagination
      :list="filteredSortedItems"
      @changeList="paginatedList = $event"
    >
      <ul class="list">
        <li v-for="n in paginatedList" :key="n.id" class="my-2">
          <router-link
            v-if="isTutor"
            :to="{
              name: 'teacher-journal',
              query: { filterByJournalId: n.journalId },
            }"
            class="link"
          >
            <CommentPreview :comment="n" />
          </router-link>
          <CommentPreview v-else :comment="n" />
        </li>
      </ul>
    </ComponentWithPagination>
  </div>
</template>

<script setup>
import { useNotesStore } from "@/store/notes";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useNotes } from "@/composables/notes";
import { useAuthStore } from "@/store/auth";

import PageTitle from "@/elements/PageTitle.vue";
import ComponentWithPagination from "../ComponentWithPagination.vue";
import FilterByGroupAndChild from "../filtersElements/FilterByGroupAndChild.vue";

import FilterByChild from "../filtersElements/FilterByChild.vue";
import CommentPreview from "./components/CommentPreview.vue";
import FilterByRecipient from "../filtersElements/FilterByRecipient.vue";
import FilterToggler from "../elements/FilterToggler.vue";
import TogglerOfWrapper from "../elements/TogglerOfWrapper.vue";

const { isParent, isTutor } = useAuthStore();
const { notes } = storeToRefs(useNotesStore());

const { getFilteredSortedItems, paginatedList } = useNotes();

const filteredSortedItems = computed(() => getFilteredSortedItems(notes.value));
</script>
