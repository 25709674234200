<template>
  <div>
    <div class="page-wrapper master-reports">
      <h3 class="mb-5">Отчёт: Диктанты</h3>

      <div v-if="userGroups.length" class="mb-4">
        <v-autocomplete
          v-model="selectedUserGroup"
          :items="userGroups"
          required
          label="Класс"
          item-title="personGroupName"
          return-object
          density="compact"
          variant="outlined"
          :rules="requiredField"
          hide-details
        />
      </div>
      <div
        v-if="selectedUserGroup && selectedUserGroup.groupPersons"
        class="mb-4"
      >
        <v-autocomplete
          v-model="selectedChild"
          :items="selectedUserGroup.groupPersons"
          required
          label="Ребенок"
          item-title="firstname"
          return-object
          density="compact"
          variant="outlined"
          :rules="requiredField"
          hide-details
          v-on:update:modelValue="getReportData"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="
                item.raw.lastname +
                ' ' +
                item.raw.firstname +
                ' ' +
                item.raw.secname
              "
            ></v-list-item>
          </template>
        </v-autocomplete>
      </div>

      <StatusWrapper
        :loadingStatus="loading"
        :isDataExisted="reportDataSet.length > 0"
      >
        <v-card v-for="r in reportDataSet" :key="r.id" class="ma-4">
          <v-card-item class="form__button--remove text-white">
            <v-card-title>
              {{ r.dictation.dictationName }}
            </v-card-title>
            <v-card-subtitle class="text-white">
              {{
                r.dictation.scheduleEvent.scheduleClass.scheduleClassName
              }}
              ({{ formatDate(r.dictation.scheduleEvent.eventBeginDateTime) }})
            </v-card-subtitle>
          </v-card-item>

          <v-card-text>
            <div class="pt-4">
              <p><b>Уровень: </b> {{ r.dictationLevel }}</p>
            </div>
          </v-card-text>

          <v-card-text>
            <div class="pt-2">
              <p><b>Компетенции, где допущена ошибка: </b></p>
              <v-chip-group
                selected-class="text-primary"
                column
                v-if="
                  r.failedCompetenceList && r.failedCompetenceList.length > 0
                "
              >
                <v-chip
                  class="ma-2"
                  color="primary"
                  variant="outlined"
                  size="large"
                  v-for="f in r.failedCompetenceList"
                  :key="f.id"
                  v-on:click="showFailInfo(f)"
                >
                  {{ f.competenceName }} ({{ f.failCount }})
                </v-chip>
              </v-chip-group>
              <p v-else><b>Список пуст</b></p>
            </div>
          </v-card-text>
        </v-card>
      </StatusWrapper>
    </div>

    <div class="pa-4 text-center">
      <v-dialog v-model="showErrorInfo" max-width="800">
        <v-card>
          <v-card-title class="form__button--remove text-white">
            {{ selectedErrorInfo.competenceName }}
          </v-card-title>
          <v-card-text>
            <v-card-title>
              Диктанты, в которых встречается ошибка:
            </v-card-title>
            <v-list lines="two">
              <v-list-item
                v-for="n in selectedErrorInfo.dictationsWithFail"
                :key="n"
                :title="n.dictationName"
                :subtitle="
                  n.scheduleEvent.scheduleClass.scheduleClassName +
                  ' (' +
                  formatDate(n.scheduleEvent.eventBeginDateTime) +
                  ')'
                "
                v-on:click="openDictation(n.id)"
              >
                <v-divider></v-divider>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-actions class="form__button--remove text-white">
            <v-spacer></v-spacer>

            <v-btn
              text="Закрыть"
              variant="outlined"
              rounded
              @click="showErrorInfo = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div
      v-if="reports.length === 0 && loading === 'nothing'"
      class="page-wrapper mt-4"
    >
      <p>Здесь появятся отчёты</p>
    </div>
  </div>
</template>

<script>
import { customAxios } from "@/service/customAxios";
import { onMounted, ref, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import { usePopupStore } from "@/store/popup";
import { requiredField } from "@/util/inputsRules";
import { sortByLastName, schoolPeriods } from "@/util/common";

import { useUserData } from "@/composables/userData";

import StatusWrapper from "@/components/StatusWrapper.vue";

export default {
  components: {
    StatusWrapper,
  },

  setup() {
    const { downloadReport } = useUserData();
    const { showError } = usePopupStore();
    const { getRangeUIFormat, getDateFormatWithoutTime } = useDatesChanger();

    const tableRef = ref();
    const loading = ref("nothing");
    const period = ref("");
    const reports = ref([]);
    const selectedMaster = ref(null);

    const userGroups = ref([]);
    const childs = ref([]);
    const selectedUserGroup = ref("");
    const selectedChild = ref("");

    const reportDataSet = ref([]);

    const selectedErrorInfo = ref([]);
    const showErrorInfo = ref(false);

    onMounted(getUsers);

    watch(period, () => {});

    watch(selectedMaster, () => {});
    function formatDate(dateString) {
      let formatter = new Intl.DateTimeFormat("ru", {
        // weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });

      return capitalizeFirstLetter(formatter.format(Date.parse(dateString)));
    }
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async function getUsers() {
      try {
        loading.value = "loading";
        const r = await getUserGroups();
        userGroups.value = r.data;
      } catch (e) {
        showError(e);
      } finally {
        loading.value = "loaded";
      }
    }

    function openDictation(id) {
      window.open("/teacher-journal/dictations/dictation/" + id, "_blank");
    }

    async function getReportData() {
      if (selectedChild.value && selectedChild.value.id) {
        try {
          loading.value = "loading";
          const r = await getUserDictationSet(selectedChild.value.id);
          reportDataSet.value = r.data;
          console.log("reportDataSet:" + reportDataSet.value);
        } catch (e) {
          showError(e);
        } finally {
          loading.value = "loaded";
        }
      }
    }

    async function getUserGroups() {
      return await customAxios.get("/user_group/childs/active");
    }

    async function getUserDictationSet(id) {
      return await customAxios.get("/dictation/child_dictations/" + id);
    }

    function showFailInfo(f) {
      selectedErrorInfo.value = f;
      showErrorInfo.value = true;
    }

    function download() {
      const title =
        "отчёт_мастер_" +
        getDateFormatWithoutTime(period.value[0]) +
        "-" +
        getDateFormatWithoutTime(period.value[1]);

      downloadReport(title, tableRef.value);
    }

    return {
      download,
      tableRef,
      loading,
      reports,
      selectedMaster,
      period,
      requiredField,
      getRangeUIFormat,
      sortByLastName,
      schoolPeriods,

      userGroups,
      childs,
      selectedChild,
      selectedUserGroup,
      reportDataSet,

      getReportData,
      formatDate,
      selectedErrorInfo,
      showFailInfo,
      showErrorInfo,
      openDictation,
    };
  },
};
</script>
