<template>
  <div class="site-footer">
    <ul class="list">
      <li>
        <router-link to="/personal-agreement"
          >Политика обработки ПД</router-link
        >
      </li>
      <li>
        <router-link to="/user-agreement">
          Пользовательское соглашение</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { useTheme } from "vuetify";

export default {
  name: "SiteFooter",
  setup() {
    const theme = useTheme();

    return {
      theme,
    };
  },
  data() {
    return {
      showPersonalAgree: false,
      showUserAgreement: false,
    };
  },
};
</script>

<style lang="scss">
.site-footer {
  background: $light-gradient;
  padding: 20px 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 8px;

  a {
    color: #fff;
    text-decoration: none;
    padding: 8px;
  }

  li {
    margin-top: 16px;
    list-style: none;

    &:hover a {
      text-decoration: underline;
    }
  }
}
</style>
