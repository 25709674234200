<template>
  <v-card class="pa-2">
    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="activeLines.length > 0"
    >
      <div class="d-flex justify-center">
        <AppButton
          @click="$emit(`editChildsCompetence`, currentCompetences)"
          class="bordered-item my-2"
          >Сохранить</AppButton
        >
      </div>
      <h4>Критерии ФГОС, ФООП</h4>

      <v-list v-if="activeLines.length">
        <v-list-group v-for="line in activeLines" :key="line.id">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" :title="line.competenceLineName">
            </v-list-item>
          </template>

          <v-list-group
            v-for="chapter in line.competenceChapters"
            :key="chapter.id"
          >
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                :title="chapter.competenceChapterName"
              >
              </v-list-item>
            </template>

            <v-list-item
              v-for="competence in chapter.competences"
              :key="competence.id"
            >
              <label>
                <input
                  type="checkbox"
                  @click="toggleSkills(competence)"
                  :checked="setCheckedStatus(competence.id)"
                />
                {{ competence.competenceName }}
              </label>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>

      <p v-else>Нет привязанных критериев</p>
    </StatusWrapper>
  </v-card>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { customAxios } from "@/service/customAxios";

import StatusWrapper from "./StatusWrapper.vue";
import AppButton from "@/elements/AppButton.vue";

const props = defineProps(['addedItems'])
const { journal } = storeToRefs(useSubjectsJournalStore());

const loadingStatus = ref("loading");
const competenceLines = ref([]);
const currentCompetences = ref(props.addedItems.length ? props.addedItems : []);

const activeLines = computed(() => {
  if (!competenceLines.value.length) {
    return [];
  }

  return competenceLines.value.filter((v) => v.active === true);
});

function setCheckedStatus(id) {
  if (!currentCompetences.value.length) {
    return false;
  }

  return currentCompetences.value.findIndex((v) => v.id === id) >= 0;
}

function toggleSkills(item) {
  let arr = currentCompetences.value.slice();
  
  const isExisted =
    currentCompetences.value.findIndex((v) => v.id === item.id) >= 0;

  if (isExisted) {
    arr = arr.filter((v) => v.id !== item.id);
  } else {
    arr.push(item);
  }

  currentCompetences.value = arr;
}

onMounted(update)

async function update() {  
  console.log(props.addedItems);
  
  if (!journal.value) {
    return;
  }
  loadingStatus.value = "loading";
  try {
    const r = await updateHandler();
    competenceLines.value = r.data;
  } catch (e) {
    console.log(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function updateHandler() {
  return customAxios.get(
    `/schedule/competence/line/by_class/${journal.value.scheduleClassId}`
  );
}
</script>
