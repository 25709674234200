<template>
  <div>
    <div v-if="cognation.cognitionVideos.length">
      <p class="text-grey mt-2 mb-5">
        Для воспроизведения нажмите на название файла
      </p>

      <ul class="list mb-2">
        <li v-for="video in cognation.cognitionVideos" :key="video.id">
          <AppButton
            @click="show(video)"
            class="bordered-item d-flex align-center justify-space-between pa-2"
          >
            <p class="mr-3">
              <span v-if="video.originalFileName">{{
                video.originalFileName
              }}</span>
              <span v-else>Файл {{ i + 1 }}</span>
            </p>

            <v-icon icon="mdi-play"></v-icon>
          </AppButton>
        </li>
      </ul>

      <v-dialog v-model="visible">
        <div class="d-flex justify-end">
          <AppButton @click="hide" class="accent-button"
            >Закрыть</AppButton
          >
        </div>

        <div v-if="selectedVideo" class="d-flex justify-center">
          <videoPlay
            v-bind="options"
            :poster="
              'https://school.eurekacenter.ru/video' + selectedVideo.posterName
            "
          />
        </div>
      </v-dialog>
    </div>

    <div v-else class="mt-3 text-grey">Нет файлов</div>
  </div>
</template>

<script setup>
import "vue3-video-play/dist/style.css";
import { videoPlay } from "vue3-video-play";
import AppButton from "@/elements/AppButton.vue";
import { computed, ref, defineProps } from "vue";

defineProps({ cognation: { type: Object, required: true } });
const selectedVideo = ref(null);
const visible = ref(false);

function show(video) {
  selectedVideo.value = video
  visible.value = true
}

function hide() {
  selectedVideo.value = null
  visible.value = false
}

const options = computed(() => ({
  src:
    "https://new-school.geekbase.ru/video" + selectedVideo.value.sourceHlsIndex,
  type: "m3u8",
  webFullScreen: false,
  control: true,
  muted: true,
  controlBtns: [
    "audioTrack",
    "quality",
    "speedRate",
    "volume",
    "setting",
    "pip",
    "pageFullScreen",
    "fullScreen",
  ],
}));
</script>

<style lang="scss"></style>
