<template>
  <div>
    <PageTitle title="Расписание мастерских" />

    <div class="page-wrapper mb-3">
      <div class="g-col g-col--2-1fr">
        <WeekRangeFilter v-model="period" />
        <FilterByChild v-if="isParent" />
      </div>
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится расписание.</p>
        <p>
          Выставьте: <strong>неделю</strong
          ><span v-if="isParent">&nbsp;и <strong>ребёнка</strong></span
          >.
        </p>
      </template>
    </TogglerOfWrapper>

    <LoaderRing v-if="loadingStatus === 'loading'" />

    <ComponentWithPagination
      v-else
      :list="timetable"
      @changeList="paginatedList = $event"
    >
      <div class="cards-grid">
        <WorkshopPreview v-for="(d, i) in paginatedList" :key="i" :item="d" />
      </div>
    </ComponentWithPagination>
  </div>
</template>

<script setup>
import { usePopupStore } from "@/store/popup";
import { onMounted, ref, watch } from "vue";
import { useWorkshopsData } from "@/composables/workshops";

import { useAuthStore } from "@/store/auth";
import { useRoute } from "vue-router";
import { pushFilterToURL } from "@/service/router";

import LoaderRing from "@/elements/LoaderRing.vue";
import PageTitle from "@/elements/PageTitle.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";

import FilterByChild from "@/components/filtersElements/FilterByChild.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";

import { useDatesChanger } from "@/composables/datesChanger";
import WeekRangeFilter from "@/components/WeekRangeFilter.vue";
import WorkshopPreview from "@/workshops/WorkshopPreview.vue";

const { getCurrentWeek } = useDatesChanger();
const { isParent, isChild, userId } = useAuthStore();
const { showError } = usePopupStore();
const route = useRoute();

const { updateTimetableHandler } = useWorkshopsData();

const loadingStatus = ref("nothing");
const timetable = ref([]);
const paginatedList = ref([]);
const period = ref(getCurrentWeek());

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }

  updateTimetable();
});

watch(period, updateTimetable);

watch(() => route.query.filterByChildId, updateTimetable);

async function updateTimetable() {
  if (!period.value.length) {
    return;
  }

  loadingStatus.value = "loading";
  try {
    const r = await updateTimetableHandler({
      beginDate: period.value[0],
      endDate: period.value[1],
      childId: Number(route.query.filterByChildId),
    });
    timetable.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>
