<template>
  <v-container class="wrapper mt-10 pa-0">
    <TableElement>
      <template v-slot:tabs>


        <v-tabs bg-color="primary-dark" v-model="part">
          <v-tab value="1">Мои достижения</v-tab>
          <v-tab v-if="isAdmin || isTeacher || isTutor || isMaster" value="2"
          >Администрирование достижений</v-tab
          >

          <v-tab v-if="isAdmin || isTeacher || isTutor || isMaster" value="3"
          >Рефлексия для родителей</v-tab
          >
          <v-tab v-if="isAdmin || isTeacher || isTutor || isMaster" value="4" v-on:click="part=4"
          >Область достижения</v-tab
          >
          <v-tab v-if="isAdmin || isTeacher || isTutor || isMaster" value="6"
          >Общие уровни оценивания</v-tab>
        </v-tabs>
      </template>
      <template v-slot:window>
        <v-card-text class="align-self-stretch ma-0 pa-0">
          <v-window v-model="part" class="align-self-stretch">
            <v-window-item value="1" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Мои достижения</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="myCognationSearchBody"
                        label="Найти достижение"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-title class="card_ligth_header">
                <v-row class="d-flex justify-space-between pa-3">
                  <v-btn
                      @click="myCognationDialog = true"
                      variant="outlined"
                      color="primary-dark"
                      class="mb-4"
                  >
                    Добавить достижение
                  </v-btn>

                  <v-btn
                      @click="showMyFiltersDialog = true"
                      variant="outlined"
                      color="primary"
                      class="mb-4"
                  >
                    Фильтры
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                      variant="tonal"
                      class="ma-5 mx-5 w-100"
                      v-for="item in myCognationPaginatedData"
                      :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h6 class="text-white">Достижение</h6>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.cognationName
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.cognationDescription
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Добавлено:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.cognationCreateDate.replace("T", " ")
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Область:</v-list-item-title>
                        <v-list-item-subtitle v-if="item.cognationCategory!==null">{{
                            item.cognationCategory.name
                          }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-red" v-else>
                            Категория не выбрана
                          </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Файлы:</v-list-item-title>
                        <v-chip-group  v-if="item.cognationFiles!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              @click:close="deleteFile(file, item)"
                              closable
                              size="small"
                              v-for="file in item.cognationFiles"
                              :key="file"
                          >
                            {{ file.fileDescrption }}
                            <template v-slot:prepend>
                              <v-avatar  style="width: 24px; height: 24px;"  v-if="file.fileType!=='pdf'" class="text-shades-white" @click="showImage(file)">
                                <v-img  v-if="file.lazyRandomFileName!==null" :src="`https://school.eurekacenter.ru/cognation_files/${file.lazyRandomFileName}`">
                                  <template v-slot:placeholder>
                                    <div class="d-flex align-center justify-center fill-height">
                                      <v-progress-circular
                                          color="grey-lighten-4"
                                          indeterminate
                                      ></v-progress-circular>
                                    </div>
                                  </template>
                                </v-img>
                                <font-awesome-icon v-else class="text-shades-white" icon="fa-solid fa-image"/>
                              </v-avatar>
                              <v-avatar v-else  @click="downloadFile(file)">
                                <font-awesome-icon class="text-shades-white" icon="fa-solid fa-download"/>
                              </v-avatar>
                            </template>
                          </v-chip>
                        </v-chip-group>
                        <v-chip-group v-if="item.cognationFiles===null" active-class="primary--text" column>
                          <v-chip small  size="small"> Файлов нет </v-chip>
                        </v-chip-group>
                        <v-chip-group  v-if="item.cognitionVideos!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              closable
                              size="small"
                              v-for="file in item.cognitionVideos"
                              :key="file"
                              @click:close="deleteVideoFile(file)"
                          >
                            <p v-if="!!file.processed">{{ file.originalFileName }} (Видео обрабатывается)</p>
                            <p v-else>{{ file.originalFileName }} </p>
                            <template v-slot:prepend>
                              <v-progress-circular v-if="!!file.processed"
                                                   color="grey-lighten-4"
                                                   indeterminate
                                                   size="13"
                              ></v-progress-circular>
                              <font-awesome-icon v-else  icon="fa-solid fa-play" @click="playVideo(file)" />

                            </template>
                          </v-chip>
                        </v-chip-group>
                      </v-list-item>

                      <v-list-item lines="two">
                        <v-list-item-title>Модерация:</v-list-item-title>
                        <v-list-item-subtitle
                        ><v-chip v-if="item.isModerated">Пройдена</v-chip
                        ><v-chip v-else
                        >Не пройдена</v-chip
                        ></v-list-item-subtitle
                        >
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions">
                      <v-btn
                          @click="editMyCognation(item)"
                          style="margin: 5px"
                          mx-1
                          variant="outlined"
                          rounded
                          color="primary"
                      >Редактировать
                      </v-btn>
                      <v-btn
                          @click="addMyFile(item)"
                          style="margin: 5px"
                          mx-1
                          variant="outlined"
                          rounded
                          color="primary-dark"
                      >Добавить файл</v-btn
                      >
                      <v-btn
                          @click="addMyVideoFile(item)"
                          style="margin: 5px"
                          mx-1
                          variant="outlined"
                          rounded
                          color="primary-dark"
                      >Добавить видео файл</v-btn
                      >
                      <v-btn
                          @click="deleteMyCognation(item)"
                          style="margin: 5px"
                          variant="outlined"
                          rounded
                          color="warning"
                      >Удалить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header height="100vh">
                    <thead>
                    <tr>
                      <th class="text-left">
                        <b>Название</b>
                      </th>
                      <th class="text-left">
                        <b>Описание</b>
                      </th>
                      <th class="text-left">
                        <b>Добавлено</b>
                      </th>
                      <th class="text-left">
                        <b>Область</b>
                      </th>
                      <th class="text-left">
                        <b>Файлы</b>
                      </th>
                      <th class="text-left">
                        <b>Модерация</b>
                      </th>
                      <th class="text-left">
                        <b>Действие</b>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in myCognationPaginatedData"
                        :key="item.id"
                    >
                      <td>
                        <p>{{ item.cognationName }}</p>
                      </td>
                      <td>
                        <p>{{ item.cognationDescription }}</p>
                      </td>
                      <td>
                        <p>
                          {{ item.cognationCreateDate.replace("T", " ") }}
                        </p>
                      </td>
                      <td>
                        <p v-if="item.cognationCategory!==null">{{ item.cognationCategory.name }}</p>
                        <p v-else class="text-red">Категория не выбрана</p>
                      </td>
                      <td>
                        <v-chip-group  v-if="item.cognationFiles!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              @click:close="deleteFile(file, item)"
                              closable
                              size="small"
                              v-for="file in item.cognationFiles"
                              :key="file"
                          >
                            {{ file.fileDescrption }}
                            <template v-slot:prepend>
                              <v-avatar  style="width: 24px; height: 24px;"  v-if="file.fileType!=='pdf'" class="text-shades-white" @click="showImage(file)">
                                <v-img  v-if="file.lazyRandomFileName!==null" :src="`https://school.eurekacenter.ru/cognation_files/${file.lazyRandomFileName}`">
                                  <template v-slot:placeholder>
                                    <div class="d-flex align-center justify-center fill-height">
                                      <v-progress-circular
                                          color="grey-lighten-4"
                                          indeterminate
                                      ></v-progress-circular>
                                    </div>
                                  </template>
                                </v-img>
                                <font-awesome-icon v-else class="text-shades-white" icon="fa-solid fa-image"/>
                              </v-avatar>
                              <v-avatar v-else  @click="downloadFile(file)">
                                <font-awesome-icon class="text-shades-white" icon="fa-solid fa-download"/>
                              </v-avatar>
                            </template>
                          </v-chip>
                        </v-chip-group>
                        <v-chip-group v-if="item.cognationFiles===null" active-class="primary--text" column>
                          <v-chip small  size="small"> Файлов нет </v-chip>
                        </v-chip-group>

                        <v-chip-group  v-if="item.cognitionVideos!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              closable
                              size="small"
                              v-for="file in item.cognitionVideos"
                              :key="file"
                              @click:close="deleteVideoFile(file)"
                          >
                            <p v-if="!!file.processed">{{ file.originalFileName }} (Видео обрабатывается)</p>
                            <p v-else>{{ file.originalFileName }} </p>
                            <template v-slot:prepend>
                              <v-progress-circular v-if="!!file.processed"
                                  color="grey-lighten-4"
                                  indeterminate
                                                   size="13"
                              ></v-progress-circular>
                              <font-awesome-icon v-else  icon="fa-solid fa-play" @click="playVideo(file)" />

                            </template>
                          </v-chip>
                        </v-chip-group>
                      </td>
                      <td>
                        <v-chip size="small" v-if="item.isModerated"
                        >Пройдена</v-chip
                        ><v-chip size="small" v-else>Не пройдена</v-chip>
                      </td>
                      <td class="pa-2">
                        <p>
                          <v-btn
                              @click="editMyCognation(item)"
                              variant="outlined"
                              color="primary"
                              rounded
                              class="my-2"
                          >Редактировать
                          </v-btn>
                          <v-btn
                              @click="addMyVideoFile(item)"
                              style="margin: 5px"
                              mx-1
                              variant="outlined"
                              rounded
                              color="primary-dark"
                          >Добавить видео файл</v-btn
                          >
                          <v-btn
                              @click="addMyFile(item)"
                              variant="outlined"
                              color="primary-dark"
                              rounded
                              class="my-2"
                          >Добавить файл
                          </v-btn>
                          <v-btn
                              @click="deleteMyCognation(item)"
                              variant="outlined"
                              rounded
                              color="warning"
                              class="my-2"
                          >Удалить
                          </v-btn>
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-card-actions
                  class="card_ligth_actions"
                  style="min-height: 5vh"
              >
                <v-container class="text-center">
                  <v-pagination
                      v-model="myCognationPage"
                      :length="myCognationPageCount"
                      :total-visible="3"
                      size="small"
                  ></v-pagination>
                </v-container>
              </v-card-actions>
            </v-window-item>
            <v-window-item v-if="isAdmin || isTeacher || isTutor || isMaster" value="2" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Администрирование достижений</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="cognationSearchBody"
                        label="Найти"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-title class="card_ligth_header">
                <v-row class="d-flex pa-3 justify-space-between">
                  <v-btn
                      @click="openCognationAdminForm"
                      variant="outlined"
                      color="primary-dark"
                      class="mb-4"
                  >
                    Добавить достижение
                  </v-btn>

                  <v-btn
                      @click="showFiltersDialog = true"
                      variant="outlined"
                      color="primary"
                      class="mb-4"
                  >
                    Фильтры
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                      variant="tonal"
                      class="ma-5 mx-5 w-100"
                      v-for="item in cognationPaginatedData"
                      :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h5>
                        № {{ filteredCognationByСategory.indexOf(item) + 1 }}
                      </h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.cognationName
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.cognationDescription
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Добавлено:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.cognationCreateDate.replace("T", " ")
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Область:</v-list-item-title>
                        <v-list-item-subtitle v-if="item.cognationCategory!==null">{{item.cognationCategory.name }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-red" v-else>Категория не задана</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Ребенок:</v-list-item-title>
                        <v-list-item-subtitle v-if="item.child !== null"
                        >{{ item.child.lastname }}
                          {{ item.child.firstname }}
                          {{ item.child.secname }}</v-list-item-subtitle
                        >
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Добавил:</v-list-item-title>
                        <v-list-item-subtitle
                        >{{ item.creator.lastname }}
                          {{ item.creator.firstname }}
                          {{ item.creator.secname }}</v-list-item-subtitle
                        >
                      </v-list-item>

                      <v-list-item lines="two">
                        <v-list-item-title>Модерация:</v-list-item-title>
                        <v-list-item-subtitle
                        ><v-chip size="small" v-if="item.isModerated"
                        >Да</v-chip
                        ><v-chip v-else size="small"
                        >Нет</v-chip
                        ></v-list-item-subtitle
                        >
                      </v-list-item>

                      <v-list-item lines="two">
                        <v-list-item-title>Файлы:</v-list-item-title>
                        <v-chip-group  v-if="item.cognationFiles!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              @click:close="deleteFile(file, item)"
                              closable
                              size="small"
                              v-for="file in item.cognationFiles"
                              :key="file"
                          >
                            <span v-if="file.fileDescrption.length >17">
                             {{ file.fileDescrption.substr(0,17) }} ...
                            </span>
                            <span v-else>
                                 {{file.fileDescrption}}
                            </span>
                            <template v-slot:prepend>
                              <v-avatar  style="width: 24px; height: 24px;"  v-if="file.fileType!=='pdf'" class="text-shades-white" @click="showImage(file)">
                                <v-img  v-if="file.lazyRandomFileName!==null" :src="`https://school.eurekacenter.ru/cognation_files/${file.lazyRandomFileName}`">
                                  <template v-slot:placeholder>
                                    <div class="d-flex align-center justify-center fill-height">
                                      <v-progress-circular
                                          color="grey-lighten-4"
                                          indeterminate
                                      ></v-progress-circular>
                                    </div>
                                  </template>
                                </v-img>
                                <font-awesome-icon v-else class="text-shades-white" icon="fa-solid fa-image"/>
                              </v-avatar>
                              <v-avatar v-else  @click="downloadFile(file)">
                                <font-awesome-icon class="text-shades-white" icon="fa-solid fa-download"/>
                              </v-avatar>
                            </template>
                          </v-chip>
                        </v-chip-group>
                        <v-chip-group v-if="item.cognationFiles===null" active-class="primary--text" column>
                          <v-chip small  size="small"> Файлов нет </v-chip>
                        </v-chip-group>
                        <v-chip-group  v-else-if="item.cognitionVideos!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              closable
                              size="small"
                              v-for="file in item.cognitionVideos"
                              :key="file"
                              @click:close="deleteVideoFile(file)"
                          >
                            <p v-if="!!file.processed">{{ file.originalFileName }} (Видео обрабатывается)</p>
                            <p v-else>{{ file.originalFileName }} </p>
                            <template v-slot:prepend>
                              <v-progress-circular v-if="!!file.processed"
                                                   color="grey-lighten-4"
                                                   indeterminate
                                                   size="13"
                              ></v-progress-circular>
                              <font-awesome-icon v-else  icon="fa-solid fa-play" @click="playVideo(file)" />

                            </template>
                          </v-chip>
                        </v-chip-group>
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions d-flex flex-wrap">
                      <v-btn
                          @click="editCognation(item)"
                          class="ma-1 text-primary"
                          variant="outlined"
                          rounded
                      >
                        Редактировать</v-btn
                      >
                      <v-btn
                          @click="addFile(item)"
                          class="ma-1 text-primary"
                          variant="outlined"
                          rounded
                      ><font-awesome-icon icon="fa-solid fa-file-import" />
                        Добавить файл</v-btn>
                      <v-btn
                          @click="addVideoFile(item)"
                          style="margin: 5px"
                          mx-1
                          variant="outlined"
                          rounded
                          color="primary-dark"
                      >Добавить видео файл</v-btn
                      >
                      <v-btn
                          v-if="isAdmin || item.creatorId === user.id"
                          @click="deleteCognation(item)"
                          class="ma-1 text-primary"
                          variant="outlined"
                          rounded
                          color="warning"
                      >
                        Удалить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header class="h-100">
                    <thead>
                    <tr>
                      <th class="text-left">
                        <b># </b>
                        <font-awesome-icon
                            v-on:click="byCognationId"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Название</b>
                      </th>
                      <th class="text-left">
                        <b>Описание </b>
                        <font-awesome-icon
                            v-on:click="byCognationDescription"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Добавлено </b>
                        <font-awesome-icon
                            v-on:click="byCognationCreateDate"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Область </b>
                        <font-awesome-icon
                            v-on:click="byCognationCategoryId"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Ребенок </b>
                        <font-awesome-icon
                            v-on:click="byCognationChildId"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Добавил </b>
                        <font-awesome-icon
                            v-on:click="byCognationChildId"
                            icon="fa-solid fa-sort"
                        />
                      </th>

                      <th class="text-left">
                        <b>Модерация </b>
                        <font-awesome-icon
                            v-on:click="byCognationIsModerated"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Файлы</b>
                      </th>
                      <th class="text-left">
                        <b>Действие</b>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in cognationPaginatedData" :key="item.id">
                      <td>
                        <p>
                          {{ filteredCognationByСategory.indexOf(item) + 1 }}
                        </p>
                      </td>
                      <td>
                        <p>{{ item.cognationName }}</p>
                      </td>
                      <td>
                        <p>{{ item.cognationDescription }}</p>
                      </td>
                      <td>
                        <p>
                          {{ item.cognationCreateDate.replace("T", " ") }}
                        </p>
                      </td>
                      <td>
                        <p v-if="item.cognationCategory!==null">{{ item.cognationCategory.name }}</p>
                        <p v-else class="text-red">Категория не выбрана</p>
                      </td>
                      <td>
                        <p v-if="item.child !== null">
                          {{ item.child.lastname }} {{ item.child.firstname }}
                          {{ item.child.secname }}
                        </p>
                      </td>
                      <td>
                        <p v-if="item.child !== null">
                          {{ item.creator.lastname }}
                          {{ item.creator.firstname }}
                          {{ item.creator.secname }}
                        </p>
                      </td>
                      <td>
                        <v-chip size="small" v-if="item.isModerated"
                        >Пройдена</v-chip
                        ><v-chip v-else size="small">Не пройдена</v-chip>
                      </td>
                      <td>
                        <v-chip-group  v-if="item.cognationFiles!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              @click:close="deleteFile(file, item)"
                              closable
                              size="small"
                              v-for="file in item.cognationFiles"
                              :key="file"
                          >
                              <span v-if="file.fileDescrption.length >17">
                             {{ file.fileDescrption.substr(0,17) }} ...
                            </span>
                            <span v-else>
                                 {{file.fileDescrption}}
                            </span>
                            <template v-slot:prepend>
                              <v-avatar  style="width: 24px; height: 24px;"  v-if="file.fileType!=='pdf'" class="text-shades-white" @click="showImage(file)">
                                <v-img  v-if="file.lazyRandomFileName!==null" :src="`https://school.eurekacenter.ru/cognation_files/${file.lazyRandomFileName}`">
                                  <template v-slot:placeholder>
                                    <div class="d-flex align-center justify-center fill-height">
                                      <v-progress-circular
                                          color="grey-lighten-4"
                                          indeterminate
                                      ></v-progress-circular>
                                    </div>
                                  </template>
                                </v-img>
                                <font-awesome-icon v-else class="text-shades-white" icon="fa-solid fa-image"/>
                              </v-avatar>
                              <v-avatar v-else  @click="downloadFile(file)">
                                <font-awesome-icon class="text-shades-white" icon="fa-solid fa-download"/>
                              </v-avatar>
                            </template>
                          </v-chip>
                        </v-chip-group>
                        <v-chip-group v-if="item.cognationFiles===null" active-class="primary--text" column>
                          <v-chip small  size="small"> Файлов нет </v-chip>
                        </v-chip-group>
                        <v-chip-group  v-if="item.cognitionVideos!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              closable
                              size="small"
                              v-for="file in item.cognitionVideos"
                              :key="file"
                              @click:close="deleteVideoFile(file)"
                          >
                            <p v-if="!!file.processed">{{ file.originalFileName }} (Видео обрабатывается)</p>
                            <p v-else>{{ file.originalFileName }} </p>
                            <template v-slot:prepend>
                              <v-progress-circular v-if="!!file.processed"
                                                   color="grey-lighten-4"
                                                   indeterminate
                                                   size="13"
                              ></v-progress-circular>
                              <font-awesome-icon v-else  icon="fa-solid fa-play" @click="playVideo(file)" />

                            </template>
                          </v-chip>
                        </v-chip-group>
                      </td>
                      <td>
                        <p>
                          <v-btn
                              @click="editCognation(item)"
                              class="ma-1 text-primary"
                              variant="outlined"
                              rounded
                          >
                            Редактировать</v-btn
                          >
                          <v-btn
                              @click="addFile(item)"
                              class="ma-1 text-primary"
                              variant="outlined"
                              rounded
                          >
                            Добавить файл</v-btn
                          >
                          <v-btn
                              @click="addVideoFile(item)"
                              style="margin: 5px"
                              mx-1
                              variant="outlined"
                              rounded
                              color="primary-dark"
                          >Добавить видео файл</v-btn
                          >
                          <v-btn
                              v-if="isAdmin || item.creatorId === user.id"
                              @click="deleteCognation(item)"
                              class="ma-1 text-primary"
                              variant="outlined"
                              rounded
                              color="warning"
                          >
                            Удалить</v-btn
                          >
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions
                  class="card_ligth_actions"
                  style="min-height: 5vh"
              >
                <v-container class="text-center">
                  <v-pagination
                      v-model="cognationPage"
                      :length="cognationPageCount"
                      :total-visible="3"
                      size="small"
                  ></v-pagination>
                </v-container>
              </v-card-actions>
            </v-window-item>
            <v-window-item v-if="isAdmin || isTeacher || isTutor || isMaster" value="3" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Администрирование рефлексии для родителей</h3>
              </v-card-title>

              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="characteristicSearchBody"
                        label="Найти"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-title class="card_ligth_header">
                <v-row class="d-flex justify-space-between pa-4">
                  <v-btn
                      @click="characteristicDialog = true"
                      variant="outlined"
                      color="primary-dark"
                      class="mb-4"
                  >
                    Добавить рефлексию
                  </v-btn>

                  <v-btn
                      @click="showCharacteristicsFiltersDialog = true"
                      variant="outlined"
                      color="primary"
                      class="mb-4"
                  >
                    Фильтры
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                      variant="tonal"
                      class="ma-5 mx-5 w-100"
                      v-for="item in characteristicPaginatedData"
                      :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h5>
                        №
                        {{
                          filteredCharacteristicsByUserGroup.indexOf(item) + 1
                        }}
                      </h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Обучающийся:</v-list-item-title>
                        <v-list-item-subtitle
                        >{{ item.subject.lastname }}
                          {{ item.subject.firstname }}
                          {{ item.subject.secname }}</v-list-item-subtitle
                        >
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Оценивающий:</v-list-item-title>
                        <v-list-item-subtitle
                        >{{ item.creator.lastname }}
                          {{ item.creator.firstname }}
                          {{ item.creator.secname }}</v-list-item-subtitle
                        >
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Характеристика:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.characteristicsBody
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Добавлено:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.createDate.replace("T", " ")
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Файлы:</v-list-item-title>
                        <v-chip-group  v-if="item.cognationFiles!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              @click:close="deleteReflectionFile(file, item)"
                              closable
                              size="small"
                              v-for="file in item.cognationFiles"
                              :key="file"
                          >
                            <span v-if="file.fileDescrption.length >17">
                             {{ file.fileDescrption.substr(0,17) }} ...
                            </span>
                            <span v-else>
                                 {{file.fileDescrption}}
                            </span>
                            {{ file.fileDescrption }}
                            <template v-slot:prepend>
                              <v-avatar  style="width: 24px; height: 24px;"  v-if="file.fileType!=='pdf'" class="text-shades-white" @click="showImage(file)">
                                <v-img  v-if="file.lazyRandomFileName!==null" :src="`https://school.eurekacenter.ru/cognation_files/${file.lazyRandomFileName}`">
                                  <template v-slot:placeholder>
                                    <div class="d-flex align-center justify-center fill-height">
                                      <v-progress-circular
                                          color="grey-lighten-4"
                                          indeterminate
                                      ></v-progress-circular>
                                    </div>
                                  </template>
                                </v-img>
                                <font-awesome-icon v-else class="text-shades-white" icon="fa-solid fa-image"/>
                              </v-avatar>
                              <v-avatar v-else  @click="downloadFile(file)">
                                <font-awesome-icon class="text-shades-white" icon="fa-solid fa-download"/>
                              </v-avatar>
                            </template>
                          </v-chip>
                        </v-chip-group>
                        <v-chip-group v-if="item.cognationFiles===null" active-class="primary--text" column>
                          <v-chip small  size="small"> Файлов нет </v-chip>
                        </v-chip-group>
                      </v-list-item>

                      <v-list-item lines="two">
                        <v-list-item-title>Модерация:</v-list-item-title>
                        <v-list-item-subtitle
                        ><v-chip v-if="item.isActual">Да</v-chip
                        ><v-chip v-else>Нет</v-chip></v-list-item-subtitle
                        >
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions">
                      <v-btn
                          @click="editCharacteristic(item)"
                          class="ma-1 text-primary"
                          mx-1
                          variant="outlined"
                          rounded
                      >
                        Редактировать</v-btn
                      >
                      <v-btn
                          @click="addReflectionFile(item)"
                          class="ma-1 text-primary"
                          mx-1
                          variant="outlined"
                          rounded
                      ><font-awesome-icon icon="fa-solid fa-file-import" />
                        Прикрепить файл</v-btn
                      >
                      <v-btn
                          v-if="isAdmin || item.creatorId === user.id"
                          @click="deleteCharacteristic(item)"
                          class="ma-1 text-primary"
                          color="warning"
                          variant="outlined"
                          rounded
                      >
                        Удалить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header class="h-100">
                    <thead>
                    <tr>
                      <th class="text-left">
                        <b># </b>
                        <font-awesome-icon
                            v-on:click="byCharacteristicId"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Обучающийся </b>
                        <font-awesome-icon
                            v-on:click="bySubjectId"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Оценивающий </b>
                        <font-awesome-icon
                            v-on:click="byCreatorId"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Характеристика </b>
                      </th>
                      <th class="text-left">
                        <b>Добавлено </b>
                        <font-awesome-icon
                            v-on:click="byCreateDate"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Файлы</b>
                      </th>
                      <th class="text-left">
                        <b>Модерация </b>
                        <font-awesome-icon
                            v-on:click="byIsActual"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Действие</b>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in characteristicPaginatedData"
                        :key="item.id"
                    >
                      <td>
                        <p>
                          {{
                            filteredCharacteristicsByUserGroup.indexOf(item) +
                            1
                          }}
                        </p>
                      </td>
                      <td>
                        <p>
                          {{ item.subject.lastname }}
                          {{ item.subject.firstname }}
                          {{ item.subject.secname }}
                        </p>
                      </td>
                      <td>
                        <p>
                          {{ item.creator.lastname }}
                          {{ item.creator.firstname }}
                          {{ item.creator.secname }}
                        </p>
                      </td>
                      <td>
                        <p>{{ item.characteristicsBody }}</p>
                      </td>
                      <td>
                        <p>{{ item.createDate.replace("T", " ") }}</p>
                      </td>

                      <td>
                        <v-chip-group  v-if="item.cognationFiles!==null" active-class="primary--text" column>
                          <v-chip
                              small
                              @click:close="deleteReflectionFile(file, item)"
                              closable
                              size="small"
                              v-for="file in item.cognationFiles"
                              :key="file"
                          >
                            <span v-if="file.fileDescrption.length >17">
                             {{ file.fileDescrption.substr(0,17) }} ...
                            </span>
                            <span v-else>
                                 {{file.fileDescrption}}
                            </span>
                            <template v-slot:prepend>
                              <v-avatar  style="width: 24px; height: 24px;"  v-if="file.fileType!=='pdf'" class="text-shades-white" @click="showImage(file)">
                                <v-img  v-if="file.lazyRandomFileName!==null" :src="`https://school.eurekacenter.ru/cognation_files/${file.lazyRandomFileName}`">
                                  <template v-slot:placeholder>
                                    <div class="d-flex align-center justify-center fill-height">
                                      <v-progress-circular
                                          color="grey-lighten-4"
                                          indeterminate
                                      ></v-progress-circular>
                                    </div>
                                  </template>
                                </v-img>
                                <font-awesome-icon v-else class="text-shades-white" icon="fa-solid fa-image"/>
                              </v-avatar>
                              <v-avatar v-else  @click="downloadFile(file)">
                                <font-awesome-icon class="text-shades-white" icon="fa-solid fa-download"/>
                              </v-avatar>
                            </template>
                          </v-chip>
                        </v-chip-group>
                        <v-chip-group v-if="item.cognationFiles===null" active-class="primary--text" column>
                          <v-chip small  size="small"> Файлов нет </v-chip>
                        </v-chip-group>
                      </td>
                      <td>
                        <v-chip size="small" v-if="item.isActual">Да</v-chip
                        ><v-chip size="small" v-else>Нет</v-chip>
                      </td>
                      <td>
                        <p>
                          <v-btn
                              @click="editCharacteristic(item)"
                              class="ma-1 text-primary"
                              mx-1
                              variant="outlined"
                              rounded
                          >
                            Редактировать</v-btn
                          >
                          <v-btn
                              @click="addReflectionFile(item)"
                              class="ma-1 text-primary"
                              mx-1
                              variant="outlined"
                              rounded
                          ><font-awesome-icon
                              icon="fa-solid fa-file-import"
                          />
                            Прикрепить файл</v-btn
                          >
                          <v-btn
                              v-if="isAdmin || item.creatorId === user.id"
                              @click="deleteCharacteristic(item)"
                              class="ma-1 text-primary"
                              color="warning"
                              variant="outlined"
                              rounded
                          >
                            Удалить</v-btn
                          >
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions
                  class="card_ligth_actions"
                  style="min-height: 5vh"
              >
                <v-container class="text-center">
                  <v-pagination
                      v-model="characteristicPage"
                      :length="characteristicPageCount"
                      :total-visible="3"
                      size="small"
                  ></v-pagination>
                </v-container>
              </v-card-actions>
            </v-window-item>
            <v-window-item v-if="isAdmin || isTeacher || isTutor || isMaster" :value="4" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Администрирование области достижений</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="cognationCategorySearchBody"
                        label="Найти"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="mb-4">
                    <v-btn
                        @click="sectionDialog = true"
                        variant="outlined"
                        color="primary-dark"
                    >
                      Добавить область
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                      variant="tonal"
                      class="ma-5 mx-5 w-100"
                      v-for="item in categoriesPaginatedData"
                      :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h5>
                        Область № {{ filteredCategories.indexOf(item) + 1 }}
                      </h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.name
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.description
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Добавлено:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.createDate.replace("T", " ")
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Начало:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.lifeBeginTime.replace("T", " ")
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Окончание:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.lifeEndTime.replace("T", " ")
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Активна:</v-list-item-title>
                        <v-list-item-subtitle
                        ><v-chip v-if="item.isActive">Да</v-chip
                        ><v-chip v-else>Нет</v-chip></v-list-item-subtitle
                        >
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions">
                      <v-btn
                          @click="editCategory(item)"
                          class="ma-1 text-primary"
                          variant="outlined"
                          rounded
                      >
                        Редактировать</v-btn
                      >
                      <v-btn
                          @click="categoryLevels(item)"
                          class="ma-1 text-primary"
                          variant="outlined"
                          rounded
                      >
                        Уровни</v-btn
                      >
                      <v-btn
                          @click="deleteCategory(item)"
                          class="ma-1 text-primary"
                          color="warning"
                          variant="outlined"
                          rounded
                      >
                        Удалить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header class="h-100">
                    <thead>
                    <tr>
                      <th class="text-left">
                        <b># </b>
                        <font-awesome-icon
                            v-on:click="byCategoryId"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Название </b>
                        <font-awesome-icon
                            v-on:click="byCategoryName"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Описание </b>
                        <font-awesome-icon
                            v-on:click="byCategoryDescription"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Добавлено </b>
                        <font-awesome-icon
                            v-on:click="byCategoryCreateDate"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Начало </b>
                        <font-awesome-icon
                            v-on:click="byCategoryBegin"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Окончание </b>
                        <font-awesome-icon
                            v-on:click="byCategoryEnd"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Активна </b>
                        <font-awesome-icon
                            v-on:click="byCategoryActive"
                            icon="fa-solid fa-sort"
                        />
                      </th>
                      <th class="text-left">
                        <b>Действие</b>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in categoriesPaginatedData"
                        :key="item.id"
                    >
                      <td>
                        <p>{{ filteredCategories.indexOf(item) + 1 }}</p>
                      </td>
                      <td>
                        <p>{{ item.name }}</p>
                      </td>
                      <td>
                        <p>{{ item.description }}</p>
                      </td>
                      <td>
                        <p>{{ item.createDate.replace("T", " ") }}</p>
                      </td>
                      <td>
                        <p>{{ item.lifeBeginTime.replace("T", " ") }}</p>
                      </td>
                      <td>
                        <p>{{ item.lifeEndTime.replace("T", " ") }}</p>
                      </td>
                      <td>
                        <v-chip size="small" v-if="item.isActive">Да</v-chip
                        ><v-chip size="small" v-else>Нет</v-chip>
                      </td>
                      <td>
                        <p>
                          <v-btn
                              @click="editCategory(item)"
                              class="ma-1 text-primary"
                              color="primary"
                              variant="outlined"
                              rounded
                          >
                            Редактировать</v-btn
                          >
                          <v-btn
                              @click="categoryLevels(item)"
                              class="ma-1 text-primary"
                              variant="outlined"
                              rounded
                          >
                            Уровни</v-btn
                          >
                          <v-btn
                              @click="deleteCategory(item)"
                              class="ma-1 text-primary"
                              color="warning"
                              variant="outlined"
                              rounded
                          >
                            Удалить</v-btn
                          >
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions
                  class="card_ligth_actions"
                  style="min-height: 5vh"
              >
                <v-container class="text-center">
                  <v-pagination
                      v-model="categoryPage"
                      :length="categoriesPageCount"
                      :total-visible="3"
                      size="small"
                  ></v-pagination>
                </v-container>
              </v-card-actions>
            </v-window-item>
            <v-window-item v-if="isAdmin || isTeacher || isTutor || isMaster" :value="5" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Уровни оценивания категории "{{selectedCategory.name}}"</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="categoryLevelSearchBody"
                        label="Найти"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                        @click="part=3"
                        variant="outlined"
                        color="primary-dark"
                        class="mr-2"
                    >
                      Назад
                    </v-btn>
                    <v-btn
                        @click="categoryLevelDialog = true"
                        variant="outlined"
                        color="primary-dark"
                        class="mr-2"
                    >
                      Добавить уровень
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                      variant="tonal"
                      class="ma-5 mx-5 w-100"
                      v-for="item in categoryCognationLevels"
                      :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h5>
                        №
                        {{
                          categoryCognationLevels.indexOf(item) + 1
                        }}
                      </h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Наименование уровня:</v-list-item-title>
                        <v-list-item-subtitle>{{ item.cognitionCategoryLevelName }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two" v-if="item.finalLevel!==null && item.finalLevel!==undefined">
                        <v-list-item-title>Общий уровень:</v-list-item-title>
                        <v-list-item-subtitle>{{ item.finalLevel.levelValue }}</v-list-item-subtitle
                        >
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions">
                      <v-btn
                          @click="editCognationLevel(item)"
                          class="ma-1 text-primary"
                          mx-1
                          variant="outlined"
                          rounded
                      >
                        Редактировать</v-btn
                      >

                      <v-btn
                          v-if="isAdmin"
                          @click="deleteCognationLevel(item)"
                          class="ma-1 text-primary"
                          color="warning"
                          variant="outlined"
                          rounded
                      >
                        Удалить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header class="h-100">
                    <thead>
                    <tr>
                      <th class="text-left">
                        <b># </b>
                      </th>
                      <th class="text-left">
                        <b>Наименование уровня </b>
                      </th>
                      <th class="text-left">
                        <b>Общий уровень </b>
                      </th>
                      <th class="text-left">
                        <b>Действие</b>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in categoryCognationLevels"
                        :key="item.id"
                    >
                      <td>
                        <p>
                          {{categoryCognationLevels.indexOf(item) + 1 }}
                        </p>
                      </td>
                      <td>
                        <p>{{item.cognitionCategoryLevelName}}</p>
                      </td>
                      <td>
                        <p v-if="item.finalLevel!==null">{{item.finalLevel.levelValue}}</p>
                        <p v-else>Не выбран</p>
                      </td>

                      <td>
                        <p>
                          <v-btn
                              @click="editCognationLevel(item)"
                              class="ma-1 text-primary"
                              mx-1
                              variant="outlined"
                              rounded
                          >Редактировать</v-btn>

                          <v-btn
                              v-if="isAdmin"
                              @click="deleteCognationLevel(item)"
                              class="ma-1 text-primary"
                              color="warning"
                              variant="outlined"
                              rounded
                          >Удалить</v-btn>
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-divider></v-divider>
            </v-window-item>
            <v-window-item v-if="isAdmin || isTeacher || isTutor || isMaster" value="6" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Общие уровни оценивания</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="finalLevelSearchBody"
                        label="Найти"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                        @click="finalLevelDialog = true"
                        variant="outlined"
                        color="primary-dark"
                        class="mr-2"
                    >
                      Добавить уровень
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                      variant="tonal"
                      class="ma-5 mx-5 w-100"
                      v-for="item in finalLevels"
                      :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h5>№ {{finalLevels.indexOf(item) + 1 }}</h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Значение уровня:</v-list-item-title>
                        <v-list-item-subtitle>{{ item.levelValue }}</v-list-item-subtitle>
                      </v-list-item>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions">
                      <v-btn
                          @click="editFinalLevel(item)"
                          class="ma-1 text-primary"
                          mx-1
                          variant="outlined"
                          rounded
                      >Редактировать</v-btn>

                      <v-btn
                          v-if="isAdmin"
                          @click="deleteFinalLevel(item)"
                          class="ma-1 text-primary"
                          color="warning"
                          variant="outlined"
                          rounded
                      >Удалить</v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header class="h-100">
                    <thead>
                    <tr>
                      <th class="text-left">
                        <b># </b>
                      </th>
                      <th class="text-left">
                        <b>Значение уровня</b>
                      </th>
                      <th class="text-left">
                        <b>Действие</b>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in finalLevels"
                        :key="item.id"
                    >
                      <td>
                        <p>
                          {{finalLevels.indexOf(item) + 1 }}
                        </p>
                      </td>
                      <td>
                        <p>{{item.levelValue}}</p>
                      </td>

                      <td>
                        <p>
                          <v-btn
                              @click="editFinalLevel(item)"
                              class="ma-1 text-primary"
                              mx-1
                              variant="outlined"
                              rounded
                          >Редактировать</v-btn>

                          <v-btn
                              v-if="isAdmin"
                              @click="deleteFinalLevel(item)"
                              class="ma-1 text-primary"
                              color="warning"
                              variant="outlined"
                              rounded
                          >Удалить</v-btn>
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-divider></v-divider>
            </v-window-item>
          </v-window>
        </v-card-text>
      </template>
    </TableElement>

    <v-dialog v-model="finalLevelDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Общий уровень оценивания</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="finalLevelForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Значение*"
                      v-model="levelValue"
                      :rules="nameRules"
                      hint="Укажите значение"
                      :counter="50"
                      variant="outlined"
                      required
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closeFinalLevelDialog"
              color="primary-dark"
          >
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveFinalLevel">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="categoryLevelDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Уровень оценивания категории</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="levelForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Название*"
                      v-model="cognitionCategoryLevelName"
                      :rules="nameRules"
                      hint="Укажите название уровня"
                      :counter="50"
                      variant="outlined"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="finalLevelId"
                      :items="finalLevels"
                      clearable
                      label="общий уровень"
                      item-title="levelValue"
                      item-value="id"
                      variant="outlined"
                  >
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.levelValue"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closeLevelDialog"
              color="primary-dark"
          >
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveLevel">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cognationDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Достижение ребенка</h5>
        </v-card-title>
        <v-card-text >
          <v-form ref="cognationForm">
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="cognationName"
                    :rules="cognationNameRules"
                    variant="outlined"
                    hint="Укажите название достижения"
                    :counter="50"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Описание*"
                    v-model="cognationDescription"
                    :rules="cognationDescriptionRules"
                    variant="outlined"
                    :counter="255"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="cognationCategory"
                    :items="activeCatigories"
                    :custom-filter="cognationCategoryFilter"
                    :rules="subjectRules"
                    variant="outlined"
                    chips
                    small-chips
                    label="Область"
                    required
                    item-title="name"
                    item-value="id"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip v-bind="props" :text="item.raw.name"></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                        :subtitle="item.raw.description"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="subjectGroupCreateCognationFilter"
                    :items="userGroups"
                    chips
                    small-chips
                    :custom-filter="eventScheduleUserGroupFilter"
                    clearable
                    label="Фильтр обучающихся"
                    item-title="personGroupName"
                    item-value="id"
                    :disabled="!!characteristicId"
                    variant="outlined"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.personGroupName"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.personGroupName"
                        :subtitle="item.raw.personGroupDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="child"
                    :items="filteredUsersByUserGroupForCognation"
                    :custom-filter="childFilter"
                    item-title="firstname"
                    item-value="id"
                    :rules="subjectRules"
                    required
                    chips
                    clearable
                    small-chips
                    label="Выбрать ребенка"
                    variant="outlined"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="
                          item.raw.firstname +
                          ' ' +
                          item.raw.secname +
                          ' ' +
                          item.raw.lastname
                        "
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="
                          item.raw.firstname +
                          ' ' +
                          item.raw.secname +
                          ' ' +
                          item.raw.lastname
                        "
                        :subtitle="item.raw.birthday"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-col v-if="!this.cognationId" cols="12">
                  <v-file-input
                      multiple
                      small-chips
                      show-size
                      v-model="cognationFiles"
                      accept="image/png, image/bmp, image/jpeg,.pdf"
                      ref="files"
                      type="file"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      label="Загрузить файлы"
                  ></v-file-input>
                </v-col>
              </v-col>
              <v-col cols="12">
                <v-container class="px-0" fluid>
                  <v-switch
                      v-model="isModerated"
                      :label="`Модерация`"
                      color="success"
                  >
                  </v-switch>
                  <v-chip v-if="isModerated"> пройдена</v-chip
                  ><v-chip v-else> не пройдена</v-chip>
                </v-container>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Комментарий модератора*"
                    v-model="staffDescription"
                    variant="outlined"
                    :counter="255"
                    hint="Укажите комментарий модератора"
                ></v-textarea>
              </v-col>
              <v-col cols="12" v-if="cognationCategory">
                <v-autocomplete
                    v-model="categoryLevelId"
                    :items="categoryCognationLevels"
                    item-title="cognitionCategoryLevelName"
                    item-value="id"
                    clearable
                    label="Выбрать уровень"
                    variant="outlined"
                >
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.cognitionCategoryLevelName"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-progress-linear
                    v-if="uploadPercentage>0"
                    color="amber"
                    v-model="uploadPercentage"
                    height="25"
                >

                  <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                </v-progress-linear>
              </v-col>

            </v-row>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closeCognationDialog"
              color="primary-dark"
          >
            Отмена
          </v-btn>
          <v-btn
              rounded
              variant="outlined"
              @click="saveCognationWithFiles"
              :disabled = "(uploadPercentage>0)&&(uploadPercentage<100)"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewPhoto" scrollable persistent>
      <v-card>
        <v-divider></v-divider>
        <v-img v-if="selectedFile.lazyRandomFileName!==null"
               :lazy-src="`https://school.eurekacenter.ru/cognation_files/${selectedFile.lazyRandomFileName}`"
               :src="`https://school.eurekacenter.ru/cognation_files/${selectedFile.randomFileName}`"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>

        <v-img v-else
               :src="`https://school.eurekacenter.ru/cognation_files/${selectedFile.randomFileName}`"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>

        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">

          <v-spacer></v-spacer>
          <v-btn
              @click="downloadFile(this.selectedFile)"
              ma-2
              variant="outlined"
              rounded
          ><font-awesome-icon icon="fa-solid fa-download" />Скачать</v-btn
          >
          <v-btn
              @click="viewPhoto = false"
              ma-2
              variant="outlined"
              rounded
          ><font-awesome-icon icon="fa-solid fa-close" />Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sectionDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Область достижения</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="sectionForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Название*"
                      v-model="name"
                      :rules="nameRules"
                      hint="Укажите название области"
                      :counter="50"
                      variant="outlined"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      label="Описание*"
                      v-model="description"
                      :rules="descriptionRules"
                      variant="outlined"
                      :counter="255"
                      hint="Укажите описание"
                      required
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-label>Начало периода</v-label>
                  <DatePicker
                      v-model="lifeBeginTime"
                      autoApply
                      textInput
                      locale="ru"
                      required
                  />
                  <v-alert
                      v-if="lifeBeginTimeAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-label>Окончание периода</v-label>
                  <DatePicker
                      v-model="lifeEndTime"
                      autoApply
                      textInput
                      locale="ru"
                      required
                  />
                  <v-alert
                      v-if="lifeEndTimeAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                  <v-alert
                      v-if="dateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Окончание раньше начала!
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-container class="px-0" fluid>
                    <v-switch
                        v-model="isActive"
                        :label="`Активна`"
                        color="success"
                    ></v-switch>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closeCategoryDialog"
              color="primary-dark"
          >
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveSection">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="characteristicDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Рефлексия для родителей</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="characteristicForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="subjectGroupCreateCharacteristicsFilter"
                      :items="userGroups"
                      :custom-filter="eventScheduleUserGroupFilter"
                      chips
                      clearable
                      small-chips
                      label="Фильтр обучающихся"
                      item-title="personGroupName"
                      item-value="id"
                      required
                      :disabled="!!characteristicId"
                      variant="outlined"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.personGroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.firstname"
                          :subtitle="item.raw.personGroupDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="subject"
                      :items="filteredUsersByUserGroupForCharacteristics"
                      :custom-filter="childFilter"
                      chips
                      small-chips
                      clearable
                      label="Обучающийся"
                      item-title="firstname"
                      item-value="id"
                      :disabled="!!characteristicId"
                      variant="outlined"
                      requred
                      :rules="subjectRules"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="
                          item.raw.firstname +
                          ' ' +
                          item.raw.secname +
                          ' ' +
                          item.raw.lastname
                        "
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="
                          item.raw.firstname +
                          ' ' +
                          item.raw.secname +
                          ' ' +
                          item.raw.lastname
                        "
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      label="Описание*"
                      v-model="characteristicsBody"
                      :rules="characteristicsBodyRules"
                      variant="outlined"
                      :counter="1500"
                      hint="Укажите описание"
                      required
                  ></v-textarea>
                </v-col>
                <v-col v-if="!this.characteristicId" cols="12">
                  <v-file-input
                      multiple
                      small-chips
                      show-size
                      v-model="cognationFiles"
                      accept="image/png, image/bmp, image/jpeg,.pdf"
                      ref="files"
                      type="file"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      label="Загрузить файлы"
                  ></v-file-input>
                </v-col>

                <v-col cols="12">
                  <v-row class="px-0" fluid>
                    <v-switch
                        v-model="isActual"
                        :label="`Показывать родителям`"
                        color="success"
                    >
                    </v-switch>
                    <v-chip v-if="isActual"> да</v-chip
                    ><v-chip v-else> нет</v-chip>
                  </v-row>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-progress-linear
                    v-if="uploadPercentage>0"
                    color="amber"
                    v-model="uploadPercentage"
                    height="25"
                >

                  <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                </v-progress-linear>
              </v-col>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closeCharacteristic"
              color="primary-dark"
          >
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveCharacteristicWithFiles">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMyFileInput" persistent scrollable>
      <v-form ref="myFileInputForm">
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Загрузка файла моего достижения</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>

              <v-row>
                <v-col cols="12">
                  <v-file-input
                      show-size
                      v-model="fileBody"
                      ref="myFileBody"
                      :rules="fileRules"
                      accept="image/png, image/bmp, image/jpeg,.pdf"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      v-on:change="handleFileUpload"
                      label="Загрузить файл*"
                  ></v-file-input>
                </v-col>


                <v-col cols="12">
                  <v-progress-linear
                      v-if="uploadPercentage>0"
                      color="amber"
                      v-model="uploadPercentage"
                      height="25"
                  >

                    <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                  </v-progress-linear>
                </v-col>

              </v-row>


            </v-container>
            <small>*обязательно для заполнения</small>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                class="primary-dark"
                style="margin-left: 5px"
                @click="hideFileInput"
                color="primary-dark"
            >
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                class="primary"
                style="margin-left: 5px"
                :disabled="downloadAnimation"
                @click="saveMyFile"
            >
              Сохранить
              <v-progress-circular
                  v-if="downloadAnimation"
                  indeterminate
                  color="amber"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="showFileInput" persistent scrollable>
      <v-form ref="cognationFileForm">
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Загрузка файла достижения</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>

                <v-col cols="12">
                  <v-file-input
                      show-size
                      v-model="fileBody"
                      ref="fileBody"
                      :rules="fileRules"
                      accept="image/png, image/bmp, image/jpeg,.pdf"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      v-on:change="handleFileUpload"
                      label="Загрузить файл*"
                  ></v-file-input>
                </v-col>

                <v-col cols="12">
                  <v-progress-linear
                      v-if="uploadPercentage>0"
                      color="amber"
                      v-model="uploadPercentage"
                      height="25"
                  >

                    <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                  </v-progress-linear>
                </v-col>

              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                class="primary-dark"
                style="margin-left: 5px"
                @click="hideFileInput"
                color="primary-dark"
            >
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                class="primary"
                style="margin-left: 5px"
                :disabled="downloadAnimation"
                @click="saveFile"
            >
              Сохранить
              <v-progress-circular
                  v-if="downloadAnimation"
                  indeterminate
                  color="amber"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="showLoader" persistent
    ><v-alert
    ><span class="text-h5 text-primary"
    >Загрузка данных
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular></span
    ></v-alert>
    </v-dialog>

    <v-dialog v-model="showFiltersDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Фильтры</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="filterForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="scheduleFilterUserGroup"
                      :items="userGroups"
                      :custom-filter="eventScheduleUserGroupFilter"
                      outlined
                      chips
                      small-chips
                      label="Группа пользователей"
                      clearable
                      item-title="personGroupName"
                      item-value="id"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.personGroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.personGroupName"
                          :subtitle="item.raw.personGroupDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="filterCognationSubject"
                      :items="filteredUsersByUserGroup"
                      field
                      :custom-filter="childFilter"
                      outlined
                      chips
                      small-chips
                      clearable
                      item-title="lastname"
                      item-value="id"
                      label="Владелец достижения"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.firstname + ' ' + item.raw.lastname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.firstname + ' ' + item.raw.lastname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="filterCognationCreator"
                      :items="users"
                      :custom-filter="childFilter"
                      outlined
                      dense
                      chips
                      small-chips
                      clearable
                      item-title="lastname"
                      item-value="id"
                      label="Кто добавил достижения"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.firstname + ' ' + item.raw.lastname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.firstname + ' ' + item.raw.lastname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="filterCognationCategory"
                      :items="categories"
                      :custom-filter="categoryFilter"
                      outlined
                      dense
                      chips
                      small-chips
                      clearable
                      item-title="name"
                      item-value="id"
                      label="Область"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip v-bind="props" :text="item.raw.name"></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.name"
                          :subtitle="item.raw.description"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-label>Период</v-label>
                  <DatePicker
                      v-model="eventFilterDate"
                      inline
                      textInput
                      inlineWithInput
                      autoApply
                      range
                      locale="ru"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeFilters">
            Закрыть
          </v-btn>
          <v-btn rounded variant="outlined" @click="clearFilters">
            Очистить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMyFiltersDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Фильтры</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="filterForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="filterMyCognationCategory"
                      :items="categories"
                      :custom-filter="categoryFilter"
                      outlined
                      dense
                      clearable
                      item-title="name"
                      item-value="id"
                      label="Область"
                  >

                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.name"
                          :subtitle="item.raw.description"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-label>Период</v-label>
                  <DatePicker
                      v-model="eventFilterMyDate"
                      inline
                      textInput
                      inlineWithInput
                      autoApply
                      range
                      locale="ru"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeMyFilters">
            Закрыть
          </v-btn>
          <v-btn rounded variant="outlined" @click="clearMyFilters">
            Очистить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCharacteristicsFiltersDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Фильтры</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="filterForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="scheduleCharacteristicsFilterUserGroup"
                      :items="userGroups"
                      :custom-filter="eventScheduleUserGroupFilter"
                      outlined
                      chips
                      small-chips
                      label="Группа пользователей"
                      clearable
                      item-title="personGroupName"
                      item-value="id"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.personGroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.personGroupName"
                          :subtitle="item.raw.personGroupDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="filterCharacteristicsSubject"
                      :items="filteredUsersByUserGroupForChar"
                      :custom-filter="childFilter"
                      outlined
                      dense
                      chips
                      small-chips
                      clearable
                      item-title="lastname"
                      item-value="id"
                      label="Обучающийся"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.firstname + ' ' + item.raw.lastname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.firstname + ' ' + item.raw.lastname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="filterCharacteristicsCreator"
                      :items="users"
                      :custom-filter="childFilter"
                      outlined
                      dense
                      chips
                      small-chips
                      clearable
                      item-title="lastname"
                      item-value="id"
                      label="Кто добавил рефлексию"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.firstname + ' ' + item.raw.lastname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.firstname + ' ' + item.raw.lastname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-label>Период</v-label>
                  <DatePicker
                      v-model="characteristicFilterDate"
                      inline
                      textInput
                      inlineWithInput
                      autoApply
                      range
                      locale="ru"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closeCharacteristicsFilters"
          >
            Закрыть
          </v-btn>
          <v-btn
              rounded
              variant="outlined"
              @click="clearCharacteristicsFilters"
          >
            Очистить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showReflectionFileInput" persistent scrollable>
      <v-form ref="fileReflectionForm">
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Загрузка файла для рефлексии</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                      show-size
                      v-model="reflectionFileBody"
                      ref="reflectionFileBody"
                      accept="image/png, image/png, image/bmp, image/jpeg,.pdf"
                      :rules="fileRules"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      v-on:change="handleReflectionFileUpload"
                      label="Загрузить файл*"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-progress-linear
                      v-if="uploadPercentage>0"
                      color="amber"
                      v-model="uploadPercentage"
                      height="25"
                  >

                    <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                  </v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                color="primary-dark"
                style="margin-left: 5px"
                @click="hideReflectionFileInput"
            >
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                color="primary"
                style="margin-left: 5px"
                :disabled="downloadAnimation"
                @click="saveReflectionFile"
            >
              Сохранить
              <v-progress-circular
                  v-if="downloadAnimation"
                  indeterminate
                  color="amber"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="myCognationDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Мое достижение</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="myCognationForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Название*"
                      v-model="cognationName"
                      :rules="cognationNameRules"
                      hint="Укажите название достижения"
                      variant="outlined"
                      :counter="60"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      label="Описание*"
                      v-model="cognationDescription"
                      :rules="cognationDescriptionRules"
                      variant="outlined"
                      :counter="255"
                      hint="Укажите описание"
                      required
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                      v-model="cognationCategory"
                      :items="activeCatigories"
                      variant="outlined"
                      :rules="subjectRules"
                      filled
                      chips
                      small-chips
                      closable-chips
                      label="Область"
                      required
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip v-bind="props" :text="item.raw.name"></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.description"
                          :subtitle="item.raw.description"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col v-if="!this.cognationId" cols="12">
                  <v-file-input
                      multiple
                      small-chips
                      show-size
                      v-model="cognationFiles"
                      accept="image/png, image/bmp, image/jpeg,.pdf"
                      ref="files"
                      type="file"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      label="Загрузить файлы"
                  ></v-file-input>
                </v-col>

              </v-row>
              <v-col cols="12">
                <v-progress-linear
                    v-if="uploadPercentage>0"
                    color="amber"
                    v-model="uploadPercentage"
                    height="25"
                >

                  <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                </v-progress-linear>
              </v-col>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closeMyCognationDialog"
              color="primary-dark"
          >
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveMyCognationWithFiles">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMyVideoFileInput" persistent scrollable>
      <v-form ref="myVideoFileForm">
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Загрузка видео</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                      show-size
                      v-model="myVideoFileBody"
                      ref="myVideoFileBody"
                      :rules="fileRules"
                      accept="video/mp4,video/x-m4v,video/*"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      label="Загрузить файл*"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-progress-linear
                      v-if="uploadPercentage>0 & uploadPercentage<100"
                      color="amber"
                      v-model="uploadPercentage"
                      height="25"
                  >

                    <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                  </v-progress-linear>
                </v-col>
                <v-col cols="12" v-if="uploadPercentage===100">
                <p>Файл загружен, идет обработка. Окно можно закрыть. После обработки видео будет доступно для просмотра.</p>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                color="primary-dark"
                style="margin-left: 5px"
                @click="closeMyVideoFileInput"
            >
              Закрыть
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                color="primary"
                style="margin-left: 5px"
                :disabled="downloadAnimation"
                @click="saveMyVideoFile"
            >
              Сохранить
              <v-progress-circular
                  v-if="downloadAnimation"
                  indeterminate
                  color="amber"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="showVideoFileInput" persistent scrollable>
      <v-form ref="videoFileForm">
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Загрузка видео</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                      show-size
                      v-model="videoFileBody"
                      ref="videoFileBody"
                      :rules="fileRules"
                      accept="video/mp4,video/x-m4v,video/*"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      label="Загрузить файл*"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-progress-linear
                      v-if="uploadPercentage>0 & uploadPercentage<100"
                      color="amber"
                      v-model="uploadPercentage"
                      height="25"
                  >

                    <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                  </v-progress-linear>
                </v-col>
                <v-col cols="12" v-if="uploadPercentage===100">
                  <p>Файл загружен, идет обработка. Окно можно закрыть. После обработки видео будет доступно для просмотра.</p>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                color="primary-dark"
                style="margin-left: 5px"
                @click="closeVideoFileInput"
            >
              Закрыть
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                color="primary"
                style="margin-left: 5px"
                :disabled="downloadAnimation"
                @click="saveVideoFile"
            >
              Сохранить
              <v-progress-circular
                  v-if="downloadAnimation"
                  indeterminate
                  color="amber"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="showVideo">
      <div class="d-flex justify-end">
        <AppButton @click="destroy(null)" class="accent-button"
        >Закрыть</AppButton
        >
      </div>
      <div  class="d-flex justify-center">
          <Vue3VideoPlayer
              v-bind="playerOptions"
              :poster="posterSrc"
          ></Vue3VideoPlayer>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import {userService } from "@/service/user.service";
import TableElement from "../elements/TableElement.vue";
import HLSCore from '@cloudgeek/playcore-hls'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}
import _ from "lodash";
import download from "js-file-download";
import AppButton from "@/elements/AppButton.vue";
import {customAxios} from "@/service/customAxios";

export default {
  components: {AppButton, TableElement },
  name: "AdminCognation",
  selectedFile: '',

  props: [],
  data: () => ({
    playerOptions:{},
    videoFileBody:[],
    showVideoFileInput: false,

    videoSrc:'',
    posterSrc:'',
    players: {},
    HLSCore,
    volume: 80,
    selectedVideo: '',
    showVideo: false,
    showMyVideoFileInput: false,
    myVideoFileBody: [],
    finalLevelSearchBody:"",
    finalLevelDialog: false,
    finalLevels:[],
    levelValue:'',

    levels:[],
    finalLevelId: undefined,
    cognitionCategoryLevelName:'',

    categoryCognationLevels:[],
    selectedCategory: undefined,
    categoryLevelDialog: false,
    categoryLevelSearchBody: '',
    staffDescription:'',
    categoryLevelId: undefined,
    eventFilterMyDate: [],
    filterMyCognationCategory: undefined,
    showMyFiltersDialog: false,
    selectedFile:'',
    viewPhoto: false,
    cognationFiles:[],
    myCognationSearchBody: "",
    myCognationDialog: false,
    myCognationPage: 1,
    myCognations: [],
    uploadPercentage:0,
    downloadPercentage:0,

    showReflectionFileInput: false,
    reflectionFileBody: [],

    eventFilterDate: [],
    characteristicFilterDate: [],
    reflectionFileDescrption: "",

    filterCharacteristicsSubject: undefined,
    filterCognationCreator: undefined,
    filterCharacteristicsCreator: undefined,
    subjectGroupCreateCharacteristicsFilter: undefined,
    scheduleCharacteristicsFilterUserGroup: undefined,
    users: [],
    showCharacteristicsFiltersDialog: false,
    showFiltersDialog: false,
    showFilters: false,
    showLoader: false,
    lifeBeginTimeAlert: false,
    lifeEndTimeAlert: false,
    dateAlert: false,
    cognationId: "",
    cognationDialog: false,
    sectionDialog: false,
    child: null,
    cognationCategory: null,
    isActive: false,
    lifeEndTime: null,
    lifeBeginTime: null,
    score: "",
    description: "",
    name: "",
    sectionId: "",
    cognationName: "",
    cognationDescription: "",
    isModerated: false,
    cognationSearchBody: "",
    cognationCategorySearchBody: "",
    cognationCategoryId: undefined,

    nameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    descriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],

    cognationNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    cognationDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],
    noteCount: 20,
    cognationPage: 1,
    categoryPage: 1,
    isLocked: false,
    searchBody: "",
    loading: false,
    part: 1,
    cognations: [],
    categories: [],
    activeCatigories: [],
    childs: [],
    myProfile: "",
    showMyFileInput: false,

    characteristicPage: 1,
    characteristics: [],

    characteristicSortKey: "createDate",
    lastCharacteristicSortKey: "createDate",
    characteristicSortOrder: -1,
    characteristicSearchBody: "",
    characteristicDialog: false,
    characteristicsBody: "",
    characteristicsBodyRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 1500) ||
          "Значение до должно превышать 1500 символов!",
    ],
    isActual: false,
    characteristicId: "",
    subjectRules: [(v) => (!!v && true) || "Поле обязательно для заполнения!"],
    subject: null,

    categoriesSortKey: "id",
    lastCategoriesSortKey: "id",
    categoriesSortOrder: 1,

    cognationSortKey: "cognationCreateDate",
    lastCognationSortKey: "cognationCreateDate",
    cognationSortOrder: -1,

    myCognationSortKey: "cognationCreateDate",
    mylastCognationSortKey: "cognationCreateDate",
    myCognationSortOrder: -1,

    randomFileName: "",
    ext: "",
    sourceId: "",
    downloadAnimation: false,
    showFileInput: false,
    fileName: "",
    fileDescrption: "",
    fileBody: [],
    fileDescrptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],
    cognationCategoryRules: [
      (v) => v === undefined || "Поле обязательно для заполнения!",
    ],
    fileRules: [
      (v) => !!v || "Необходимо прикрепить файл!",

    ],
    userGroups: [],
    scheduleFilterUserGroup: undefined,
    filterCognationSubject: undefined,
    filterCognationCategory: undefined,
    subjectGroupCreateCognationFilter: undefined,
  }),
  watch: {
    part(newPart) {
      localStorage.cognationAdminTab = newPart;
    },
  },
  mounted() {
  //  if (localStorage.cognationAdminTab) {
   //   this.part = localStorage.cognationAdminTab;
   // }



  },
  methods: {

    viewCore(id, player) {
      console.log(id, player);
      this.players[id] = player;
    },
    play(id) {
      console.log('custom play: id =', id);
      this.players && this.players[id] && this.players[id].play();
    },
    destroy(id) {
      this.showVideo = false;
      this.players && this.players[id] && this.players[id].destroy();
    },

    playVideo(item){
      this.selectedVideo = item
      this.videoSrc = "https://new-school.geekbase.ru/video" + item.sourceHlsIndex
      this.posterSrc ="https://new-school.geekbase.ru/video" + item.posterName
      this.playerOptions = {
        src:
            "https://new-school.geekbase.ru/video" + item.sourceHlsIndex,
          type: "m3u8",
          webFullScreen: false,
          control: true,
          muted: true,
          controlBtns: [
        "audioTrack",
        "quality",
        "speedRate",
        "volume",
        "setting",
        "pip",
        "pageFullScreen",
        "fullScreen",
      ],
      }



      this.showVideo = true


    },
    saveVideoFile() {
      this.$refs.videoFileForm.validate().then((result) => {
        if (result.valid) {
          this.downloadAnimation = true;
          let formData = new FormData();
          formData.append("file", this.$refs.videoFileBody.files[0]);
          customAxios
              .post( "/video/upload/" + this.sourceId , formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + this.user.token,
                },
                onUploadProgress: function( progressEvent ) {
                  this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                }.bind(this)

              }).then((res) => {
              console.log("upload result: " + res)
            if(res.status===202){
              let index = getIndex(this.cognations, res.data.id);
              this.cognations.splice(index, 1, res.data);
              this.showVideoFileInput = false;
              this.uploadPercentage = 0;
              this.videoFileBody=[];
              this.downloadAnimation = false;
            }
          })
              .catch((error) => {
                this.downloadAnimation = false;
                this.showVideoFileInput = false;
                this.uploadPercentage = 0;
                this.videoFileBody=[];
                console.log("Error: " + error);
              });

        }
      });
    },
    saveMyVideoFile() {
      this.$refs.myVideoFileForm.validate().then((result) => {
        if (result.valid) {
          this.downloadAnimation = true;
          let formData = new FormData();
          formData.append("file", this.$refs.myVideoFileBody.files[0]);
          customAxios
              .post( "/video/upload/" + this.sourceId , formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + this.user.token,
                },
                onUploadProgress: function( progressEvent ) {
                  this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                }.bind(this)

              }).then((res) => {
            console.log("upload result: " + res)
            if(res.status===202){
              let index = getIndex(this.myCognations, res.data.id);
              this.myCognations.splice(index, 1, res.data);
              this.showMyVideoFileInput = false;
              this.uploadPercentage = 0;
              this.myVideoFileBody=[];
              this.downloadAnimation = false;
            }
          })
              .catch((error) => {
                this.downloadAnimation = false;
                this.showMyVideoFileInput = false;
                this.uploadPercentage = 0;
                this.myVideoFileBody=[];
                console.log("Error: " + error);
              });

        }
      });
    },
    closeMyVideoFileInput(){
      this.showMyVideoFileInput = false;
      this.uploadPercentage = 0;
      this.myVideoFileBody=[];
      this.downloadAnimation = false;
    },
    closeVideoFileInput(){
      this.showVideoFileInput = false;
      this.uploadPercentage = 0;
      this.videoFileBody=[];
      this.downloadAnimation = false;
    },
    openCognationAdminForm(){
      this.cognationDialog = true;
      this.isModerated = true;
    },
    closeFinalLevelDialog(){
      this.levelValue= ''
      this.id=undefined
      this.finalLevelDialog = false
    },
    saveFinalLevel(){
      this.$refs.finalLevelForm.validate().then((result) => {
        if (result.valid) {
          
          let level = {
            levelValue: this.levelValue

          };

          if (this.id) {
            customAxios.put( "/cognation/category/level/final/" + this.id, level)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.finalLevels, response.data.id);
                    this.finalLevels.splice(index, 1, response.data);
                    this.levelValue = "";
                    this.finalLevelDialog = false;
                    this.id = undefined;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
          } else {
            customAxios
                .post( "/cognation/category/level/final", level)
                .then((response) => {
                  if (response.status === 201) {
                    this.finalLevels.push(response.data);
                    console.log("server created");
                    this.finalLevelDialog = false;
                    this.levelValue = "";
                    this.id = undefined;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                
          }

        } })
    },
    saveLevel(){
      this.$refs.levelForm.validate().then((result) => {
        if (result.valid) {
          console.log("level form validated")
          
          let level = {
            cognitionCategoryId: this.selectedCategory.id,
            finalLevelId: this.finalLevelId,
            cognitionCategoryLevelName:capitalizeFirstLetter(this.cognitionCategoryLevelName),

          };

          if (this.id) {
            customAxios.put( "/cognation/category/level/" + this.id, level)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.categoryCognationLevels, response.data.id);
                    this.categoryCognationLevels.splice(index, 1, response.data);
                    this.categoryLevelDialog = false;
                    this.cognitionCategoryLevelName = "";
                    this.finalLevelId = undefined;
                    this.id = "";
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                
          } else {
            customAxios
                .post( "/cognation/category/level", level)
                .then((response) => {
                  if (response.status === 201) {
                    this.categoryCognationLevels.push(response.data);
                    console.log("server created");
                    this.categoryLevelDialog = false;
                    this.cognitionCategoryLevelName = "";
                    this.finalLevelId = undefined;
                    this.id = "";
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
          }

        } })
    },
    closeLevelDialog(){
      this.categoryLevelDialog = false;
      this.cognitionCategoryLevelName = "";
      this.finalLevelId = undefined;
    },
    editFinalLevel(item){
      this.id = item.id
      this.levelValue= item.levelValue
      this.finalLevelDialog = true

      console.log(item)

    },
    editCognationLevel(item){
      this.id = item.id
      this.cognitionCategoryLevelName= item.cognitionCategoryLevelName
      this.finalLevelId = item.finalLevelId
      this.categoryLevelDialog = true

      console.log(item)

    },
    deleteCognationLevel(item){
      console.log(item)
      customAxios.delete( "/cognation/category/level/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              console.log(response.data)
              this.categoryCognationLevels.splice(this.categoryCognationLevels.indexOf(item), 1);
              this.categoryLevelDialog = false
            } else if(response.status === 207) {
              alert(response.data.message);
              console.log(response);
              this.categoryLevelDialog = false
            }
          })

    },
    deleteFinalLevel(item){
      console.log(item)
     
      customAxios.delete( "/cognation/category/level/final/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              console.log(response.data)
              this.finalLevels.splice(this.finalLevels.indexOf(item), 1);
              this.finalLevelDialog = false
            } else if(response.status === 207) {
              alert(response.data.message);
              console.log(response);
              this.finalLevelDialog = false
            }
          })

    },
    categoryLevels(item){
      this.selectedCategory = item;
     
      customAxios.get( "/cognation/category/level/" + this.selectedCategory.id)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data)
              this.categoryCognationLevels = response.data
            }
          })
      this.part = 5;
    },
    showImage(item){
      this.selectedFile = item;
      this.viewPhoto = true;
    },

    editMyCognation(item) {
      this.cognationId = item.id;
      this.cognationName = item.cognationName;
      this.cognationDescription = item.cognationDescription;
      this.cognationCategory = item.cognationCategory;
      this.myCognationDialog = true;
      this.fileBody = item.fileBody;
    },
    closeMyCognationDialog() {
      this.myCognationDialog = false;
      this.cognationId = "";
      this.cognationName = "";
      this.cognationDescription = "";
      this.cognationFiles = [];
      this.cognationCategory = undefined;
      this.isModerated = false;
    },
    deleteMyCognation(item) {
     
      if (confirm("Вы точно хотите удалить достижение?")) {
        customAxios
            .delete( "/cognation/my/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.cognations.splice(this.cognations.indexOf(item), 1);
              } else if (response.status === 207) {
                alert(response.data.message);
                console.log(response);
              } else {
                alert("Что-то пошло не так");
                console.log(response);
              }
            })
           
      }
    },
    //--

    closeFilters() {
      this.showFiltersDialog = false;
    },
    closeMyFilters() {
      this.showMyFiltersDialog = false;
    },
    closeCharacteristicsFilters() {
      this.showCharacteristicsFiltersDialog = false;
    },
    clearCharacteristicsFilters() {
      this.characteristicFilterDate = [];
      this.filterCharacteristicsSubject = undefined;
      this.filterCharacteristicsCreator = undefined;
      this.scheduleCharacteristicsFilterUserGroup = undefined;
      this.showCharacteristicsFiltersDialog = false;
    },

    clearFilters() {
      this.eventFilterDate = [];
      this.eventFilterBeginDate = null;
      this.eventFilterEndDate = null;
      this.scheduleFilterUserGroup = undefined;
      this.filterCognationSubject = undefined;
      this.filterCognationCategory = undefined;
      this.filterCognationCreator = undefined;
      this.showFiltersDialog = false;
    },
    clearMyFilters() {
      this.eventFilterMyDate = [];
      this.filterMyCognationCategory = undefined;
      this.showMyFiltersDialog = false;
    },
    eventScheduleUserGroupFilter(item, queryText, itemText) {
      return (
          itemText.raw.personGroupName
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          itemText.raw.personGroupDescription
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    childFilter(item, queryText, itemText) {
      const textFirstname = itemText.raw.firstname.toLowerCase();
      const textLastname = itemText.raw.lastname.toLowerCase();
      const textSecname = itemText.raw.secname.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
          textFirstname.indexOf(searchText) > -1 ||
          textLastname.indexOf(searchText) > -1 ||
          textSecname.indexOf(searchText) > -1
      );
    },
    categoryFilter(item, queryText, itemText) {
      const textName = itemText.raw.name.toLowerCase();
      const textDescription = itemText.raw.description.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
          textName.indexOf(searchText) > -1 ||
          textDescription.indexOf(searchText) > -1
      );
    },
    handleReflectionFileUpload() {
      this.fileName = this.$refs.reflectionFileBody.files[0].name;
      console.log(this.fileName);
      this.ext = this.fileName.substr(this.fileName.lastIndexOf(".") + 1);
      this.randomFileName =
          "sakhalin_school_files_refl_" + uuidv4() + "." + this.ext;
    },
    handleFileUpload() {
      this.fileName = this.$refs.fileBody.files[0].name;
      this.ext = this.fileName.substr(this.fileName.lastIndexOf(".") + 1);
      this.randomFileName =
          "sakhalin_school_files_" + uuidv4() + "." + this.ext;
    },
    addMyFile(item) {
      this.sourceId = item.id;
      this.showMyFileInput = true;
    },

    addMyVideoFile(item) {
      this.sourceId = item.id;
      this.showMyVideoFileInput = true;
    },
    addVideoFile(item) {
      this.sourceId = item.id;
      this.showVideoFileInput = true;
    },
    addFile(item) {
      this.sourceId = item.id;
      this.showFileInput = true;
    },
    addReflectionFile(item) {
      this.sourceId = item.id;
      this.showReflectionFileInput = true;
    },
    saveMyFile() {
      this.$refs.myFileInputForm.validate().then((result) => {
        if (result.valid) {
          this.downloadAnimation = true;
          let formData = new FormData();
          formData.append("file", this.$refs.myFileBody.files[0]);

          customAxios
              .post( "/upload/cognation/" +  this.sourceId, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + this.user.token,
                },
                onUploadProgress: function( progressEvent ) {
                  this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );

                }.bind(this)

              }).then((res) => {
            if (res.status === 201) {
              let index = getIndex(this.myCognations, res.data.id);
              this.myCognations.splice(index, 1, res.data);
              this.downloadAnimation = false;
              this.fileBody=[]
              this.uploadPercentage =0
              this.showMyFileInput = false;

            } else if (res.status === 207) {
              alert(res.data.message);
              this.showMyFileInput = false;
              this.fileBody=[]
              this.uploadPercentage =0
              console.log(res);
              this.downloadAnimation = false;
            } else {
              alert("Что-то пошло не так");
              this.showMyFileInput = false;
              console.log(res);
              this.downloadAnimation = false;
              this.fileBody=[]
              this.uploadPercentage =0
            }
          })
        }
      });
    },

    saveFile() {
      this.$refs.cognationFileForm.validate().then((result) => {
        if (result.valid) {
          this.downloadAnimation = true;
          let formData = new FormData();
          formData.append("file", this.$refs.fileBody.files[0]);

          customAxios.post( "/upload/cognation/" +  this.sourceId, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + this.user.token,
                },
                onUploadProgress: function( progressEvent ) {
                  this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );

                }.bind(this)

              }).then((res) => {
            if (res.status === 201) {
              let index = getIndex(this.cognations, res.data.id);
              this.cognations.splice(index, 1, res.data);
              this.downloadAnimation = false;
              this.fileBody=[]
              this.uploadPercentage =0
              this.showFileInput = false;

            } else if (res.status === 207) {
              alert(res.data.message);
              this.showFileInput = false;
              this.fileBody=[]
              this.uploadPercentage =0
              console.log(res);
              this.downloadAnimation = false;
            } else {
              alert("Что-то пошло не так");
              this.showFileInput = false;
              this.fileBody=[]
              this.uploadPercentage =0
              console.log(res);
              this.downloadAnimation = false;
            }
          })
        }
      });
    },


    saveReflectionFile() {
      this.$refs.fileReflectionForm.validate().then((result) => {
        if (result.valid) {
          this.downloadAnimation = true;
          let formData = new FormData();
          formData.append("file", this.$refs.reflectionFileBody.files[0]);

          customAxios.post( "/upload/reflection/" +  this.sourceId, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + this.user.token,
                },
                onUploadProgress: function( progressEvent ) {
                  this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );

                }.bind(this)

              }).then((res) => {
            if (res.status === 201) {
              let index = getIndex(this.characteristics, res.data.id);
              this.characteristics.splice(index, 1, res.data);
              this.reflectionFileBody=[]
              this.uploadPercentage =0
              this.downloadAnimation = false;
              this.showReflectionFileInput = false;
            } else if (res.status === 207) {
              alert(res.data.message);
              console.log(res);
              this.downloadAnimation = false;
              this.showReflectionFileInput = false;
              this.reflectionFileBody=[]
              this.uploadPercentage =0
            } else {
              alert("Что-то пошло не так");
              console.log(res);
              this.downloadAnimation = false;
              this.showReflectionFileInput = false;
              this.reflectionFileBody=[]
              this.uploadPercentage =0
            }
          })
        }
      });
    },


    hideFileInput() {
      this.fileName = "";
      this.fileDescrption = "";
      this.fileBody = [];
      this.sourceId = undefined;
      this.showFileInput = false;
      this.showMyFileInput = false;
    },

    hideReflectionFileInput() {
      this.fileName = "";
      this.fileDescrption = "";
      this.fileBody = [];
      this.sourceId = undefined;
      this.showReflectionFileInput = false;
    },

    byCharacteristicId() {
      if (this.lastCharacteristicSortKey.includes("id")) {
        this.characteristicSortOrder = this.characteristicSortOrder * -1;
      }
      this.characteristicSortKey = "id";
      this.lastCharacteristicSortKey = this.characteristicSortKey;
    },

    bySubjectId() {
      if (this.lastCharacteristicSortKey.includes("subjectId")) {
        this.characteristicSortOrder = this.characteristicSortOrder * -1;
      }
      this.characteristicSortKey = "subjectId";
      this.lastCharacteristicSortKey = this.characteristicSortKey;
    },
    byCreatorId() {
      if (this.lastCharacteristicSortKey.includes("creatorId")) {
        this.characteristicSortOrder = this.characteristicSortOrder * -1;
      }
      this.characteristicSortKey = "creatorId";
      this.lastCharacteristicSortKey = this.characteristicSortKey;
    },
    byCreateDate() {
      if (this.lastCharacteristicSortKey.includes("createDate")) {
        this.characteristicSortOrder = this.characteristicSortOrder * -1;
      }
      this.characteristicSortKey = "createDate";
      this.lastCharacteristicSortKey = this.characteristicSortKey;
    },
    byIsActual() {
      if (this.lastCharacteristicSortKey.includes("isActual")) {
        this.characteristicSortOrder = this.characteristicSortOrder * -1;
      }
      this.characteristicSortKey = "isActual";
      this.lastCharacteristicSortKey = this.characteristicSortKey;
    },

    byCategoryId() {
      if (this.lastCategoriesSortKey.includes("id")) {
        this.categoriesSortOrder = this.categoriesSortOrder * -1;
      }
      this.categoriesSortKey = "id";
      this.lastCategoriesSortKey = this.categoriesSortKey;
    },

    byCategoryName() {
      if (this.lastCategoriesSortKey.includes("name")) {
        this.categoriesSortOrder = this.categoriesSortOrder * -1;
      }
      this.categoriesSortKey = "name";
      this.lastCategoriesSortKey = this.categoriesSortKey;
    },

    byCategoryDescription() {
      if (this.lastCategoriesSortKey.includes("description")) {
        this.categoriesSortOrder = this.categoriesSortOrder * -1;
      }
      this.categoriesSortKey = "description";
      this.lastCategoriesSortKey = this.categoriesSortKey;
    },

    byCategoryCreateDate() {
      if (this.lastCategoriesSortKey.includes("createDate")) {
        this.categoriesSortOrder = this.categoriesSortOrder * -1;
      }
      this.categoriesSortKey = "createDate";
      this.lastCategoriesSortKey = this.categoriesSortKey;
    },

    byCategoryBegin() {
      if (this.lastCategoriesSortKey.includes("lifeBeginTime")) {
        this.categoriesSortOrder = this.categoriesSortOrder * -1;
      }
      this.categoriesSortKey = "lifeBeginTime";
      this.lastCategoriesSortKey = this.categoriesSortKey;
    },

    byCategoryEnd() {
      if (this.lastCategoriesSortKey.includes("lifeEndTime")) {
        this.categoriesSortOrder = this.categoriesSortOrder * -1;
      }
      this.categoriesSortKey = "lifeEndTime";
      this.lastCategoriesSortKey = this.categoriesSortKey;
    },

    byCategoryActive() {
      if (this.lastCategoriesSortKey.includes("isActive")) {
        this.categoriesSortOrder = this.categoriesSortOrder * -1;
      }
      this.categoriesSortKey = "isActive";
      this.lastCategoriesSortKey = this.categoriesSortKey;
    },

    byCognationDescription() {
      if (this.lastCognationSortKey.includes("cognationDescription")) {
        this.cognationSortOrder = this.cognationSortOrder * -1;
      }
      this.cognationSortKey = "cognationDescription";
      this.lastCognationSortKey = this.cognationSortKey;
    },

    byCognationCreateDate() {
      if (this.lastCognationSortKey.includes("cognationCreateDate")) {
        this.cognationSortOrder = this.cognationSortOrder * -1;
      }
      this.cognationSortKey = "cognationCreateDate";
      this.lastCognationSortKey = this.cognationSortKey;
    },
    byCognationId() {
      if (this.lastCognationSortKey.includes("id")) {
        this.cognationSortOrder = this.cognationSortOrder * -1;
      }
      this.cognationSortKey = "id";
      this.lastCognationSortKey = this.cognationSortKey;
    },

    byCognationCategoryId() {
      if (this.lastCognationSortKey.includes("categoryId")) {
        this.cognationSortOrder = this.cognationSortOrder * -1;
      }
      this.cognationSortKey = "categoryId";
      this.lastCognationSortKey = this.cognationSortKey;
    },

    byCognationChildId() {
      if (this.lastCognationSortKey.includes("childId")) {
        this.cognationSortOrder = this.cognationSortOrder * -1;
      }
      this.cognationSortKey = "childId";
      this.lastCognationSortKey = this.cognationSortKey;
    },

    byCognationIsModerated() {
      if (this.lastCognationSortKey.includes("isModerated")) {
        this.cognationSortOrder = this.cognationSortOrder * -1;
      }
      this.cognationSortKey = "isModerated";
      this.lastCognationSortKey = this.cognationSortKey;
    },
    cognationCategoryFilter(item, queryText, itemText) {
      return (
          itemText.raw.name
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          itemText.raw.description
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    deleteReflectionFile(file, item) {
      let cognationFile = {
        fileId: file.id,
      };

      customAxios.put("/cognation/reflection/file/delete/" + item.id, cognationFile)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.cognations, response.data.id);
              this.cognations.splice(index, 1, response.data);
            } else {
              alert("Что-то пошло не так");
              console.log(response);
            }
          })

    },

    deleteFile(file, item) {
      let cognationFile = {
        fileId: file.id,
      };

      customAxios.put("/cognation/my/file/delete/" + item.id, cognationFile)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.cognations, response.data.id);
              this.cognations.splice(index, 1, response.data);
            } else {
              alert("Что-то пошло не так");
              console.log(response);
            }
          })
    },
    deleteVideoFile(file) {

      customAxios.delete("/video/upload/" + file.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.cognations, response.data.id);
              this.cognations.splice(index, 1, response.data);
            } else {
              alert("Что-то пошло не так");
              console.log(response);
            }
          })
    },
    downloadFile: function (item) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
        responseType: "blob",
        onDownloadProgress: function( progressEvent ) {
          this.downloadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this)

      };
      console.log("item.randomFileName: " + item.randomFileName);
      console.log("item.fileName: " + item.fileName);
      customAxios.get( "/downloadFile/cognation/" + item.randomFileName,
          config
      ).then((resp) => {
        userService.handleResponse(resp);
        download(resp.data, item.fileName);
      });
    },
    editCognation(item) {
      this.cognationId = item.id;
      this.cognationName = item.cognationName;
      this.categoryLevelId = item.categoryLevelId;
      this.cognationDescription = item.cognationDescription;
      this.staffDescription = item.staffDescription;
      this.cognationCategory = item.cognationCategory;
      this.child = item.child;
      this.isModerated = item.isModerated;

      customAxios.get( "/cognation/category/level/" + item.categoryId)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data)
              this.categoryCognationLevels = response.data
            }
          })
      this.cognationDialog = true;
    },
    editCategory(item) {
      this.sectionId = item.id;
      this.name = item.name;
      this.description = item.description;
      this.score = item.score;
      this.lifeBeginTime = item.lifeBeginTime;
      this.lifeEndTime = item.lifeEndTime;
      this.isActive = item.isActive;
      this.sectionDialog = true;
    },
    showDialog() {
      if (this.part === 1) {
        this.cognationDialog = true;
      } else {
        this.sectionDialog = true;
      }
    },
    closeCognationDialog() {
      this.cognationDialog = false;
      this.cognationId = "";
      this.cognationName = "";
      this.staffDescription = "";
      this.categoryLevelId = undefined;
      this.cognationDescription = "";
      this.cognationFiles = [];
      this.cognationCategory = null;
      this.isModerated = false;
      this.child = null;
    },
    closeCategoryDialog() {
      this.sectionDialog = false;
      this.sectionId = "";
      this.name = "";
      this.description = "";
      this.isActive = false;
      this.lifeBeginTime = null;
      this.lifeEndTime = null;
    },

    closeUserGroupForm() {
      this.dialog = false;
      this.personGroupName = "";
      this.personGroupDescription = "";
      this.isLocked = false;
    },
    clearSearch() {
      this.searchBody = "";
    },


    saveCharacteristicWithFiles() {

      this.$refs.characteristicForm.validate().then((result) => {
        if (result.valid) {
          if (this.subject instanceof Object) this.subject = this.subject.id;

          let characteristic = {
            characteristicsBody: capitalizeFirstLetter(this.characteristicsBody),
            subjectId: this.subject,
            isActual: this.isActual,
          };


          if (this.characteristicId) {
            customAxios.put("/cognation/characteristics/" + this.characteristicId, characteristic)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.characteristics, response.data.id);
                    this.characteristics.splice(index, 1, response.data);
                    this.characteristicDialog = false;
                    this.characteristicId = "";
                    this.characteristicsBody = "";
                    this.subjectId = null;
                    this.subject = null;
                    this.isActive = false;
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
          } else {
            customAxios.post( "/cognation/characteristics/", characteristic)
                .then((response) => {
                  if (response.status === 201) {

                    if(this.$refs.files.files.length>0){
                      let formData = new FormData();

                      for (var i = 0; i < this.$refs.files.files.length; i++ ){
                        let file = this.$refs.files.files[i];
                        formData.append('files', file);
                      }
                      customAxios
                          .post( "/cognation/files/characteristics/" + response.data.id, formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                              Authorization: "Bearer " + this.user.token,
                            },
                            onUploadProgress: function( progressEvent ) {
                              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                            }.bind(this)


                          })
                          .then((response2) => {
                            if(response2.status===201){
                              this.characteristics.push(response2.data);
                              this.characteristicDialog = false;
                              this.characteristicId = "";
                              this.characteristicsBody = "";
                              this.subjectId = null;
                              this.subject = null;
                              this.isActive = false;
                              this.uploadPercentage = 0
                              this.cognationFiles = [];
                            } else if(response2.status===207){
                              alert(response.data2.message);
                              this.characteristics.push(response2.data);
                              this.characteristicDialog = false;
                              this.characteristicId = "";
                              this.characteristicsBody = "";
                              this.subjectId = null;
                              this.subject = null;
                              this.isActive = false;
                              this.uploadPercentage = 0
                              this.cognationFiles = [];
                              console.log(response);
                            }
                          });
                    } else {
                      this.characteristics.push(response.data);
                      this.characteristicDialog = false;
                      this.characteristicId = "";
                      this.characteristicsBody = "";
                      this.subjectId = null;
                      this.subject = null;
                      this.isActive = false;
                      this.uploadPercentage = 0
                      this.cognationFiles = [];

                    }

                  } else if (response.status === 207) {
                    alert(response.data.message);
                    this.characteristicDialog = false;
                    this.characteristicId = "";
                    this.characteristicsBody = "";
                    this.subjectId = null;
                    this.subject = null;
                    this.isActive = false;
                    this.uploadPercentage = 0
                    this.cognationFiles = [];

                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log("error: " + error);
                });
          }
        }
      });
    },


    deleteCharacteristic(item) {

      if (confirm("Вы точно хотите удалить запись?")) {
        customAxios.delete("/cognation/characteristics/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.characteristics.splice(
                    this.characteristics.indexOf(item),
                    1
                );
              } else if (response.status === 207) {
                alert(response.data.message);
                console.log(response);
              } else {
                console.log(response);
              }
            })

      }
    },
    editCharacteristic(item) {
      this.characteristicId = item.id;
      this.characteristicsBody = item.characteristicsBody;
      this.isActual = item.isActual;
      this.subject = item.subject;
      this.characteristicDialog = true;
    },
    closeCharacteristic() {
      this.characteristicId = '';
      this.characteristicsBody = "";
      this.isActual = false;
      this.subject = null;
      this.subjectId = null;
      this.cognationFiles = [];

      this.characteristicDialog = false;
    },

    saveSection() {

      this.lifeBeginTimeAlert =
          this.lifeBeginTime === null || this.lifeBeginTime === undefined;

      this.lifeEndTimeAlert =
          this.lifeEndTime === null || this.lifeEndTime === undefined;

      if (!this.lifeBeginTimeAlert && !this.lifeEndTimeAlert) {
        this.dateAlert =
            Date.parse(this.lifeBeginTime) >= Date.parse(this.lifeEndTime);
      }
      this.$refs.sectionForm.validate().then((result) => {
        if (
            result.valid &&
            !this.lifeBeginTimeAlert &&
            !this.lifeEndTimeAlert &&
            !this.dateAlert
        ) {
          let category = {
            name: capitalizeFirstLetter(this.name),
            description: capitalizeFirstLetter(this.description),
            score: 0,
            lifeBeginTime: this.lifeBeginTime,
            lifeEndTime: this.lifeEndTime,
            isActive: this.isActive,
          };

          if (this.sectionId) {
            customAxios.put("/cognation/category/" + this.sectionId, category)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.categories, response.data.id);
                    this.categories.splice(index, 1, response.data);
                    this.sectionDialog = false;
                    this.sectionId = "";
                    this.name = "";
                    this.description = "";
                    this.isActive = false;
                    this.lifeBeginTime = null;
                    this.lifeEndTime = null;
                    this.lifeBeginTime = false;
                    this.lifeBeginTimeAlert = false;
                    this.lifeEndTimeAlert = false;
                    this.dateAlert = false;
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          } else {
            customAxios.post( "/cognation/category", category)
                .then((response) => {
                  if (response.status === 201) {
                    this.categories.push(response.data);
                    this.sectionDialog = false;
                    this.name = "";
                    this.description = "";
                    this.isActive = false;
                    this.lifeBeginTime = null;
                    this.lifeEndTime = null;
                    this.lifeBeginTimeAlert = false;
                    this.lifeEndTimeAlert = false;
                    this.dateAlert = false;
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
        } else {
          alert("Проверьте корректность заполнения полей!");
        }
      });
    },

    saveCognationWithFiles() {

      this.$refs.cognationForm.validate().then((result) => {
        if (result.valid) {
          if (this.cognationCategory instanceof Object) {
            this.cognationCategory = this.cognationCategory.id;
          }

          if (this.child instanceof Object) {
            this.child = this.child.id;
          }

          let cognation = {
            cognationName: capitalizeFirstLetter(this.cognationName),
            cognationDescription: capitalizeFirstLetter(this.cognationDescription),
            categoryId: this.cognationCategory,
            childId: this.child,
            isModerated: this.isModerated,
            staffDescription: this.staffDescription,
            categoryLevelId: this.categoryLevelId
          };


          if (this.cognationId) {
            customAxios.put( "/cognation/" + this.cognationId, cognation)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.cognations,   response.data.id);
                    this.cognations.splice(index, 1, response.data);
                    this.cognationDialog = false;
                    this.cognationId = "";
                    this.cognationName = "";
                    this.staffDescription = "";
                    this.categoryLevelId = undefined;
                    this.cognationDescription = "";
                    this.cognationCategory = null;
                    this.cognationFiles = [];
                    this.isModerated = false;
                    this.child = null;
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    this.cognationDialog = false;
                    this.categoryLevelId = undefined;
                    this.cognationId = "";
                    this.cognationName = "";
                    this.staffDescription = "";
                    this.cognationDescription = "";
                    this.cognationCategory = null;
                    this.cognationFiles = [];
                    this.isModerated = false;
                    this.child = null;
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log("error: " + error);
                });
          } else {
            customAxios.post( "/cognation/", cognation)
                .then((response) => {
                  if (response.status === 201) {
                    if(this.$refs.files.files.length>0){
                      let formData = new FormData();

                      for (var i = 0; i < this.$refs.files.files.length; i++ ){
                        let file = this.$refs.files.files[i];
                        formData.append('files', file);
                      }

                      customAxios
                          .post( "/cognation/files/" + response.data.id, formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                              Authorization: "Bearer " + this.user.token,
                            },
                            onUploadProgress: function( progressEvent ) {
                              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                            }.bind(this)


                          })
                          .then((response2) => {
                            if(response2.status===201){
                              this.cognations.push(response2.data);
                              this.cognationDialog = false;
                              this.uploadPercentage = 0
                              this.cognationName = "";
                              this.cognationDescription = "";
                              this.categoryLevelId = undefined;
                              this.staffDescription = "";
                              this.cognationFiles = [];
                              this.cognationCategory = null;
                              this.isModerated = false;
                              this.child = null;
                            } else if(response2.status===207){
                              this.cognations.push(response2.data);
                              this.cognationDialog = false;
                              this.staffDescription = "";
                              this.categoryLevelId = undefined;
                              this.uploadPercentage = 0
                              this.cognationName = "";
                              this.cognationDescription = "";
                              this.cognationFiles = [];
                              this.cognationCategory = null;
                              this.isModerated = false;
                              this.child = null;
                            }
                          });

                    }  else {
                      this.cognations.push(response.data);
                      this.cognationDialog = false;
                      this.uploadPercentage = 0
                      this.cognationName = "";
                      this.cognationDescription = "";
                      this.categoryLevelId = undefined;
                      this.staffDescription = "";
                      this.cognationFiles = [];
                      this.cognationCategory = null;
                      this.isModerated = false;
                      this.child = null;
                    }




                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                    this.cognationDialog = false;
                    this.uploadPercentage = 0
                    this.cognationName = "";
                    this.cognationDescription = "";
                    this.categoryLevelId = undefined;
                    this.cognationFiles = [];
                    this.cognationCategory = null;
                    this.isModerated = false;
                    this.child = null;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log("error: " + error);
                });
          }
        }
      });
    },

    saveMyCognationWithFiles() {


      this.$refs.myCognationForm.validate().then((result) => {
        if (result.valid) {
          if (this.cognationCategory instanceof Object) {
            this.cognationCategory = this.cognationCategory.id;
          }

          if (this.child instanceof Object) {
            this.child = this.child.id;
          }

          let cognation = {
            cognationName: capitalizeFirstLetter(this.cognationName),
            cognationDescription: capitalizeFirstLetter(this.cognationDescription),
            categoryId: this.cognationCategory,
            isModerated: this.isModerated
          };


          if (this.cognationId) {
            customAxios.put( "/cognation/my/" + this.cognationId, cognation)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.myCognations,   response.data.id);
                    this.myCognations.splice(index, 1, response.data);
                    this.myCognationDialog = false;
                    this.cognationId = "";
                    this.cognationName = "";
                    this.cognationDescription = "";
                    this.cognationCategory = null;
                    this.cognationFiles = [];
                    this.isModerated = false;
                    this.child = null;
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                    this.myCognationDialog = false;
                    this.cognationId = "";
                    this.cognationName = "";
                    this.cognationDescription = "";
                    this.cognationCategory = null;
                    this.cognationFiles = [];
                    this.isModerated = false;
                    this.child = null;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log("error: " + error);
                });
          } else {
            customAxios.post( "/cognation/my", cognation)
                .then((response) => {
                  if (response.status === 201) {


                    if(this.$refs.files.files.length>0){
                      let formData = new FormData();

                      for (var i = 0; i < this.$refs.files.files.length; i++ ){
                        let file = this.$refs.files.files[i];
                        formData.append('files', file);
                      }

                      customAxios
                          .post( "/cognation/files/my/" + response.data.id, formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                              Authorization: "Bearer " + this.user.token,
                            },
                            onUploadProgress: function( progressEvent ) {
                              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                            }.bind(this)


                          })
                          .then((response2) => {
                            if(response2.status===201){
                              this.myCognations.push(response2.data);
                              this.myCognationDialog = false;
                              this.cognationName = "";
                              this.cognationDescription = "";
                              this.cognationFiles = [];
                              this.uploadPercentage = 0
                              this.cognationCategory = null;
                              this.isModerated = false;
                              this.child = null;
                            } else if(response2.status===207){
                              alert(response.data.message);
                              this.myCognations.push(response2.data);
                              this.myCognationDialog = false;
                              this.cognationName = "";
                              this.cognationDescription = "";
                              this.cognationFiles = [];
                              this.uploadPercentage = 0
                              this.cognationCategory = null;
                              this.isModerated = false;
                              this.child = null;
                            }
                          });
                    } else {
                      this.myCognations.push(response.data);
                      this.myCognationDialog = false;
                      this.cognationName = "";
                      this.cognationDescription = "";
                      this.cognationFiles = [];
                      this.uploadPercentage = 0
                      this.cognationCategory = null;
                      this.isModerated = false;
                      this.child = null;
                    }



                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log("error: " + error);
                });
          }
        }
      });
    },


    deleteCategory(item) {

      if (confirm("Вы точно хотите заблокировать область?")) {
        customAxios
            .delete( "/cognation/category/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.categories.splice(this.categories.indexOf(item), 1);
                this.activeCatigories.splice(
                    this.activeCatigories.indexOf(item),
                    1
                );
              } else if (response.status === 207) {
                alert(response.data.message);
                console.log(response);
              } else {
                alert("Что-то пошло не так");
                console.log(response);
              }
            })

      }
    },
    deleteCognation(item) {

      if (confirm("Вы точно хотите удалить достижение?")) {
        customAxios
            .delete( "/cognation/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.cognations.splice(this.cognations.indexOf(item), 1);
              } else if (response.status === 207) {
                alert(response.data.message);
                console.log(response);
              } else {
                alert("Что-то пошло не так");
                console.log(response);
              }
            })

      }
    },
  },
  computed: {

    isParent() {
      return this.$store.state.authentication.user.roles.includes(
          "ROLE_PARENT"
      );
    },
    isChild() {
      return this.$store.state.authentication.user.roles.includes("ROLE_CHILD");
    },
    isTeacher() {
      return this.$store.state.authentication.user.roles.includes(
          "ROLE_TEACHER"
      );
    },
    isTutor() {
      return this.$store.state.authentication.user.roles.includes("ROLE_TUTOR");
    },
    isAdmin() {
      return this.$store.state.authentication.user.roles.includes("ROLE_ADMIN");
    },
    isMaster() {
      return this.$store.state.authentication.user.roles.includes(
          "ROLE_MASTER"
      );
    },

    myFilteredCognations() {
      const ss = this.myCognationSearchBody.toLowerCase();

      let tempCognations = this.myCognations;

      return tempCognations.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },

    filteredMyCognationsByData() {
      let tempCognations = this.myFilteredCognations;

      if (this.eventFilterMyDate.length > 0) {
        console.log(
            "Begin: " +
            this.eventFilterMyDate[0] +
            "Filter end eventFilterMyDate: " +
            this.eventFilterDate[1]
        );
        return tempCognations.filter((sortedCognation) => {
          if (!!this.eventFilterMyDate[0] & !!this.eventFilterMyDate[1]) {
            console.log("variant 1");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.cognationCreateDate);
            let beginDateTimestump;
            beginDateTimestump = toTimestamp(this.eventFilterMyDate[0]);
            let endDateTimestump;
            endDateTimestump = toTimestamp(this.eventFilterMyDate[1]);
            if (
                (dateTimestump >= beginDateTimestump) &
                (dateTimestump <= endDateTimestump)
            ) {
              return sortedCognation;
            }
          } else if (!this.eventFilterMyDate[0] & !this.eventFilterMyDate[1]) {
            console.log("variant 2");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.cognationCreateDate);
            let beginDateTimestump;
            beginDateTimestump = toTimestamp(this.eventFilterMyDate[0]);
            if (dateTimestump >= beginDateTimestump) {
              return sortedCognation;
            }
          } else if (!this.eventFilterMyDate[0] & !!this.eventFilterMyDate[1]) {
            console.log("variant 3");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.cognationCreateDate);
            let endDateTimestump;
            endDateTimestump = toTimestamp(this.eventFilterMyDate[1]);
            if (dateTimestump <= endDateTimestump) {
              return sortedCognation;
            }
          }
        });
      } else {
        return tempCognations;
      }
    },
    filteredMyCognationByСategory() {
      let tempCognations = this.filteredMyCognationsByData;

      if (this.filterMyCognationCategory) {
        return tempCognations.filter((sortedCognation) => {
          if (sortedCognation.categoryId) {
            if (sortedCognation.categoryId === this.filterMyCognationCategory) {
              return sortedCognation;
            }
          }
        });
      } else {
        return tempCognations;
      }
    },
    mySortedCognations() {
      let tempCognations = this.filteredMyCognationByСategory;
      return tempCognations.sort(
          dynamicSort(this.mylastCognationSortKey, this.myCognationSortOrder)
      );
    },
    myCognationPageCount() {
      let l = this.mySortedCognations.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    myCognationPaginatedData() {
      const start =
              (this.myCurrentCognationPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.mySortedCognations.slice(start, end);
    },
    myCurrentCognationPage() {
      if (this.myCognationPageCount < this.myCognationPage) {
        return 1;
      } else {
        return this.myCognationPage;
      }
    },

    filteredCharacteristics() {
      const ss = this.characteristicSearchBody.toLowerCase();

      let tempCharacteristics = this.characteristics;

      return tempCharacteristics.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },
    sortedCharacteristics() {
      let tempCharacteristics = this.filteredCharacteristics;
      return tempCharacteristics.sort(
          dynamicSort(this.characteristicSortKey, this.characteristicSortOrder)
      );
    },
    filteredCharacteristicsByData() {
      let tempCognations = this.sortedCharacteristics;

      if (this.characteristicFilterDate.length > 0) {
        return tempCognations.filter((sortedCognation) => {
          if (
              !!this.characteristicFilterDate[0] &
              !!this.characteristicFilterDate[1]
          ) {
            console.log("variant 1");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.createDate);
            let beginDateTimestump;
            beginDateTimestump = toTimestamp(this.characteristicFilterDate[0]);
            let endDateTimestump;
            endDateTimestump = toTimestamp(this.characteristicFilterDate[1]);
            if (
                (dateTimestump >= beginDateTimestump) &
                (dateTimestump <= endDateTimestump)
            ) {
              return sortedCognation;
            }
          } else if (
              !this.characteristicFilterDate[0] &
              !this.characteristicFilterDate[1]
          ) {
            console.log("variant 2");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.createDate);
            let beginDateTimestump;
            beginDateTimestump = toTimestamp(this.characteristicFilterDate[0]);
            if (dateTimestump >= beginDateTimestump) {
              return sortedCognation;
            }
          } else if (
              !this.characteristicFilterDate[0] &
              !!this.characteristicFilterDate[1]
          ) {
            console.log("variant 3");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.createDate);
            let endDateTimestump;
            endDateTimestump = toTimestamp(this.characteristicFilterDate[1]);
            if (dateTimestump <= endDateTimestump) {
              return sortedCognation;
            }
          }
        });
      } else {
        return tempCognations;
      }
    },
    filteredCharacteristicsByUser() {
      let tempCognations = this.filteredCharacteristicsByData;

      if (this.filterCharacteristicsSubject) {
        return tempCognations.filter((sortedCognation) => {
          if (sortedCognation.subjectId === this.filterCharacteristicsSubject) {
            return sortedCognation;
          }
        });
      } else {
        return tempCognations;
      }
    },
    filteredCharacteristicsByCreator() {
      let tempCognations = this.filteredCharacteristicsByUser;

      if (this.filterCharacteristicsCreator) {
        return tempCognations.filter((sortedCognation) => {
          if (sortedCognation.creatorId === this.filterCharacteristicsCreator) {
            return sortedCognation;
          }
        });
      } else {
        return tempCognations;
      }
    },

    filteredCharacteristicsByUserGroup() {
      let tempCognations = this.filteredCharacteristicsByCreator;

      if (this.scheduleCharacteristicsFilterUserGroup) {
        return tempCognations.filter((sortedCognation) => {
          if (sortedCognation.subject.personGroups) {
            let index = getIndex(
                sortedCognation.subject.personGroups,
                this.scheduleCharacteristicsFilterUserGroup
            );
            if (index > -1) {
              return sortedCognation;
            }
          }
        });
      } else {
        return tempCognations;
      }
    },
    characteristicPageCount() {
      let l = this.filteredCharacteristicsByUserGroup.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    characteristicPaginatedData() {
      const start =
              (this.currentCharacteristicPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.filteredCharacteristicsByUserGroup.slice(start, end);
    },
    currentCharacteristicPage() {
      if (this.characteristicPageCount < this.characteristicPage) {
        return 1;
      } else {
        return this.characteristicPage;
      }
    },

    filteredUsersByUserGroup() {
      let tempUsers = this.users;

      if (this.scheduleFilterUserGroup) {
        return tempUsers.filter((sortedUser) => {
          if (sortedUser.personGroups) {
            if (
                getIndex(sortedUser.personGroups, this.scheduleFilterUserGroup) >
                -1
            ) {
              return sortedUser;
            }
          }
        });
      } else {
        return tempUsers;
      }
    },
    filteredUsersByUserGroupForChar() {
      let tempUsers = this.users;

      if (this.scheduleCharacteristicsFilterUserGroup) {
        return tempUsers.filter((sortedUser) => {
          if (sortedUser.personGroups) {
            if (
                getIndex(
                    sortedUser.personGroups,
                    this.scheduleCharacteristicsFilterUserGroup
                ) > -1
            ) {
              return sortedUser;
            }
          }
        });
      } else {
        return tempUsers;
      }
    },
    filteredUsersByUserGroupForCharacteristics() {
      let tempUsers = this.users;

      if (this.subjectGroupCreateCharacteristicsFilter) {
        return tempUsers.filter((sortedUser) => {
          if (sortedUser.personGroups) {
            if (
                getIndex(
                    sortedUser.personGroups,
                    this.subjectGroupCreateCharacteristicsFilter
                ) > -1
            ) {
              return sortedUser;
            }
          }
        });
      } else {
        return tempUsers;
      }
    },
    filteredUsersByUserGroupForCognation() {
      let tempUsers = this.users;

      if (this.subjectGroupCreateCognationFilter) {
        return tempUsers.filter((sortedUser) => {
          if (sortedUser.personGroups) {
            if (
                getIndex(
                    sortedUser.personGroups,
                    this.subjectGroupCreateCognationFilter
                ) > -1
            ) {
              return sortedUser;
            }
          }
        });
      } else {
        return tempUsers;
      }
    },

    filteredCognations() {
      const ss = this.cognationSearchBody.toLowerCase();

      let tempCognations = this.cognations;

      return tempCognations.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },
    sortedCognations() {
      let tempCognations = this.filteredCognations;
      return tempCognations.sort(
          dynamicSort(this.cognationSortKey, this.cognationSortOrder)
      );
    },
    filteredCognationsByData() {
      let tempCognations = this.sortedCognations;

      if (this.eventFilterDate.length > 0) {
        console.log(
            "Begin: " +
            this.eventFilterDate[0] +
            "Filter end date: " +
            this.eventFilterDate[1]
        );
        return tempCognations.filter((sortedCognation) => {
          if (!!this.eventFilterDate[0] & !!this.eventFilterDate[1]) {
            console.log("variant 1");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.cognationCreateDate);
            let beginDateTimestump;
            beginDateTimestump = toTimestamp(this.eventFilterDate[0]);
            let endDateTimestump;
            endDateTimestump = toTimestamp(this.eventFilterDate[1]);
            if (
                (dateTimestump >= beginDateTimestump) &
                (dateTimestump <= endDateTimestump)
            ) {
              return sortedCognation;
            }
          } else if (!this.eventFilterDate[0] & !this.eventFilterDate[1]) {
            console.log("variant 2");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.cognationCreateDate);
            let beginDateTimestump;
            beginDateTimestump = toTimestamp(this.eventFilterDate[0]);
            if (dateTimestump >= beginDateTimestump) {
              return sortedCognation;
            }
          } else if (!this.eventFilterDate[0] & !!this.eventFilterDate[1]) {
            console.log("variant 3");
            let dateTimestump;
            dateTimestump = toTimestamp(sortedCognation.cognationCreateDate);
            let endDateTimestump;
            endDateTimestump = toTimestamp(this.eventFilterDate[1]);
            if (dateTimestump <= endDateTimestump) {
              return sortedCognation;
            }
          }
        });
      } else {
        return tempCognations;
      }
    },
    filteredCognationByUser() {
      let tempCognations = this.filteredCognationsByData;

      if (this.filterCognationSubject) {
        return tempCognations.filter((sortedCognation) => {
          if (sortedCognation.childId === this.filterCognationSubject) {
            return sortedCognation;
          }
        });
      } else {
        return tempCognations;
      }
    },
    filteredCognationByCreator() {
      let tempCognations = this.filteredCognationByUser;

      if (this.filterCognationCreator) {
        return tempCognations.filter((sortedCognation) => {
          if (sortedCognation.creatorId === this.filterCognationCreator) {
            return sortedCognation;
          }
        });
      } else {
        return tempCognations;
      }
    },
    filteredCognationByUserGroup() {
      let tempCognations = this.filteredCognationByCreator;

      if (this.scheduleFilterUserGroup) {
        return tempCognations.filter((sortedCognation) => {
          if(sortedCognation.child!==null){
            if (sortedCognation.child.personGroups) {
              let index = getIndex(
                  sortedCognation.child.personGroups,
                  this.scheduleFilterUserGroup
              );
              if (index > -1) {
                return sortedCognation;
              }
            }
          }

        });
      } else {
        return tempCognations;
      }
    },
    filteredCognationByСategory() {
      let tempCognations = this.filteredCognationByUserGroup;

      if (this.filterCognationCategory) {
        return tempCognations.filter((sortedCognation) => {
          if (sortedCognation.categoryId) {
            if (sortedCognation.categoryId === this.filterCognationCategory) {
              return sortedCognation;
            }
          }
        });
      } else {
        return tempCognations;
      }
    },
    cognationPageCount() {
      let l = this.filteredCognationByСategory.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    cognationPaginatedData() {
      const start = (this.currentCognationPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.filteredCognationByСategory.slice(start, end);
    },
    currentCognationPage() {
      if (this.cognationPageCount < this.cognationPage) {
        return 1;
      } else {
        return this.cognationPage;
      }
    },

    filteredCategories() {
      const ss = this.cognationCategorySearchBody.toLowerCase();

      let tempCategories = this.categories;

      return tempCategories.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },
    sortedCategories() {
      let tempCategories = this.filteredCategories;
      return tempCategories.sort(
          dynamicSort(this.categoriesSortKey, this.categoriesSortOrder)
      );
    },

    categoriesPageCount() {
      let l = this.filteredCategories.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    categoriesPaginatedData() {
      const start = (this.currentCategoriesPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedCategories.slice(start, end);
    },
    currentCategoriesPage() {
      if (this.categoriesPageCount < this.categoryPage) {
        return 1;
      } else {
        return this.categoryPage;
      }
    },
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },
    user() {
      return this.$store.state.authentication.user;
    },
    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {
    this.showLoader = true;

    function getChilds(axios) {
      return axios.get( "/user/child");
    }

    function getCognations(axios) {
      return axios.get( "/cognation");
    }

    function getCognationCategoryes(axios) {
      return axios.get( "/cognation/category");
    }

    function getActiveCognationCategoryes(axios) {
      return axios.get( "/cognation/category/active");
    }
    function getCharacteristics(axios) {
      return axios.get( "/cognation/characteristics");
    }

    function getUserGroups(axios) {
      return axios.get( "/user_group");
    }
    function getUsers(axios) {
      return axios.get( "/user");
    }
    function getMyProfile(axios) {
      return axios.get( "/user/i");
    }
    function getMyCognations(axios) {
      return axios.get( "/cognation/my");
    }
    function getFinalLevels(axios) {
      return axios.get( "/cognation/category/level/final");
    }

    if (
        this.$store.state.authentication.user.roles.includes("ROLE_ADMIN") ||
        this.$store.state.authentication.user.roles.includes("ROLE_MASTER") ||
        this.$store.state.authentication.user.roles.includes("ROLE_TUTOR") ||
        this.$store.state.authentication.user.roles.includes("ROLE_TEACHER")
    ) {
      Promise.all([
        getChilds(customAxios),
        getCognations(customAxios),
        getCognationCategoryes(customAxios),
        getActiveCognationCategoryes(customAxios),
        getCharacteristics(customAxios),
        getUserGroups(customAxios),
        getUsers(customAxios),
        getMyProfile(customAxios),
        getMyCognations(customAxios),
        getFinalLevels(customAxios),
      ])
          .then((results) => {
            if (results[0].status === 200) {
              this.childs = results[0].data;
            }

            if (results[1].status === 200) {
              this.cognations = results[1].data;
            }

            if (results[2].status === 200) {
              this.categories = results[2].data;
            }

            if (results[3].status === 200) {
              this.activeCatigories = results[3].data;
            }

            if (results[4].status === 200) {
              this.characteristics = results[4].data;
            }
            if (results[5].status === 200) {
              this.userGroups = results[5].data;
            }
            if (results[6].status === 200) {
              this.users = results[6].data;
            }
            if (results[7].status === 200) {
              this.myProfile = results[7].data;
            }
            if (results[8].status === 200) {
              this.myCognations = results[8].data;
            }
            if (results[9].status === 200) {
              this.finalLevels = results[9].data;
            }
            this.showLoader = false;
          })
          .catch(() => {
            this.showLoader = false;

          });
    } else {
      Promise.all([
        getActiveCognationCategoryes(customAxios),
        getMyProfile(customAxios),
        getMyCognations(customAxios),
      ])
          .then((results) => {
            if (results[0].status === 200) {
              this.activeCatigories = results[0].data;
            }
            if (results[1].status === 200) {
              this.myProfile = results[1].data;
            }
            if (results[2].status === 200) {
              this.myCognations = results[2].data;
            }
            this.showLoader = false;
          })
          .catch(() => {
            this.showLoader = false;

          });
    }
  },
};
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
  );
}
function toTimestamp(strDate) {
  var datum = Date.parse(strDate);
  return datum / 1000;
}

function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
</script>
<style>
.app {
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
.test-player-wrap {
  width: 720px;
  height: 405px;
  position: relative;
  margin: 20px auto;
}
.btn-play {
  color: white;
  margin-right: 10px;
  cursor: pointer;
}
.btn-play svg {
  width: 16px;
}
</style>
