import CommonRatingSheets from "@/teacher-journal/CommonRatingSheets.vue";
import JList from "@/teacher-journal/JList.vue";

import JEditor from "@/teacher-journal/JEditor.vue";
import JLayout from "@/teacher-journal/JLayout.vue";
import JMainPage from "@/teacher-journal/JMainPage.vue";

import JRatingSheets from "@/teacher-journal/JRatingSheets.vue";
import JDictations from "@/teacher-journal/JDictations.vue";

import ForeignSheetEditor from "@/teacher-journal/rating-sheets/foreign/SheetEditor.vue";
import SheetEditor from "@/teacher-journal/rating-sheets/SheetEditor.vue";
import ForeignSheetPage from "@/teacher-journal/rating-sheets/foreign/SheetPage.vue";

import SheetPage from "@/teacher-journal/rating-sheets/SheetPage.vue";
import SheetsTaskEditor from "@/teacher-journal/rating-sheets/SheetsTaskEditor.vue";
import DictationEditor from "@/teacher-journal/dictations/DictationEditor.vue";

import DictationPage from "@/teacher-journal/dictations/DictationPage.vue";
import ForeignDictationEditor from "@/teacher-journal/dictations/foreign/DictationEditor.vue";
import ForeignDictationPage from "@/teacher-journal/dictations/foreign/DictationPage.vue";

const journalsList = {
  name: "teacher-journal-list",
  path: "/subjects-journals",
  component: JList,
};

const commonRatingSheets = {
  name: "common-rating-sheets",
  path: "/common-rating-sheets",
  component: CommonRatingSheets,
};

const sheets = [
  { path: "sheets", name: "sheets", component: JRatingSheets },

  {
    name: "foreign-sheet-create",
    path: "foreign-sheet-create",
    component: ForeignSheetEditor,
  },
  {
    name: "foreign-sheet-edit",
    path: "foreign-sheet-edit",
    component: ForeignSheetPage,
  },
  {
    name: "sheet-create",
    path: "sheet-create",
    component: SheetEditor,
  },
  {
    name: "sheet-edit",
    path: "sheet-edit",
    component: SheetPage,
  },
  {
    name: "sheets-task-create",
    path: "sheet-task-create",
    component: SheetsTaskEditor,
  },
  {
    name: "sheets-task-edit",
    path: "sheet-task-edit",
    component: SheetsTaskEditor,
  },
];

const dictations = [
  { path: "dictations", name: "dictations", component: JDictations },
  {
    name: "dictation-create",
    path: "dictations/create",
    component: DictationEditor,
  },
  {
    name: "dictation-edit",
    path: "dictations/edit",
    component: DictationPage,
  },
  {
    name: "foreign-dictation-create",
    path: "foreign-dictations/create",
    component: ForeignDictationEditor,
  },
  {
    name: "foreign-dictation-edit",
    path: "foreign-dictations/edit",
    component: ForeignDictationPage,
  },
];

const teacherJournal = {
  path: "/teacher-journal",

  component: JLayout,

  children: [
    {
      name: "teacher-journal",
      path: "table",
      component: JMainPage,
    },
    {
      name: "teacher-journal-create",
      path: "create",
      component: JEditor,
    },
    {
      name: "teacher-journal-edit",
      path: "edit",
      component: JEditor,
    },
    ...sheets,
    ...dictations,
  ],
};

export const journal = [teacherJournal, journalsList, commonRatingSheets];
