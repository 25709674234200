<template>
  <tr>
    <td>{{ child.child.lastname }} {{ child.child.firstname }}</td>

    <td
      :class="{
        error: v$.wordsCount.$errors.length,
      }"
    >
      <v-text-field
        v-model="v$.wordsCount.$model"
        type="number"
        density="compact"
        variant="outlined"
        hide-details
        bg-color="white"
      />
      <div
        class="input-errors"
        v-for="error of v$.wordsCount.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </td>

    <td
      :class="{
        error: v$.failsCount.$errors.length,
      }"
    >
      <v-text-field
        v-model="v$.failsCount.$model"
        type="number"
        density="compact"
        variant="outlined"
        hide-details
        bg-color="white"
      />
    </td>

    <td>
      {{ Math.round(level) }}
    </td>

    <td>
      <ChildCompetenceEditor v-model="failCompetenceList" />
    </td>
  </tr>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { maxValue, minValue, numeric } from "@vuelidate/validators";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { debounce } from "@/util/common";

import ChildCompetenceEditor from "@/components/ChildCompetenceEditor.vue";
import { useJournalData } from "@/composables/journalData";

const rules = {
  wordsCount: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
    numeric,
  },
  failsCount: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
    numeric,
  },
};

const props = defineProps({
  child: {
    type: Object,
    required: true,
  },
});

const { showError, showToastPopup } = usePopupStore();
const { updateDictations } = useJournalData();

const wordsCount = ref(null);
const failsCount = ref(null);
const level = ref(null);
const failCompetenceList = ref([]);

watch(() => props.child, setCurrentData, { immediate: true });

function setCurrentData() {
  if (!props.child) {
    return;
  }
  wordsCount.value = props.child.wordCount;
  failsCount.value = props.child.fuckupCount;
  level.value = props.child.resultLevel;
  failCompetenceList.value = props.child.failCompetenceList;
}

const v$ = useVuelidate(rules, {
  wordsCount: wordsCount,
  failsCount: failsCount,
});
const debouncedEdit = debounce(editChildsResult, 1000);

async function editChildsResult() {
  if (v$.value.$invalid) {
    v$.value.$touch();
    return;
  }
  try {
    await editChildsResultHandler();
    showToastPopup("Сохранено");
    updateDictations();
  } catch (e) {
    showError(e);
  }
}

async function editChildsResultHandler() {
  return customAxios.put(`/foreign_dictation/child/${props.child.id}`, {
    fuckupCount: Number(failsCount.value),
    wordCount: Number(wordsCount.value),
    resultLevel: level.value,
    failCompetenceList: failCompetenceList.value,
  });
}

watch(failsCount, async (v) => {
  if (!props.child || props.child.fuckupCount === v) {
    return;
  }
  debouncedEdit();
});

watch(wordsCount, async (v) => {
  if (!props.child || props.child.wordCount === v) {
    return;
  }
  debouncedEdit();
});

watch(
  failCompetenceList,
  async (v) => {
    if (!props.child || props.child.failCompetenceList === v) {
      return;
    }
    editChildsResult();
  },
  { deep: true }
);
</script>
