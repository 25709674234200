<template>
  <tr v-if="child">
    <td>{{ child.child.lastname }} {{ child.child.firstname }}</td>
    <td v-if="viewOnly === true">
      <b>{{ level }}</b>
    </td>
    <td v-else class="cell-with-input">
      <v-select
        :items="[1, 2, 3, 4, 5]"
        v-model="level"
        variant="outlined"
        bg-color="white"
        hide-details
      />
    </td>

    <td class="h-100 w-100">
      <v-list v-if="viewOnly === true">
        <v-list-item
          v-for="f in fails"
          :key="f"
          :subtitle="f.chapter.competenceChapterName"
        >
          <template v-slot:title>
            <p>
              {{ f.competenceName }}
            </p>
          </template>
        </v-list-item>
      </v-list>

      <ChildCompetenceEditor v-else v-model="fails" />
    </td>
  </tr>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";

import ChildCompetenceEditor from "@/components/ChildCompetenceEditor.vue";
import { useJournalData } from "@/composables/journalData";

const props = defineProps({
  child: {
    type: Object,
    required: true,
  },
  viewOnly: {
    type: Boolean,
    required: false,
  },
});

const { showError, showToastPopup } = usePopupStore();
const { updateDictations } = useJournalData();

const level = ref(null);
const fails = ref([]);

watch(props.child, setCurrentData, { immediate: true });

function setCurrentData() {
  if (!props.child) {
    return;
  }
  level.value = props.child.dictationLevel;
  fails.value = props.child.failCompetenceList;
}

async function editChildsResult() {
  try {
    await editChildsResultHandler();
    showToastPopup("Сохранено");
    updateDictations()
  } catch (e) {
    showError(e);
  }
}

async function editChildsResultHandler() {
  return customAxios.put(`/dictation/child/${props.child.id}`, {
    dictationLevel: level.value,
    childId: props.child.id,
    failCompetenceList: fails.value,
  });
}

watch(level, async (v) => {
  if (props.child && v !== props.child.dictationLevel) {
    editChildsResult();
  }
});

watch(
  fails,
  (v) => {
    if (props.child && v !== props.child.failCompetenceList) {
      editChildsResult();
    }
  },
  { deep: true }
);
</script>

<style>
.wrap-text {
  white-space: unset !important;
}
</style>
