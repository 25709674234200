<template>
  <CustomForm
    title="журнала"
    @create="create"
    @remove="remove"
    :saveIsDisable="saveIsDisable"
  >
    <div
      :class="{
        error: v$.subjectId.$errors.length,
        success:
          !v$.subjectId.$errors.length &&
          v$.subjectId.$model &&
          v$.subjectId.$dirty,
      }"
      class="form__item"
    >
      <SelectorOfSubjects
        v-model="v$.subjectId.$model"
        :disabled="$route.name.includes('edit')"
      />
      <div
        class="input-errors"
        v-for="error of v$.subjectId.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div
      :class="{
        error: v$.groupId.$errors.length,
        success:
          !v$.groupId.$errors.length && v$.groupId.$model && v$.groupId.$dirty,
      }"
      class="form__item"
    >
      <SelectorOfGroups
        v-model="v$.groupId.$model"
        :disabled="$route.name.includes('edit')"
      />

      <div
        class="input-errors"
        v-for="error of v$.groupId.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        placeholder="Название журнала"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div
      :class="{
        error: v$.description.$errors.length,
        success:
          !v$.description.$errors.length &&
          v$.description.$model &&
          v$.description.$dirty,
      }"
      class="form__item"
    >
      <textarea
        v-model="v$.description.$model"
        type="text"
        @focus="v$.description.$touch"
        placeholder="Описание журнала"
        maxlength="250"
        class="form__input"
      ></textarea>

      <div
        :class="{
          'showing-tip': v$.description.$dirty && v$.description.$model.length,
        }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span v-if="v$.description.$model">
            {{ v$.description.$model.length }} / 250
          </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.description.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div
      :class="{
        error: v$.period.$errors.length,
        success:
          !v$.period.$errors.length &&
          v$.period.$model.length &&
          v$.period.$dirty,
      }"
      class="form__item"
    >
      <DatePicker
        v-model="period"
        range
        autoApply
        locale="ru"
        placeholder="Период журнала"
        :enable-time-picker="false"
        @focus="v$.period.$touch"
        :clearable="false"
        :format="getRangeUIFormat"
        :disabled="$route.name.includes('edit')"
      />

      <div
        class="input-errors"
        v-for="error of v$.period.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <v-switch
      v-model="isActive"
      color="success"
      :label="isActive ? 'активен' : 'не активен'"
      hide-details
    />
  </CustomForm>
</template>

<script setup>
import { computed, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

import CustomForm from "@/components/CustomForm.vue";

const rules = {
  period: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  description: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 250 символов", maxLength(250)),
  },
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
  subjectId: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  groupId: {
    required: helpers.withMessage("Обязательное поле", required),
  },
};
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { useRoute, useRouter } from "vue-router";
import { watch } from "vue";

import { useDatesChanger } from "@/composables/datesChanger";
import { storeToRefs } from "pinia";
import { debounce } from "@/util/common";
import { useJournalData } from "@/composables/journalData";

import SelectorOfSubjects from "../components/inputs/SelectorOfSubjects.vue";
import SelectorOfGroups from "@/components/inputs/SelectorOfGroups.vue";

const router = useRouter();
const route = useRoute();

const { getRangeUIFormat } = useDatesChanger();
const { updateJournal } = useJournalData();
const { journal } = storeToRefs(useSubjectsJournalStore());
const { setJournal } = useSubjectsJournalStore();

const { showToastPopup, showError, showConfirmPopup, closePopup } =
  usePopupStore();

const name = ref("");
const description = ref("");
const period = ref([]);
const isActive = ref(false);
const subjectId = ref(null);
const groupId = ref(null);

const journalData = computed(() => ({
  teacherLogName: name.value,
  teacherLogDescription: description.value,
  isActive: isActive.value,
  beginDateTime: period.value[0],
  endDateTime: period.value[1],
}));

const v$ = useVuelidate(rules, {
  name: name,
  description: description,
  period: period,
  subjectId: subjectId,
  groupId: groupId,
});

watch(
  journal,
  () => {
    if (route.name.includes("create")) {
      setJournal(null);
      return;
    }
    setCurrentData();
  },
  { immediate: true }
);

const saveIsDisable = computed(() => {
  v$.value.$touch();
  return v$.value.$invalid;
});

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();

      await removeTeacherLog();
      showToastPopup("Журнал удален");
      router.push({ name: "teacher-journal-list" });
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Вы точно хотите удалить журнал?");
}

async function removeTeacherLog() {
  return customAxios.delete(`/teacher/log/${journal.value.id}`);
}

async function create() {
  try {
    await createNewJournal();
    showToastPopup("Успешно сохранено");
    router.push({ name: "teacher-journal-list" });
  } catch (e) {
    showError(e);
  }
}

function setCurrentData() {
  if (!journal.value) {
    return;
  }

  subjectId.value = journal.value.scheduleClassId;
  groupId.value = journal.value.personGroupId;
  name.value = journal.value.teacherLogName;
  period.value = [journal.value.beginDateTime, journal.value.endDateTime];
  description.value = journal.value.teacherLogDescription;
  isActive.value = journal.value.isActive;
}

const debouncedEditJournal = debounce(editJournal, 1000);

watch(name, (n) => {
  if (!journal.value || n === journal.value.teacherLogName) {
    return;
  }
  debouncedEditJournal();
});

watch(description, (n) => {
  if (!journal.value || n === journal.value.teacherLogDescription) {
    return;
  }
  debouncedEditJournal();
});

watch(isActive, (n) => {
  if (!journal.value || n === journal.value.isActive) {
    return;
  }
  editJournal();
});

watch(period, (p) => {
  if (!journal.value) {
    return;
  }

  const periodChanged =
    p[0] !== journal.value.beginDateTime || p[1] !== journal.value.endDateTime;

  if (periodChanged) {
    editJournal();
  }
});

async function editJournal() {
  if (!journal.value) {
    return;
  }

  try {
    await editJournalHandler();
    showToastPopup("Изменения сохранены");
    updateJournal();
  } catch (e) {
    showError(e);
  }
}

async function editJournalHandler() {
  return customAxios.put("/teacher/log/" + journal.value.id, journalData.value);
}

async function createNewJournal() {
  return customAxios.post("/teacher/log", {
    scheduleClassId: subjectId.value,
    personGroupId: groupId.value,
    ...journalData.value,
  });
}
</script>
