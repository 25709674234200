<template>
  <div>
    <PageTitle title="Заметки по детям" />

    <div v-if="isTutor" class="d-flex align-center justify-end my-2">
      <router-link :to="{ name: 'note-create' }" class="accent-button"
        >Создать заметку</router-link
      >
    </div>

    <div class="page-wrapper">
      <NotesFilters />
    </div>

    <ComponentWithPagination
      :list="filteredSortedItems"
      @changeList="paginatedList = $event"
    >
      <ul class="list">
        <li v-for="n in paginatedList" :key="n.id" class="my-2 rounded">
          <router-link
            :to="{
              name: `${
                userGlobalRole === 'user'
                  ? 'child-note-page'
                  : 'tutor-note-page'
              }`,
              query: { noteId: n.id, groupId: $route.query.filterByGroupId },
            }"
            class="link pa-2 d-block"
          >
            <NotePreview :note="n" />
          </router-link>
        </li>
      </ul>
    </ComponentWithPagination>
  </div>
</template>

<script setup>
import { useNotesStore } from "@/store/notes";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useNotes } from "@/composables/notes";
import { useAuthStore } from "@/store/auth";

import ComponentWithPagination from "../ComponentWithPagination.vue";
import PageTitle from "@/elements/PageTitle.vue";

import NotesFilters from "./components/NotesFilters.vue";
import NotePreview from "./components/NotePreview.vue";

const { getFilteredSortedItems, paginatedList } = useNotes();
const { isTutor } = useAuthStore();
const { notes } = storeToRefs(useNotesStore());

const filteredSortedItems = computed(() => getFilteredSortedItems(notes.value));
</script>
