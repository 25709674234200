<template>
  <div class="page-wrapper">
    <div class="d-flex justify-space-between mb-4">
      <h3>Таблица компетенций</h3>

      <AppButton
        v-if="currentChild"
        @click="currentChildId = null"
        class="bordered-item"
        >Назад ко всем детям</AppButton
      >
    </div>
    <ChildCompetenceTable
      v-if="currentChild"
      @changeStatus="changeStatus"
      :child="currentChild"
    />
    <div v-else class="table-wrapper">
      <table class="table-custom">
        <thead>
          <tr class="table-custom__headers">
            <th></th>
            <th>
              <p>Writing</p>
              <p>Reading</p>
            </th>
            <th>Listening</th>
            <th>Speaking</th>

            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <SheetRow
            v-for="(child, i) in children"
            :key="child.id"
            :idx="i"
            :child="child"
            @editChildsResult="edit"
            @startChildEditing="currentChildId = child.id"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps } from "vue";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

import { sortByName } from "@/util/common";

import ChildCompetenceTable from "./ChildCompetenceTable.vue";
import SheetRow from "./SheetRow.vue";
import AppButton from "@/elements/AppButton.vue";
import { useJournalData } from "@/composables/journalData";

const props = defineProps({ sheet: { type: Object, required: true } });

const currentChildId = ref(null);

const { updateRatingSheets } = useJournalData();
const { showError, showToastPopup } = usePopupStore();

const children = computed(() => {
  if (!props.sheet || !props.sheet.childList.length) {
    return [];
  }

  return sortByName(
    props.sheet.childList.map((v) => ({
      ...v,
      name: `${v.child.firstname} ${v.child.lastname}`,
    }))
  );
});

const currentChild = computed(() => {
  if (!currentChildId.value || !children.value.length) {
    return;
  }
  return children.value.find((v) => v.id === currentChildId.value);
});

async function changeStatus(data) {
  try {
    await editChildsCompetenceStatus(data);
    showToastPopup("Успешно сохранено");
  } catch (e) {
    showError(e);
  }
}

async function edit(data) {
  try {
    await editChildsResult(data);
    showToastPopup("Успешно сохранено");
    updateRatingSheets();
  } catch (e) {
    showError(e);
  }
}

async function editChildsResult({ options, id }) {
  return customAxios.put(`/foreign_checklist/child/${id}`, options);
}

async function editChildsCompetenceStatus({ item, result }) {
  return customAxios.put(
    `/foreign_checklist/child/competence/${item.id}`,
    result
  );
}
</script>
