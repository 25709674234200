<template>
  <v-card class="swot-objects">
    <v-card-title class="bg-deep-purple-lighten-2 d-flex justify-center">
      <h4>Предметы</h4>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <h3 v-if="currentChild">
            Ученик: {{ currentChild.firstname }} {{ currentChild.lastname }}
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="swot-objects__table table-default">
            <table>
              <thead>
                <th rowspan="2"></th>
                <th></th>
                <th colspan="2">Уровни</th>
                <th
                  colspan="2"
                  rowspan="2"
                  v-if="classLevelList.length || isObjectRateEditorShowed"
                ></th>
              </thead>
              <thead>
                <th>№</th>
                <th>Предмет</th>
                <th>Реальный</th>
                <th>Планируемый</th>
                <th
                  colspan="2"
                  v-if="classLevelList.length || isObjectRateEditorShowed"
                ></th>
              </thead>

              <tbody>
                <RateObject
                  v-for="(ro, i) in classLevelList"
                  :key="ro.id"
                  :idx="i + 1"
                  :item="ro"
                  :mode="
                    editedObject && editedObject.id === ro.id
                      ? 'editing'
                      : 'showing'
                  "
                  @removeObject="removeObject"
                  @editItem="startEditing"
                  @saveObject="$emit('saveObject', $event)"
                  @cancelEditing="editedObject = null"
                  @editObject="$emit('editObject', $event)"
                />

                <RateObject
                  v-if="isObjectRateEditorShowed && !editedObject"
                  mode="creating"
                  @close="isObjectRateEditorShowed = false"
                  @saveObject="$emit('saveObject', $event)"
                />
              </tbody>
            </table>
          </div>
          <AppButton
            v-if="!isObjectRateEditorShowed"
            class="bordered-item mt-3"
            @click="isObjectRateEditorShowed = true"
            >Добавить предмет</AppButton
          >
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <ClosingButton @close="$emit('close')" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, onMounted } from "vue";
import { useTutorStore } from "@/store/tutor";
import { storeToRefs } from "pinia";

import AppButton from "@/elements/AppButton.vue";
import RateObject from "./RateObject.vue";
import ClosingButton from "@/elements/ClosingButton.vue";

export default {
  emits: ["saveObject", "close", "removeObject", "editObject"],
  setup(_, ctx) {
    const tutorStore = useTutorStore();
    const { currentChild, currentSwot } = storeToRefs(tutorStore);

    const classLevelList = ref([]);

    const isObjectRateEditorShowed = ref(false);
    const editedObject = ref(null);

    function removeObject(id) {
      if (confirm("Вы точно хотите удалить предмет из анализа?")) {
        ctx.emit("removeObject", id);
      }
    }

    function startEditing(item) {
      editedObject.value = item;
    }

    onMounted(() => {
      if (currentSwot.value) {
        classLevelList.value = currentSwot.value.classLevelList;
      }
    });

    return {
      removeObject,
      currentChild,
      startEditing,
      editedObject,
      classLevelList,
      isObjectRateEditorShowed,
    };
  },
  components: { AppButton, RateObject, ClosingButton },
};
</script>

<style lang="scss">
.swot-objects {
  &__table {
    thead:first-child {
      th {
        border: none;
        border-bottom: 1px solid rgba($secondary, 0.3);
      }

      th:first-child {
        border: none;
        border-right: 1px solid rgba($secondary, 0.3);
      }

      th:nth-child(2) {
        border-bottom: none;
        border-right: 1px solid rgba($secondary, 0.3);
      }
    }
  }
}
</style>
