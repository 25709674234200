<template>
  <div v-if="sheet && sheet.skillListSkills.length > 0" class="page-wrapper">
    <div class="table-wrapper">
      <table class="table-custom">
        <thead>
          <tr>
            <th></th>
            <th :colspan="sheet.skillListSkills.length"></th>
            <th colspan="2">1 ур.</th>
            <th colspan="2">2 ур.</th>
            <th colspan="2">3 ур.</th>
          </tr>

          <tr class="table-custom__headers">
            <th></th>
            <th v-for="s in sheet.skillListSkills" :key="s.id">
              <p>{{ s.skillDescription }}</p>
              <p>Ур. {{ s.skillLevel }}</p>
            </th>
            <th>
              <p>баллы</p>
            </th>
            <th>
              <p>%</p>
            </th>

            <th>
              <p>баллы</p>
            </th>
            <th>
              <p>%</p>
            </th>

            <th>
              <p>баллы</p>
            </th>
            <th>
              <p>%</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(c, i) in sortedChildren"
            :key="c.id"
            @click="lastEditRowId = i"
            :class="{ 'table-custom__last-visited': lastEditRowId === i }"
          >
            <td>{{ c.child.lastname }} {{ c.child.firstname }}</td>
            <TableCell
              v-for="s in c.skills"
              :key="s.id"
              @setGrade="setGrade"
              :skill="s"
            />

            <!-- 1 level  -->
            <td>
              <span v-if="c.resultComputedTeacherGrade">
                {{ roundUp(c.resultComputedTeacherGrade) }}
              </span>
            </td>

            <td>
              <p v-if="c.resultComputedTeacherGradePercentage">
                {{ roundUp(c.resultComputedTeacherGradePercentage) }}
              </p>
            </td>

            <!-- 2 level  -->
            <td>
              <p v-if="c.resultComputedTeacherGradeLevel2">
                {{ roundUp(c.resultComputedTeacherGradeLevel2) }}
              </p>
            </td>
            <td>
              <p v-if="c.resultComputedTeacherGradePercentageLevel2">
                {{ roundUp(c.resultComputedTeacherGradePercentageLevel2) }}
              </p>
            </td>
            <!-- 3 level  -->
            <td>
              <p v-if="c.resultComputedTeacherGradeLevel3">
                {{ roundUp(c.resultComputedTeacherGradeLevel3) }}
              </p>
            </td>
            <td>
              <p v-if="c.resultComputedTeacherGradePercentageLevel3">
                {{ roundUp(c.resultComputedTeacherGradePercentageLevel3) }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps } from "vue";

import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";
import { sortByChildLastname } from "@/util/common";

import TableCell from "./TableCell.vue";

const props = defineProps({ sheet: { type: Object, required: true } });

const lastEditRowId = ref(null);

const { showToastPopup, showError } = usePopupStore();

const sortedChildren = computed(() => {
  return sortByChildLastname(props.sheet.listChildrens);
});

async function setGrade(data) {
  try {
    await setGradeHandler(data);
    showToastPopup("Выставлено");
  } catch (e) {
    showError(e);
  }
}

async function setGradeHandler(data) {
  return customAxios.put(
    `/teacher/log/skill_list/child/skill/teacher/${data.id}`,
    data
  );
}

function roundUp(digit) {
  return Math.round(digit);
}
</script>
