<template>
  <div v-if="journalDaysWithIds.length > 0">
    <TableLegend
      class="ma-2"
      :titles="{
        positive: 'Присутствие',
        negative: 'Отсутствие (любая причина)',
      }"
    />

    <div class="page-wrapper my-3">
      <h3 class="mb-3">Таблица журнала</h3>

      <div class="table-wrapper">
        <table class="table-custom">
          <thead>
            <tr class="table-custom__headers">
              <th></th>
              <th v-for="day in journalDaysWithIds" :key="day.id">
                <p>
                  {{ getDateFormatDayMonth(day.teacherLogDayDate) }}
                </p>
                <p>{{ getTimeFromDate(day.teacherLogDayDate) }}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(kid, k) in sortedChildren" :key="k">
              <td>{{ kid.name }}</td>
              <CommentVisitStatusCell
                v-for="(d, j) in kid.dates"
                :key="j"
                :item="{ ...d, childId: kid.childId }"
                @editVisitStatus="editVisitStatus"
                @editChildComment="editChildComment"
                :comment="d.teacherComment"
                :status="d.attendanceStatus"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="page-wrapper my-3">
      <h3 class="mb-3">Общий комментарий</h3>
      <div class="table-wrapper">
        <table class="table-custom">
          <thead>
            <tr class="table-custom__headers">
              <th></th>
              <th v-for="day in journalDaysWithIds" :key="day.id">
                <p>
                  {{ getDateFormatDayMonth(day.teacherLogDayDate) }}
                </p>
                <p>{{ getTimeFromDate(day.teacherLogDayDate) }}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Общий комментарий</td>

              <CommentForAll
                v-for="d in journal.days"
                :key="d.id"
                :comment="d.teacherComment"
                @send="(e) => sendCommonComment(e, d.id)"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else class="page-wrapper pa-4 text-center">
    <p>
      В выбранный период отсутствуют занятия или расписание не было заполнено в
      момент формирования журнала!
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { storeToRefs } from "pinia";

import { sortByName } from "@/util/common";
import { useDatesChanger } from "@/composables/datesChanger";
import { usePopupStore } from "@/store/popup";

import { customAxios } from "@/service/customAxios";

import TableLegend from "@/components/elements/TableLegend.vue";
import CommentForAll from "./CommentForAll.vue";
import CommentVisitStatusCell from "./CommentVisitStatusCell.vue";

const { showToastPopup, showError } = usePopupStore();
const { getDateFormatDayMonth, getTimeFromDate } = useDatesChanger();

const { journalDaysWithIds, journalKidsForTable, journal } = storeToRefs(
  useSubjectsJournalStore()
);

const sortedChildren = computed(() => {
  if (!journalKidsForTable.value.length) {
    return [];
  }
  return sortByName(journalKidsForTable.value);
});

async function editVisitStatus(data) {
  try {
    await editChildVisitStatus(data);
    showToastPopup("Статус сохранен");
  } catch (e) {
    showError(e);
  }
}

function editChildVisitStatus({ item, status }) {
  return customAxios.put(
    `/teacher/log/day/child/attendance/${item.dayChildId}`,
    {
      logAttendanceStatus: status,
    }
  );
}

async function editChildComment(data) {
  try {
    await editChildCommentHandler(data);
    showToastPopup("Комментарий сохранен");
  } catch (e) {
    showError(e);
  }
}

function editChildCommentHandler({ item, comment }) {
  return customAxios.put(`/teacher/log/day/child/${item.dayChildId}`, {
    teacherComment: comment,
    dayChildId: item.dayChildId,
    childId: item.childId,
  });
}

async function sendCommonComment(comment, id) {
  try {
    await editCommonCommentHandler(comment, id);
    showToastPopup("Общий комментарий сохранен");
  } catch (e) {
    showError(e);
  }
}

function editCommonCommentHandler(comment, id) {
  return customAxios.put(`/teacher/log/day/${id}`, { teacherComment: comment });
}
</script>
