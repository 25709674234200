<template>
  <tr>
    <td>{{ props.child.name }}</td>

    <td
      :class="{
        error: v$.writingReadingCompetenceBall.$errors.length,
      }"
    >
      <v-text-field
        v-model="v$.writingReadingCompetenceBall.$model"
        type="number"
        density="compact"
        variant="outlined"
        hide-details
        bg-color="white"
      />
    </td>

    <td
      :class="{
        error: v$.listeningCompetenceBall.$errors.length,
      }"
    >
      <v-text-field
        v-model="v$.listeningCompetenceBall.$model"
        type="number"
        density="compact"
        variant="outlined"
        hide-details
        bg-color="white"
      />
    </td>

    <td
      :class="{
        error: v$.speakingCompetenceBall.$errors.length,
      }"
    >
      <v-text-field
        v-model="v$.speakingCompetenceBall.$model"
        type="number"
        density="compact"
        variant="outlined"
        hide-details
        bg-color="white"
      />
    </td>

    <td>
      <p>{{ props.child.totalBall }}</p>
    </td>

    <td>
      <AppButton
        @click="$emit('startChildEditing', props.child)"
        :class="haveResults ? 'bordered-item bordered-item--reversed' : 'bordered-item'"
      >
        <CIcon icon="pen-icon" />
      </AppButton>
    </td>
  </tr>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { maxValue, minValue } from "@vuelidate/validators";

import { debounce } from "@/util/common";

import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

const rules = {
  writingReadingCompetenceBall: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
  },
  speakingCompetenceBall: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
  },
  listeningCompetenceBall: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
  },
};

const emits = defineEmits(["editChildsResult", "startChildEditing"]);

const props = defineProps({
  child: {
    type: Object,
    required: true,
  },
});

const writingReadingCompetenceBall = ref(0);
const speakingCompetenceBall = ref(0);
const listeningCompetenceBall = ref(0);

const haveResults = computed(() => {
  if (!props.child) {
    return false;
  }

  const haveChanges = [
    ...props.child.writingReadingCompetenceStatuses,
    ...props.child.speakingCompetenceStatuses,
    ...props.child.listeningCompetenceStatuses,
  ].filter((v) => v.workingTowards !== v.achieved);

  return haveChanges.length > 0;
});

const v$ = useVuelidate(rules, {
  writingReadingCompetenceBall: writingReadingCompetenceBall,
  speakingCompetenceBall: speakingCompetenceBall,
  listeningCompetenceBall: listeningCompetenceBall,
});

const debouncedEdit = debounce(editChildsResult, 1000);

watch(writingReadingCompetenceBall, (v) => {
  if (v === props.child.writingReadingCompetenceBall) {
    return;
  }
  debouncedEdit();
});

watch(speakingCompetenceBall, (v) => {
  if (v === props.child.speakingCompetenceBall) {
    return;
  }
  debouncedEdit();
});

watch(listeningCompetenceBall, (v) => {
  if (v === props.child.listeningCompetenceBall) {
    return;
  }
  debouncedEdit();
});

watch(() => props.child, setCurrentData, { immediate: true });

function setCurrentData() {
  if (!props.child) {
    return;
  }
  speakingCompetenceBall.value = props.child.speakingCompetenceBall;
  writingReadingCompetenceBall.value = props.child.writingReadingCompetenceBall;
  listeningCompetenceBall.value = props.child.listeningCompetenceBall;
}

function editChildsResult() {
  if (v$.value.$invalid) {
    return;
  }

  emits("editChildsResult", {
    id: props.child.id,
    options: {
      speakingCompetenceBall: speakingCompetenceBall.value,
      listeningCompetenceBall: listeningCompetenceBall.value,
      writingReadingCompetenceBall: writingReadingCompetenceBall.value,
    },
  });
}
</script>
