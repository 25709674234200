<template>
  <LoaderRing v-if="loadingStatus === 'loading'" />
  <router-view v-else @updateList="updateList"></router-view>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useWorkshopStore } from "@/store/workshop";
import { customAxios } from "@/service/customAxios";

import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";

import LoaderRing from "@/elements/LoaderRing.vue";

const route = useRoute();
const { showError } = usePopupStore();
const { setWorkshops } = useWorkshopStore();

const loadingStatus = ref("nothing");

const options = computed(() => ({
  studyYearId: Number(route.query.filterByYear),
}));

watch(() => route.query.filterByYear, updateList);

onMounted(updateList);

async function updateList() {
  if (!route.query.filterByYear) {
    return;
  }
  loadingStatus.value = "loading";

  try {
    const r2 = await getAllPublishedWorkshops();
    setWorkshops(r2.data);
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getAllPublishedWorkshops() {
  return customAxios.post("/workshop/published/all", options.value);
}
</script>
