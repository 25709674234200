<template>
  <div class="albums-list">
    <ul class="d-flex flex-wrap">
      <li v-for="album in published" :key="album.id" class="elevation-3">
        <router-link
          :to="{ name: 'album-photos', params: { albumId: album.id } }"
        >
          <img
            v-if="album.albumPhotos && album.albumPhotos.length"
            :src="
              'https://new-school.geekbase.ru/photo/' +
              album.albumPhotos[0].fileName
            "
          />
          

          <p>{{ album.albumName }} {{ album.albumDescription }}</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useGalleryStore } from "@/store/gallery";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const { albums } = storeToRefs(useGalleryStore());
const published = computed(() =>
  albums.value.length ? albums.value.filter((v) => v.isPublished === true) : []
);
</script>

<style lang="scss">
.albums-list {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  li {
    margin: 4px;
    width: 220px;
    height: 220px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    background-image: url('@/assets/school-pattern.png');
    background-size: auto 100%;

    p {
      position: absolute;
      z-index: 10;
      color: #fff;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba($main-1, 0.5);
      transition: all 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 4px;
    }
  }
}
</style>
