<template>
  <v-select
    v-if="subjectsSelector.length"
    v-model="selectedSchoolSubjectId"
    :items="subjectsSelector"
    variant="outlined"
    density="compact"
    hide-details
    placeholder="Предмет"
    bg-color="white"
    :clearable="true"
  >
  </v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";
import { onMounted, watch } from "vue";
import { useSchoolSubjectsData } from "@/composables/schoolSubjects";
import { useRoute } from "vue-router";

const route = useRoute();

const { subjectsSelector, getSchoolSubjects, selectedSchoolSubjectId } =
  useSchoolSubjectsData();

onMounted(async () => {
  await getSchoolSubjects();
  setCurrentFilter();
});

watch(selectedSchoolSubjectId, (id) =>
  id
    ? pushFilterToURL({ name: "filterBySubjectId", value: id })
    : removeFilterFromURL("filterBySubjectId")
);

watch(() => route.query.filterBySubjectId, setCurrentFilter);

function setCurrentFilter() {
  if (!route.query.filterBySubjectId || !subjectsSelector.value.length) {
    return;
  }

  const isExisted =
    subjectsSelector.value.findIndex(
      (v) => v.value === Number(route.query.filterBySubjectId)
    ) >= 0;

  selectedSchoolSubjectId.value = isExisted
    ? Number(route.query.filterBySubjectId)
    : null;
}
</script>
