<template>
  <div>
    <PageTitle title="SWOT" />
    <div v-if="currentChild">
      <div class="d-flex justify-end mb-2">
        <router-link
          :to="{
            name: 'tutor-child-edit',
            params: {
              childId: $route.params.childId,
            },
            query: {
              groupId: $route.query.filterByGroupId,
            },
          }"
          class="accent-button"
          >Редактировать</router-link
        >
      </div>

      <div class="page-wrapper">
        <div class="d-flex align-center">
          <AvatarImage :link="currentChild.personAvatar" />
          <span
            >{{ currentChild.firstname }} {{ currentChild.secname }}
            {{ currentChild.lastname }}</span
          >
        </div>
      </div>

      <div class="page-wrapper my-3">
        <h3 class="mb-2">О ребёнке</h3>
        <ul class="list d-flex flex-wrap">
          <li>
            <router-link
              :to="{
                name: 'tutor-child-notes',
                query: {
                  filterByChildId: $route.query.filterByChildId,
                  filterByGroupId: $route.query.filterByGroupId,
                },
              }"
              class="bordered-item ma-1 ml-0"
              >Заметки
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'child-swot',
                params: {
                  childId: $route.params.childId,
                },
              }"
              class="bordered-item ma-1"
              >SWOT
            </router-link>
          </li>
        </ul>
      </div>

      <div class="page-wrapper my-3">
        <h3 class="mb-2">Отчёты</h3>
        <ul class="list d-flex flex-wrap">
          <li>
            <router-link
              :to="{
                name: 'i-studio-report-by-child',
                query: {
                  filterByChildId: $route.params.childId,
                  filterByGroupId: $route.params.groupId,
                },
              }"
              class="bordered-item ma-1 ml-0"
              >Я-Студия</router-link
            >
          </li>

          <li>
            <router-link
              :to="{
                name: 'workshop-child-report',
                query: {
                  filterByChildId: $route.params.childId,
                  filterByGroupId: $route.params.groupId,
                },
              }"
              class="bordered-item ma-1"
              >Мастерские</router-link
            >
          </li>

          <li>
            <router-link
              :to="{
                name: 'workshop-child-report',
                query: {
                  filterByChildId: $route.params.childId,
                  filterByGroupId: $route.params.groupId,
                },
              }"
              class="bordered-item ma-1"
              >Русский по уровню усвоения</router-link
            >
          </li>
        </ul>
      </div>

      <div class="page-wrapper my-3">
        <h3 class="mb-3">Ожидаемые и текущие уровни освоения предметов</h3>
        <LevelsChart :childId="$route.params.childId" class="mt-3" />
      </div>

      <div class="page-wrapper my-3">
        <div class="mb-5">
          <h3 class="mb-3">Уровни освоения предметов в динамике</h3>
          <div class="g-col g-col--3-1fr">
            <FilterBySubject />
          </div>
        </div>

        <ChildMetricsBySybject :childId="$route.params.childId" class="mt-3" />
      </div>
    </div>

    <LoaderRing v-else />
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useChildData } from "@/composables/childProfile";

import LoaderRing from "@/elements/LoaderRing.vue";
import AvatarImage from "@/elements/AvatarImage.vue";
import LevelsChart from "@/usersPages/LevelsChart.vue";

import ChildMetricsBySybject from "@/usersPages/ChildMetricsBySybject.vue";
import FilterBySubject from "@/components/filtersElements/FilterBySubject.vue";
import PageTitle from "@/elements/PageTitle.vue";

const { updateCurrentChild, currentChild } = useChildData();

onMounted(updateCurrentChild);
</script>
