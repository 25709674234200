<template>
  <LoaderRing v-if="loadingStatus === 'loading'" />
  <router-view v-else></router-view>
</template>

<script setup>
import { watch } from "vue";
import { useWorkshopsData } from "@/composables/workshops";
import { useRoute } from "vue-router";

import LoaderRing from "@/elements/LoaderRing.vue";

const route = useRoute();
const { updateWorkshopsForMaster, loadingStatus } = useWorkshopsData();

watch(() => route.query.filterByYear, updateWorkshopsForMaster, {
  immediate: true,
});
</script>
