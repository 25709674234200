<template>
  <div v-if="photos.length > 0" class="w-last-photos wrapper my-10">
    <h2 class="text-center mb-5">Эвриканские фотографии</h2>

    <Swiper
      :slides-per-view="3"
      :modules="[Autoplay]"
      :autoplay="true"
      :speed="1000"
      delay="2000"
      effect="slide"
      class="w-last-photos__swiper"
      :breakpoints="{
        '320': {
          slidesPerView: 1,
          spaceBetween: 8,
        },
        '480': {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        '768': {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      }"
    >
      <SwiperSlide class="swiper-slide" v-for="(s, i) in photos" :key="i">
        <router-link
          :to="{ name: 'album-photos', params: { albumId: s.albumId } }"
          class="w-last-photos__wrapper"
        >
          <img
            :src="'https://new-school.geekbase.ru/photo/' + s.lazyFileName"
          />
        </router-link>
      </SwiperSlide>
    </Swiper>

    <div class="d-flex justify-center mt-5">
      <router-link :to="{ name: 'gallery-albums' }" class="bordered-item"
        >К фотоальбомам</router-link
      >
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { customAxios } from "@/service/customAxios";
import { onMounted, ref } from "vue";

const photos = ref([]);

onMounted(getPhotos);

async function getPhotos() {
  const r = await customAxios.get("/album/photo/last_fresh");
  photos.value = r.data;
}
</script>

<style lang="scss">
.w-last-photos {
  &__swiper {
    border-radius: 16px;
    overflow: hidden;
  }

  &__wrapper {
    height: 300px;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
