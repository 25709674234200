<template>
  <div>
    <div class="d-flex align-center justify-space-between mt-10 mb-4">
      <h3 class="mr-4">Дни мастерской с темами</h3>
      <router-link
        class="accent-button"
        :to="{
          name: 'theme-create',
          params: { workshopId: $route.params.workshopId },
          query: {
            filterByYear: $route.query.filterByYear,
          },
        }"
        >Добавить</router-link
      >
    </div>

    <ul v-if="list.length" class="list">
      <li v-for="day in list" :key="day.id" class="gradient-list-item">
        <router-link
          class="gradient-list-item__content"
          :to="{
            name: 'theme-edit',
            params: { themeId: day.id, workshopId: $route.params.workshopId },
            query: {
              filterByYear: $route.query.filterByYear,
            },
          }"
        >
          <v-label v-if="day.eventDateTimeBegin">{{ getDateFormatWithTime(day.eventDateTimeBegin) }}</v-label>
          <h4 v-if="day.eventSubject">{{ day.eventSubject }}</h4>
          <p v-if="day.eventComment">{{ day.eventComment }}</p>
        </router-link>
      </li>
    </ul>
    <p v-else>Пока нет тем дня</p>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

defineProps({ list: { type: Array, required: false } });

const { getDateFormatWithTime } = useDatesChanger();
</script>
