<template>
  <div>
    <router-link
      :to="{
        name: 'sheets',
        query: {
          filterByJournalId: $route.query.filterByJournalId,
          dayId: $route.query.dayId,
        },
      }"
      class="bordered-item mb-3"
      >Назад</router-link
    >
    <div v-if="sheet">
      <SheetEditor :sheet="sheet" />
      <FinalRatingTable class="my-5" :sheet="sheet" />
      <SheetTasks class="my-5" :sheet="sheet" />
    </div>
  </div>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";

import { useJournalData } from "@/composables/journalData";
import { storeToRefs } from "pinia";

import SheetEditor from "./SheetEditor.vue";
import SheetTasks from "./SheetTasks.vue";
import FinalRatingTable from "./FinalRatingTable.vue";

const route = useRoute();
const { ratingSheets } = storeToRefs(useSubjectsJournalStore());
const { updateRatingSheets } = useJournalData();

onMounted(updateRatingSheets);

const sheet = computed(() => {
  if (!ratingSheets.value.length) {
    return null;
  }

  return ratingSheets.value.find((v) => v.id === Number(route.query.listId));
});
</script>
