<template>
  <div>
    <h4 class="mb-2">Компетенции ученика: {{ child.name }}</h4>

    <div v-if="isEmpty">
      <p>Добавьте умения в оценочный лист</p>
    </div>

    <div
      v-if="child.writingReadingCompetenceStatuses.length"
      class="table-default foreign-rating-sheet__table my-5"
    >
      <table>
        <thead>
          <tr>
            <th>Writing/reading</th>
            <th>Working towards</th>
            <th>Achieved</th>
          </tr>
        </thead>
        <tbody>
          <ChildCompetenceRow
            v-for="c in child.writingReadingCompetenceStatuses"
            :key="c.id"
            :item="c"
            @changeStatus="$emit('changeStatus', $event)"
          />
        </tbody>
      </table>
    </div>

    <div
      v-if="child.listeningCompetenceStatuses.length"
      class="table-default foreign-rating-sheet__table my-5"
    >
      <table>
        <thead>
          <tr>
            <th>Listening</th>
            <th>Working towards</th>
            <th>Achieved</th>
          </tr>
        </thead>
        <tbody>
          <ChildCompetenceRow
            v-for="c in child.listeningCompetenceStatuses"
            :key="c.id"
            :item="c"
            @changeStatus="$emit('changeStatus', $event)"
          />
        </tbody>
      </table>
    </div>

    <div
      v-if="child.speakingCompetenceStatuses.length"
      class="table-default foreign-rating-sheet__table"
    >
      <table>
        <thead>
          <tr>
            <th>Speaking</th>
            <th>Working towards</th>
            <th>Achieved</th>
          </tr>
        </thead>
        <tbody>
          <ChildCompetenceRow
            v-for="c in child.speakingCompetenceStatuses"
            :key="c.id"
            :item="c"
            @changeStatus="$emit('changeStatus', $event)"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue";
import ChildCompetenceRow from "./ChildCompetenceRow.vue";
defineEmits(["changeStatus"]);

const props = defineProps({
  child: {
    type: Object,
    required: true,
  },
});


// const writingReadingCompetenceStatuses = ref([]);
// const speakingCompetenceStatuses = ref([]);
// const listeningCompetenceStatuses = ref([]);

const isEmpty = computed(
  () =>
    props.child.writingReadingCompetenceStatuses.length +
      props.child.speakingCompetenceStatuses.length +
      props.child.listeningCompetenceStatuses.length ===
    0
);

// watch(() => props.child, setCurrentData, { immediate: true });

// setCurrentData()

// function setCurrentData() {
//   if (!props.child) {
//     return;
//   }
//   speakingCompetenceStatuses.value = props.child.speakingCompetenceStatuses;
//   writingReadingCompetenceStatuses.value =
//     props.child.writingReadingCompetenceStatuses;
//   listeningCompetenceStatuses.value = props.child.listeningCompetenceStatuses;
// }
</script>
