<template>
  <div>
    <AppButton @click="escape" class="bordered-item mb-3">Назад</AppButton>

    <CustomForm title="темы дня" @remove="remove" @create="create">
      <div v-if="currentTheme" class="form__item">
        <div class="form__label">Дата</div>
        <p>{{ getDateFormatWithoutTime(currentTheme.eventDateTimeBegin) }}</p>
      </div>

      <div v-else class="form__item">
        <div class="form__label">Дата:</div>

        <DatePicker
          inline-with-input
          v-model="date"
          autoApply
          locale="ru"
          :enable-time-picker="false"
          ignore-time-validation
          :disabled-week-days="[6, 0]"
          :allowedDates="allowedDates"
          :start-date="allowedDates[0]"
          :format="getOneDayUIFormat"
          class="mb-4"
        ></DatePicker>
      </div>

      <div class="form__item">
        <div class="form__label">Время начала</div>
        <v-select
          v-model="commonStartTime"
          :items="['15:30', '17:00']"
          variant="outlined"
          density="compact"
          hide-details
        >
        </v-select>
      </div>

      <div class="form__item">
        <div class="form__label">Тема:</div>
        <textarea
          v-model="v$.theme.$model"
          type="text"
          @focus="v$.theme.$touch"
          placeholder="Тема"
          maxlength="100"
          class="form__input"
        ></textarea>
      </div>

      <div class="form__item">
        <div class="form__label">Коммент:</div>
        <textarea
          v-model="v$.comment.$model"
          type="text"
          @focus="v$.comment.$touch"
          placeholder="Коммент"
          maxlength="250"
          class="form__input"
        ></textarea>
      </div>
    </CustomForm>
  </div>
</template>

<script setup>
import { useWorkshopStore } from "@/store/workshop";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

import { useRoute, useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { useDatesChanger } from "@/composables/datesChanger";

import { required, maxLength, helpers } from "@vuelidate/validators";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { eachDayOfInterval } from "date-fns";

import CustomForm from "@/components/CustomForm.vue";
import { useDatesData } from "@/composables/datesData";
import { debounce } from "@/util/common";
import { useWorkshopsData } from "@/composables/workshops";
import AppButton from "@/elements/AppButton.vue";

const rules = {
  theme: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
  comment: {
    max: helpers.withMessage("Не более 250 символов", maxLength(100)),
  },
};

const { getTime } = useDatesData();
const { getDateFormatWithoutTime, getOneDayUIFormat } = useDatesChanger();
const { setDateTimeOfBegin } = useWorkshopsData();
const { showError, showToastPopup, showConfirmPopup, closePopup } =
  usePopupStore();

const route = useRoute();
const router = useRouter();
const commonStartTime = ref("15:30");
const theme = ref(null);
const comment = ref(null);
const date = ref(null);

const v$ = useVuelidate(rules, {
  theme: theme,
  comment: comment,
});

const { getWorkshopById } = storeToRefs(useWorkshopStore());

const currentWorkshop = computed(() =>
  getWorkshopById.value(route.params.workshopId)
);

const currentTheme = computed(() => {
  if (!route.params.themeId || !currentWorkshop.value) {
    return null;
  }

  return currentWorkshop.value.workshopDays.find(
    (v) => v.id === Number(route.params.themeId)
  );
});

const debouncedEditTheme = debounce(edit, 1000);

watch(comment, (v) => {
  if (
    !currentTheme.value ||
    (currentTheme.value && currentTheme.value.eventComment === v)
  ) {
    return;
  }
  debouncedEditTheme();
});

watch(theme, (v) => {
  if (
    !currentTheme.value ||
    (currentTheme.value && currentTheme.value.eventSubject === v)
  ) {
    return;
  }

  debouncedEditTheme();
});

watch(() => route.params.themeId, setCurrentData, { immediate: true });

function setCurrentData() {
  if (!currentTheme.value) {
    return;
  }

  comment.value = currentTheme.value.eventComment;
  theme.value = currentTheme.value.eventSubject;
  commonStartTime.value = getTime(currentTheme.value.eventDateTimeBegin);
}

const allowedDates = computed(() => {
  if (!currentWorkshop.value) {
    return [];
  }
  const days = eachDayOfInterval({
    start: new Date(currentWorkshop.value.beginDateTime),
    end: new Date(currentWorkshop.value.endDateTime),
  });

  return days;
});

async function create() {
  try {
    await saveNewThemeHandler();
    showToastPopup("Тема создана");
    escape();
  } catch (e) {
    console.log(e);

    showError(e);
  }
}

async function saveNewThemeHandler() {
  return customAxios.post(`/workshop/day`, {
    eventComment: comment.value,
    eventSubject: theme.value,
    workshopId: currentWorkshop.value.id,
    eventDateTimeBegin: setDateTimeOfBegin(date.value, commonStartTime.value),
  });
}

async function edit() {
  if (!currentTheme.value) {
    return;
  }
  try {
    await editThemeHandler({
      eventComment: comment.value,
      eventSubject: theme.value,
      workshopId: currentWorkshop.value.id,
      eventDateTimeBegin: currentTheme.value.eventDateTimeBegin,
    });
    showToastPopup("Сохранено");
  } catch (e) {
    showError(e);
  }
}

async function editThemeHandler(data) {
  return customAxios.put(`/workshop/day/${currentTheme.value.id}`, data);
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();
      await removeThemeHandler();
      showToastPopup("Тема удалена");
      escape();
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить тему?");
}

async function removeThemeHandler() {
  return customAxios.delete(`/workshop/day/${currentTheme.value.id}`);
}

async function escape() {
  router.push({
    name: "workshop-edit",
    params: { workshopId: route.params.workshopId },
    query: { filterByYear: route.query.filterByYear },
  });
}
</script>
