<template>
  <section id="features" class="section-features">
    <div class="wrapper">
      <CObserverAnimate>
        <h3>Возможности школы</h3>
      </CObserverAnimate>

      <div class="section-features__cards">
        <v-card
          class="section-features__card"
          theme="none"
          v-intersect="{ handler: onIntersect1, options: opt }"
          :class="{ animated: isIntersecting1 }"
        >
          <v-card-title class="section-features__title mb-4">{{
            benefits[0].title
          }}</v-card-title>

          <p class="section-features__subtitle">{{ benefits[0].subtitle }}</p>

          <v-img :src="benefits[0].img" height="200" class="my-6"></v-img>

          <v-list lines="one">
            <v-list-subheader class="section-features__list-subheader"
              >Вы можете</v-list-subheader
            >
            <v-list-item
              v-for="(item, i) in benefits[0].list"
              :key="i"
              class="section-features__list-item pa-0 my-6"
            >
              <v-img
                src="pen.png"
                height="35"
                width="35"
                max-width="35"
                contain
                class="mx-2"
              />
              <v-list-item-title class="section-features__item-title">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card
          class="section-features__card"
          theme="none"
          v-intersect="{ handler: onIntersect2, options: opt }"
          :class="{ animated: isIntersecting2 }"
        >
          <v-card-title class="section-features__title mb-4">{{
            benefits[1].title
          }}</v-card-title>

          <p class="section-features__subtitle">{{ benefits[1].subtitle }}</p>

          <v-img :src="benefits[1].img" height="200" class="my-6"></v-img>

          <v-list lines="one">
            <v-list-subheader class="section-features__list-subheader"
              >Вы можете</v-list-subheader
            >
            <v-list-item
              v-for="(item, i) in benefits[1].list"
              :key="i"
              class="section-features__list-item pa-0 my-6"
            >
              <v-img
                src="pen.png"
                height="35"
                width="35"
                max-width="35"
                contain
                class="mx-2"
              />
              <v-list-item-title class="section-features__item-title">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card
          class="section-features__card"
          theme="none"
          v-intersect="{ handler: onIntersect3, options: opt }"
          :class="{ animated: isIntersecting3 }"
        >
          <v-card-title class="section-features__title mb-4">{{
            benefits[2].title
          }}</v-card-title>

          <p class="section-features__subtitle">{{ benefits[2].subtitle }}</p>

          <v-img :src="benefits[2].img" height="200" class="my-6"></v-img>

          <v-list lines="one">
            <v-list-subheader class="section-features__list-subheader"
              >Вы можете</v-list-subheader
            >
            <v-list-item
              v-for="(item, i) in benefits[2].list"
              :key="i"
              class="section-features__list-item pa-0 my-6"
            >
              <v-img
                src="pen.png"
                height="35"
                width="35"
                max-width="35"
                contain
                class="mx-2"
              />
              <v-list-item-title class="section-features__item-title">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </div>
  </section>
</template>

<script>
import CObserverAnimate from "@/components/observers/CObserverAnimate.vue";
const benefits = [
  {
    id: 1,
    title: `РАСПИСАНИЕ`,
    subtitle: `Электронное расписание занятий, всегда под рукой - в личном кабинете для детей и родителей.
`,
    list: [
      `Посмотреть планируемые мероприятия и задание для самостоятельной работы к ним.
`,
      `Записаться на посещение вариативного занятия.`,
      `Отменить запись в случае если ребенок решил изменить свой выбор`,
    ],
    img: `f_1.jpeg`,
  },
  {
    id: 2,
    title: `ПОРТФОЛИО`,
    subtitle: `Здесь будут собраны достижения 
обучающегося на протяжении всего процесса обучения.`,
    list: [
      `Как учитель - фиксировать достижения обучающегося в электронном портфолио.
`,
      `Как родитель - следить за достижениями своих детей в личном кабинете.`,
      `Как ученик - гордиться своими достижениями.`,
    ],
    img: `f_2.jpeg`,
  },

  {
    id: 3,
    title: `ВЕБИНАРЫ`,
    subtitle: `Организация вебинаров и вебинарных занятий доступна прямо в информационной системе.`,
    list: [
      `Организовать встречу как для узкого круга лиц, так и для больших групп.`,
      `Принять участие как с компьютера, так и с мобильных устройств.`,
      `Встречаться онлайн без установки сторонних приложений.`,
    ],
    img: `f_3.jpeg`,
  },
];
export default {
  components: { CObserverAnimate },
  data() {
    return {
      benefits,
      isIntersecting1: false,
      isIntersecting2: false,
      isIntersecting3: false,
      opt: { threshold: 0.3 },
    };
  },
  methods: {
    onIntersect1(isIntersecting) {
      if (isIntersecting) {
        this.isIntersecting1 = isIntersecting;
      }
    },
    onIntersect2(isIntersecting) {
      if (isIntersecting) {
        this.isIntersecting2 = isIntersecting;
      }
    },
    onIntersect3(isIntersecting) {
      if (isIntersecting) {
        this.isIntersecting3 = isIntersecting;
      }
    },
  },
};
</script>

<style lang="scss">
.section-features {
  padding-top: 50px;

  &__item-title {
    white-space: normal;
  }

  &__title {
    color: $main-2;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }

  &__list-subheader {
    color: $main;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 20px;
    color: rgba(#000, 0.5);
    text-align: left;
  }

  &__card.v-card {
    box-shadow: $shadow;
    width: 460px;
    margin: 16px;
    padding: 16px;

    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);

    &.animated {
      -webkit-animation: slideFade 0.4s cubic-bezier(0.12, 0, 0.39, 0) 0s 1
        forwards;
      animation: slideFade 0.4s cubic-bezier(0.12, 0, 0.39, 0) 0s 1 forwards;
    }
  }

  h3 {
    margin-bottom: 20px;
    text-align: center;
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);

    &.animated {
      -webkit-animation: slideFade 0.4s cubic-bezier(0.12, 0, 0.39, 0) 0s 1
        forwards;
      animation: slideFade 0.4s cubic-bezier(0.12, 0, 0.39, 0) 0s 1 forwards;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .v-list-item__content {
    display: flex;
  }

  @media (max-width: 768px) {
    &__card.v-card {
      margin: 16px 8px;
    }
  }

  @media (max-width: 480px) {
    &__card.v-card {
      width: 100%;
    }
  }
}
</style>
