<template>
  <StatusWrapper
    :is-data-existed="workshop !== null"
    :loadingStatus="loadingStatus"
  >
    <div class="workshop-page">
      <PageTitle v-if="workshop.workshopName" :title="workshop.workshopName" />

      <div v-if="isChild" class="d-flex justify-center my-3">
        <BookingPanel
          v-if="controllsIsVisible"
          :workshop="workshop"
          @unfollow="unfollow"
          @follow="follow"
        />
        <LoaderRing v-else />
      </div>

      <!-- <div
        v-if="isAuthor || isAdmin"
        class="d-flex justify-end my-3"
      >
        <LinkToJournal :id="workshop.id" />

        <router-link
          :to="{
            name: 'workshop-edit',
            params: { id: workshop.id },
            query: { filterByYear: $route.query.filterByYear },
          }"
          class="ma-1 accent-button"
          >Редактировать</router-link
        >
      </div> -->

      <div class="page-wrapper mt-2">
        <div v-if="isAdmin" class="d-flex align-center mb-3">
          <v-label>ID мастерской:</v-label>
          <span class="ml-2">{{ workshop.id }}</span>
        </div>

        <div class="workshop-page__info">
          <ul>
            <li v-if="workshop.master">
              <p class="mb-2">Мастер</p>

              <p>
                {{ workshop.master.firstname }}
                {{ workshop.master.sectname }}
                {{ workshop.master.lastname }}
              </p>

              <AvatarImage
                class="avatar-image--max my-5"
                :link="workshop.master.personAvatar"
                :alt="`${workshop.master.firstname} ${workshop.master.sectname} ${workshop.master.lastname}`"
              />
            </li>

            <li>
              <p>Осталось мест</p>
              <p>
                {{
                  workshop.maxChildrenCount - workshop.workshopChildrens.length
                }}
                из {{ workshop.maxChildrenCount }}
              </p>
            </li>

            <li>
              <p>Описание</p>
              <p
                v-if="
                  workshop.workshopDescription &&
                  workshop.workshopDescription.length
                "
              >
                {{ workshop.workshopDescription }}
              </p>
              <p v-else class="text-grey">Описание пока не добавлено</p>
            </li>

            <li>
              <p>Дни занятий</p>

              <div v-for="(t, i) in weekTitles" :key="i">
                <span>{{ t }}</span>
              </div>
            </li>
            <li>
              <p>Время начала</p>
              <span>{{ startOfWorkshops }}</span>
            </li>
            <li>
              <p>Период</p>
              <span class="d-flex align-center">
                {{ getDateFormatWithoutTime(workshop.beginDateTime) }} -
                {{ getDateFormatWithoutTime(workshop.endDateTime) }}</span
              >
            </li>
          </ul>

          <div class="workshop-page__calendar">
            <p>Kалендарь занятий</p>

            <DatePicker
              class="workshop-page__datepicker"
              inline
              autoApply
              locale="ru"
              :start-date="allowedDates[0]"
              :disabled-week-days="[6, 0]"
              ignore-time-validation
              :allowedDates="allowedDates"
              :enable-time-picker="false"
            />
          </div>
        </div>
      </div>
    </div>
  </StatusWrapper>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { customAxios } from "@/service/customAxios";

import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";

import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { dayOfWeekDict } from "@/util/common";

import PageTitle from "@/elements/PageTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import AvatarImage from "@/elements/AvatarImage.vue";

import BookingPanel from "./BookingPanel.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";

const { showToastPopup, showErrorWithCustomText, showError } = usePopupStore();
const { getDateFormatWithoutTime, getTimeFromDate } = useDatesChanger();
const { isChild, isAdmin } = storeToRefs(
  useAuthStore()
);

const route = useRoute();

const workshop = ref(null);
const controllsIsVisible = ref(true);
const loadingStatus = ref("nothing");

onMounted(getWorkshop);

async function getWorkshop() {
  loadingStatus.value = "loading";

  try {
    const r = await getWorkshopHandler();
    workshop.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getWorkshopHandler() {
  return customAxios.get(`/workshop/published/${route.params.workshopId}`);
}

const allowedDates = computed(() => {
  if (!workshop.value.workshopDays) {
    return [];
  }

  return workshop.value.workshopDays.map((v) => {
    return v.eventDateTimeBegin;
  });
});

const weekTitles = computed(() => {
  if (
    !workshop.value ||
    !workshop.value.workshopDays ||
    !workshop.value.workshopDays.length
  ) {
    return [];
  }
  return getUniqDaysFromArray(
    workshop.value.workshopDays,
    "eventDateTimeBegin"
  );
});

const startOfWorkshops = computed(() => {
  if (
    !workshop.value ||
    !workshop.value.workshopDays ||
    !workshop.value.workshopDays.length
  ) {
    return null;
  }
  const time = getTimeFromDate(
    workshop.value.workshopDays[0].eventDateTimeBegin
  );

  return time;
});

async function follow() {
  try {
    const r = await followWorkshop();

    if (r.status === 207) {
      showErrorWithCustomText(r.data.message);
    } else {
      showToastPopup("Вы подписаны на мастерскую");
    }

    getWorkshop();
  } catch (e) {
    showError(e);
  }
}

async function unfollow() {
  try {
    const r = await unfollowWorkshop();

    if (r.status === 207) {
      showErrorWithCustomText(r.data.message);
    } else {
      showToastPopup("Вы отписаны от мастерской");
    }

    getWorkshop();
  } catch (e) {
    showError(e);
  }
}

async function followWorkshop() {
  return customAxios.post(`/workshop/join/${workshop.value.id}`);
}

async function unfollowWorkshop() {
  return customAxios.post(`/workshop/leave/${workshop.value.id}`);
}

function getUniqDaysFromArray(array, key) {
  const days = array.map((v) => {
    const d = new Date(v[key]);

    const title = dayOfWeekDict[d.getDay()].title;

    return title;
  });

  return days.reduce((acc, item) => {
    if (acc.includes(item)) {
      return acc;
    }

    return [...acc, item];
  }, []);
}
</script>

<style lang="scss">
.workshop-page {
  h2 {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 30px;
  }

  &__dates {
    color: #93909e;
    display: flex;
    align-items: center;

    svg {
      stroke: #93909e;
      margin-right: 8px;
    }
  }

  &__calendar {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      text-transform: uppercase;
      color: $main-2;
      margin-bottom: 8px;
      font-weight: 500;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 16px;

    ul {
      list-style: none;
    }

    li + li {
      margin-top: 16px;
    }

    li {
      p:first-child {
        color: $main-2;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  &__datepicker {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  @media (max-width: 768px) {
    &__buttons {
      grid-row: 1 / 2;
      margin-left: auto;
    }

    &__info {
      grid-template-columns: 1fr;
    }
  }
}
</style>
