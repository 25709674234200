import { customAxios } from "@/service/customAxios";
import { defineStore } from "pinia";

export const useWorkshopStore = defineStore("workshop", {
  state: () => ({
    workshops: [],
  }),
  getters: {
    getWorkshopById(state) {
      return (id) => {
        if (state.workshops.length === 0) {
          return null
        }

        return state.workshops.find(v => v.id ===  Number(id))
      }
    },
  },
  actions: {
    setWorkshops(array) {
      this.workshops = array;
    },

    async getChildsWorkshops(id) {
      const r = await customAxios.get("/workshop/published/all");

      if (r.data && !r.data.length) {
        return [];
      }

      const filtered = r.data.filter((v) => {
        return findCurrentChildInWorkshopChildrens(v.workshopChildrens, id);
      });

      function findCurrentChildInWorkshopChildrens(children, id) {
        return children.findIndex((v) => v.id === id) >= 0;
      }

      this.setWorkshops(filtered);
    },
  },
});
