<template>
  <div>
    <PageTitle title="Все оценочные листы" />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>

      <template v-slot:content>
        <p class="mb-3">Здесь отобразиться список оценочных.</p>
        <p class="mb-3">Выставьте: <strong>год</strong> (это обязательно).</p>

        <p class="mb-3">
          Так же вы можете пофильтровать список по <strong>классу</strong> и
          <strong>предмету</strong>
        </p>
      </template>
    </TogglerOfWrapper>

    <div class="g-col g-col--2-1fr my-3">
      <FilterBySubject />
      <FilterByPeriod />
    </div>

    <StatusWrapper
      :isDataExisted="isDataExisted"
      :loadingStatus="loadingStatus"
    >
      <ul v-if="$route.query.filterBySubjectId === '13'">
        <li
          v-for="l in list.foreignChecklistList"
          :key="l.id"
          class="gradient-list-item"
        >
          <router-link
            class="gradient-list-item__content"
            :to="{
              name: 'foreign-sheet-edit',
              query: {
                filterByJournalId: l.teacherLogId,
                listId: l.id,
                dayId: l.teacherLogDayId,
              },
            }"
          >
            <h4 v-if="l.listDescription">{{ l.listDescription }}</h4>
            <h4 v-else>Нет названия</h4>
          </router-link>
        </li>
      </ul>

      <ul v-else>
        <li
          v-for="l in list.teacherLogSkillListList"
          :key="l.id"
          class="gradient-list-item"
        >
          <router-link
            class="gradient-list-item__content"
            :to="{
              name: 'sheet-edit',
              query: {
                filterByJournalId: l.teacherLogId,
                listId: l.id,
                dayId: l.teacherLogDayId,
              },
            }"
          >
            <h4 v-if="l.skillListDescription">
              {{ l.skillListDescription }}
            </h4>
            <h4 v-else>Нет названия</h4>
          </router-link>
        </li>
      </ul>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useRatingSheetsStore } from "@/store/rating-sheets";
import { storeToRefs } from "pinia";
import { usePopupStore } from "@/store/popup";

import StatusWrapper from "@/components/StatusWrapper.vue";
import FilterBySubject from "@/components/filtersElements/FilterBySubject.vue";
import PageTitle from "@/elements/PageTitle.vue";

import FilterByPeriod from "@/components/filtersElements/FilterByPeriod.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";

const { showError } = usePopupStore();
const { setList } = useRatingSheetsStore();
const { list } = storeToRefs(useRatingSheetsStore());

const loadingStatus = ref("nothing");
const route = useRoute();

const isDataExisted = computed(() => {
  if (!list.value) {
    return false;
  }

  if (route.query.filterBySubjectId === "13") {
    return list.value.foreignChecklistList.length > 0;
  }

  return list.value.teacherLogSkillListList.length > 0;
});

watch(() => route.query.startDate, updateList);
watch(() => route.query.endDate, updateList);
watch(() => route.query.filterBySubjectId, updateList);

onMounted(updateList);

async function updateList() {
  if (
    !route.query.startDate ||
    !route.query.endDate ||
    !route.query.filterBySubjectId
  ) {
    return;
  }

  loadingStatus.value = "loading";

  try {
    const r = await getRatingSheetsHandler();
    setList(r.data);
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getRatingSheetsHandler() {
  return customAxios.post(`/teacher/log/checklists/by_subject_and_range`, {
    beginDate: route.query.startDate + "T00:00:00.000Z",
    endDate: route.query.endDate + "T00:00:00.000Z",
    subjectId: Number(route.query.filterBySubjectId),
  });
}
</script>
