<template>
  <div>
    <router-link
      :to="{
        name: 'teacher-journal',
        query: {
          filterByJournalId: $route.query.filterByJournalId,
        },
      }"
      class="bordered-item mb-3"
      >Назад</router-link
    >

    <PageTitle :title="`Диктанты ${isForeignJournal ? 'Английский' : ''}`" />

    <FilterByDay />

    <div v-if="$route.query.dayId" class="d-flex my-3 justify-end">
      <router-link
        :to="{
          name: isForeignJournal
            ? 'foreign-dictation-create'
            : 'dictation-create',

          query: {
            filterByJournalId: $route.query.filterByJournalId,
            dayId: $route.query.dayId,
          },
        }"
        class="accent-button"
        >Создать диктант
      </router-link>
    </div>

    <StatusWrapper
      :is-data-existed="dictations.length > 0"
      :loading-status="loadingStatus"
    >
      <ul class="list">
        <li v-for="l in dictations" :key="l.id" class="gradient-list-item mt-4">
          <router-link
            class="gradient-list-item__content"
            :to="{
              name: isForeignJournal
                ? 'foreign-dictation-edit'
                : 'dictation-edit',
              query: {
                filterByJournalId: $route.query.filterByJournalId,
                dayId: $route.query.dayId,
                listId: l.id,
              },
            }"
          >
            <h4>{{ l.dictationName }}</h4>
          </router-link>
        </li>
      </ul>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useRoute } from "vue-router";

import { onUnmounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useJournalData } from "@/composables/journalData";

import StatusWrapper from "@/components/StatusWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";
import FilterByDay from "./FilterByDay.vue";

const { dictations, isForeignJournal } = storeToRefs(useSubjectsJournalStore());
const { updateDictations, loadingStatus } = useJournalData();
const { setDictations } = useSubjectsJournalStore();
const route = useRoute();

watch(() => route.query.dayId, updateDictations, { immediate: true });

onUnmounted(() => {
  setDictations([]);
});
</script>
