import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { useWorkshopStore } from "@/store/workshop";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useDatesChanger } from "./datesChanger";

export function useWorkshopsData() {
  const { showError } = usePopupStore();
  const { setWorkshops } = useWorkshopStore();
  const { addZero } = useDatesChanger();

  const loadingStatus = ref("nothing");
  const route = useRoute();

  function updateTimetableHandler({ beginDate, endDate, childId }) {
    const data = { beginDate: beginDate, endDate: endDate };

    if (childId) {
      data.childId = childId;
    }

    return customAxios.post("/workshop/schedule/by_range", data);
  }

  async function updateWorkshopsForMaster() {
    if (!route.query.filterByYear) {
      return;
    }
    loadingStatus.value = "loading";

    try {
      const r = await getAllWorkshops();
      setWorkshops(r.data);
    } catch (e) {
      showError(e);
    } finally {
      loadingStatus.value = "loaded";
    }
  }

  async function getAllWorkshops() {
    return customAxios.post("/workshop/all", {
      studyYearId: Number(route.query.filterByYear),
    });
  }

  function setDateTimeOfBegin(date, commonStartTime) {
    if (!date || !commonStartTime) {
      return null;
    }
    
    return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
      date.getDate()
    )}T${commonStartTime.split(":")[0]}:${
      commonStartTime.split(":")[1]
    }:00.000Z`;
  }

  return {
    setDateTimeOfBegin,
    updateWorkshopsForMaster,
    updateTimetableHandler,
  };
}
