// const consultations = {
//   type: "link",
//   title: "Консультации",
//   icon: "chat-icon",
//   name: "consultations",
// };

const workshopsBookingList = {
  type: "link",
  title: "Бронирование",
  icon: "circle-icon",
  name: "workshops-booking",
};

const commonCognations = {
  type: "link",
  title: "Доска достижений",
  name: "common-cognations",
};

const englishReports = {
  type: "list",
  title: "По Английскому",
  icon: "files-icon",
  links: [
    {
      name: "users-english-report",
      path: "/english-report",
      icon: "circle-icon",
      title: "Контрольные",
    },
    {
      type: "link",
      title: "Диктанты",
      icon: "circle-icon",
      path: "/english-report-dictations",
      name: "english-dictations-by-child",
    },
  ],
};

const workshopTimetable = {
  type: "link",
  title: "Мастерских",
  name: "workshops-timetable",
};

const subjectsJournal = [
  {
    type: "list",
    title: "Журнал",
    icon: "book-icon",
    links: [
      {
        type: "link",
        title: "Таблица Журнала",
        icon: "circle-icon",
        name: "teacher-journal",
      },
      {
        type: "link",
        title: "Все журналы",
        icon: "circle-icon",
        name: "teacher-journal-list",
      },
      {
        type: "link",
        title: "Все оценочные листы",
        icon: "circle-icon",
        name: "common-rating-sheets",
      },
    ],
  },
];

const iStudio = [
  {
    type: "list",
    title: "Я - студия",
    icon: "table-icon",
    links: [
      {
        type: "link",
        title: "Таблица",
        icon: "circle-icon",
        name: "studio-table",
      },
      {
        type: "link",
        title: "Список",
        icon: "circle-icon",
        name: "studios",
      },
    ],
  },
];

const mediaEditors = [
  {
    type: "title",
    title: "Редакторы медиа",
  },
  {
    type: "link",
    title: "Альбомы",
    icon: "album-icon",
    name: "admin-albums",
  },
  {
    type: "link",
    title: "Новости",
    icon: "news-icon",
    name: "admin-news",
  },
];

const schedule = [
  {
    type: "list",
    title: "Расписания",
    icon: "calendar-icon",

    links: [
      {
        type: "link",
        title: "Общее",
        icon: "circle-icon",
        name: "common-schedule",
      },
      {
        type: "link",
        title: "Сотрудника",
        icon: "circle-icon",
        name: "employee-schedule",
      },
      { ...workshopTimetable, icon: "circle-icon" },
    ],
  },
];

const russian = {
  type: "list",
  title: "Русский язык",
  icon: "files-icon",
  links: [
    {
      type: "link",
      title: "Уровень усвоения",
      icon: "circle-icon",
      name: "russian-literature-report",
      params: { subjectId: 53 },
    },

    {
      type: "link",
      title: "Диктанты",
      icon: "circle-icon",
      name: "russian-literature-dictations-report",
      params: { subjectId: 53 },
    },
  ],
};

const literature = {
  type: "list",
  title: "Литература",
  icon: "files-icon",
  links: [
    {
      type: "link",
      title: "Уровень усвоения",
      icon: "circle-icon",
      name: "russian-literature-report",
      params: { subjectId: 25 },
    },

    {
      type: "link",
      title: "Диктанты",
      icon: "circle-icon",
      name: "russian-literature-dictations-report",
      params: { subjectId: 25 },
    },
  ],
};

const reports = [
  { title: "Отчёты", type: "title" },
  {
    type: "list",
    title: "По мастерским",
    icon: "files-icon",
    links: [
      {
        type: "link",
        title: "Мастерские по классу",
        icon: "circle-icon",
        name: "tutor-workshop-reports",
      },
      {
        type: "link",
        title: "Запись на мастерские",
        icon: "circle-icon",
        name: "workshop-booking-report",
      },
      {
        type: "link",
        title: "По детям",
        icon: "circle-icon",
        name: "workshop-child-report",
      },
    ],
  },
  {
    type: "list",
    title: "По предметам",
    icon: "files-icon",
    links: [
      {
        type: "link",
        title: "Диктанты",
        icon: "circle-icon",
        name: "teacher_dictation_report",
      },
    ],
  },
  russian,
  literature,

  {
    type: "list",
    title: "По Я-студиям",
    icon: "files-icon",
    links: [
      {
        type: "link",
        title: "По студии",
        icon: "circle-icon",
        name: "i-studio-report-by-studio",
      },
      {
        type: "link",
        title: "По месяцу",
        icon: "circle-icon",
        name: "i-studio-report-by-month",
      },
      {
        type: "link",
        title: "По ребенку",
        icon: "circle-icon",
        name: "i-studio-report-by-child",
      },
    ],
  },

  {
    type: "list",
    title: "По Английскому",
    icon: "files-icon",
    links: [
      {
        type: "link",
        title: "Диктанты (по классу)",
        icon: "circle-icon",
        name: "english-dictations-by-group",
      },
      {
        type: "link",
        title: "Диктанты (по ребёнку)",
        icon: "circle-icon",
        name: "english-dictations-by-child",
      },
      {
        type: "link",
        title: "Контрольные (по критерию)",
        icon: "circle-icon",
        name: "english-by-skill-reports",
      },
      {
        type: "link",
        title: "Контрольные (по работе)",
        icon: "circle-icon",
        name: "english-by-work-reports",
      },
      {
        type: "link",
        title: "Контрольные (по ребенку)",
        icon: "circle-icon",
        name: "english-by-child-reports",
      },
    ],
  },
];

const workshopsForEmployee = {
  type: "list",
  title: "Мастерские",
  icon: "flask",
  links: [
    {
      type: "link",
      title: "Для мастера",
      icon: "circle-icon",
      name: "workshops-master-list",
    },
    {
      ...workshopsBookingList,
      title: "Бронирование",
    },
  ],
};

const tutor = [
  {
    type: "link",
    title: "Мой класс",
    icon: "users-icon",
    name: "tutor-group",
  },

  {
    type: "link",
    title: "Журналы посещения",
    icon: "doc-text-icon",
    name: "attendance-journal-list",
  },
  {
    title: "Заметки",
    type: "list",
    icon: "note-icon",

    links: [
      {
        type: "link",
        title: "По детям",
        name: "tutor-child-notes",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "По классам",
        name: "group-notes-list",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "Из журналов",
        name: "child-journals-comments",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "Создание индивидуальной",
        name: "note-create",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "Создание общеклассовой",
        name: "group-note-create",
        icon: "circle-icon",
      },
    ],
  },
];

const notes = {
  title: "Заметки",
  type: "list",
  icon: "note-icon",

  links: [
    {
      type: "link",
      title: "От тьютора",
      name: "user-notes-list",
      icon: "circle-icon",
    },
    {
      type: "link",
      title: "Из журналов",
      name: "child-journals-comments",
      icon: "circle-icon",
    },
  ],
};

const schoolLife = [
  { type: "title", title: "Школьная жизнь" },
  { type: "link", title: "Новости", icon: "news-icon", name: "news" },
  {
    type: "link",
    title: "Галерея",
    icon: "album-icon",
    name: "gallery-albums",
  },
];

export const employes = [
  // consultations,
  ...schedule,
  ...subjectsJournal,
  ...iStudio,
  ...schoolLife,
  { ...commonCognations, icon: "trophy" },
  { title: "Мастер", type: "title" },
  workshopsForEmployee,
  {
    type: "title",
    title: "Тьютор",
  },
  ...tutor,
  ...reports,
  ...mediaEditors,
];

const cognations = {
  type: "list",
  title: "Достижения",
  icon: "trophy",
  links: [
    {
      type: "link",
      title: "Управление моими достижениями",
      icon: "circle-icon",
      name: "cognations-list-mine",
    },
    { ...commonCognations, icon: "circle-icon" },
  ],
};

const active = [
  { type: "title", title: "Активности" },

  {
    type: "list",
    title: "Расписания",
    icon: "calendar-icon",

    links: [
      {
        type: "link",
        title: "Школьное",
        icon: "circle-icon",
        name: "schedule",
      },
      { ...workshopTimetable, icon: "circle-icon" },
    ],
  },
];

const progress = [
  { type: "title", title: "Прогресс" },
  { type: "link", title: "Дневник", icon: "book-icon", name: "diary" },
  {
    type: "link",
    title: "Уровни в динамике",
    icon: "chart-bar",
    name: "metrics_by_subject",
  },
  {
    type: "link",
    title: "Ожидаемые и текущие уровни",
    icon: "chart-pie",
    name: "metrics",
  },
];

const usersReports = [
  { type: "title", title: "Отчёты" },
  {
    type: "link",
    title: "Отчёт мастерские",
    icon: "files-icon",
    name: "workshop-child-report",
  },
  englishReports,
  russian,
  literature,

  {
    type: "link",
    title: "Я-студия",
    icon: "files-icon",
    name: "i-studio-report-by-child",
  },
];

export const child = [
  // consultations,
  ...active,
  {
    type: "list",
    title: "Мастерские",
    icon: "flask",
    links: [
      {
        ...workshopsBookingList,
        title: "Бронирование",
      },
      {
        type: "link",
        title: "Мои",
        icon: "circle-icon",
        name: "child-workshops",
      },
    ],
  },
  ...progress,
  cognations,
  notes,
  ...usersReports,

  ...schoolLife,
];

export const parent = [
  // consultations,

  ...active,
  {
    type: "link",
    title: "Мастерские",
    icon: "flask",
    name: "published-list",
  },

  ...progress,
  {
    type: "link",
    title: "Достижения",
    icon: "trophy",
    name: "my-children-cognations-list",
  },
  { ...commonCognations, icon: "trophy" },
  notes,
  ...usersReports,

  ...schoolLife,
];

export const admin = [
  { title: "Админ", type: "title" },
  {
    type: "list",
    title: "Пользователи",
    icon: "users-icon",
    links: [
      {
        type: "link",
        title: "Пользователи",
        icon: "circle-icon",
        name: "users",
      },
      {
        type: "link",
        title: "Группы пользователей",
        icon: "circle-icon",
        name: "user_group",
      },
      {
        type: "link",
        title: "Дети и родители",
        icon: "circle-icon",
        name: "child",
      },
    ],
  },

  {
    type: "list",
    title: "Расписание",
    icon: "table-icon",
    name: "admin-schedule",
    links: [
      {
        type: "link",
        title: "События",
        icon: "circle-icon",
        name: "events",
      },
      {
        type: "link",
        title: "Расписания",
        icon: "circle-icon",
        name: "schedules",
      },
      {
        type: "link",
        title: "Периоды",
        icon: "circle-icon",
        name: "study_years",
      },
      {
        type: "link",
        title: "Помещения",
        icon: "circle-icon",
        name: "location",
      },
      {
        type: "link",
        title: "Оснащение",
        icon: "circle-icon",
        name: "location_option",
      },
      {
        type: "link",
        title: "Занятия",
        icon: "circle-icon",
        name: "class",
      },
      {
        type: "link",
        title: "Типы занятий",
        icon: "circle-icon",
        name: "class_types",
      },
      {
        type: "link",
        title: "Заявки",
        icon: "circle-icon",
        name: "ticket",
      },
    ],
  },

  {
    type: "link",
    title: "Чеклисты",
    icon: "file-icon",
    name: "checklists",
  },

  {
    type: "link",
    title: "Компетенции",
    icon: "fire-icon",
    name: "сompetence",
  },

  {
    type: "link",
    title: "Метрики",
    icon: "chart-pie",
    name: "child-metrics-admin",
  },

  {
    type: "link",
    title: "Достижения",
    icon: "star-icon",
    name: "admin-cognation",
  },
];
