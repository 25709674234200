<template>
  <div v-if="currentWorkshop">
    <WorkshopEditor class="my-3" />

    <div class="page-wrapper">
      <h3 class="mb-4">Записавшиеся</h3>

      <ul v-if="sortedChildren.length" class="list">
        <li v-for="(child, i) in sortedChildren" :key="child.id">
          <p>{{ i + 1 }}. {{ child.lastname }} {{ child.firstname }}</p>
        </li>
      </ul>
      <p v-else>Никто пока не записался</p>
    </div>

    <WorkshopThemes class="my-3" :list="currentWorkshop.workshopDays" />
  </div>
</template>

<script setup>
import { useWorkshopStore } from "@/store/workshop";
import { useWorkshopsData } from "@/composables/workshops";
import { useRoute } from "vue-router";

import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { sortByLastName } from "@/util/common";

import WorkshopThemes from "./WorkshopThemes.vue";
import WorkshopEditor from "./WorkshopEditor.vue";

const { updateWorkshopsForMaster } = useWorkshopsData();

const route = useRoute();
const { getWorkshopById } = storeToRefs(useWorkshopStore());

const currentWorkshop = computed(() =>
  getWorkshopById.value(route.params.workshopId)
);

watch(() => route.params.workshopId, updateWorkshopsForMaster, {
  immediate: true,
});

const sortedChildren = computed(() => {
  if (!currentWorkshop.value) {
    return [];
  }
  return sortByLastName(currentWorkshop.value.workshopChildrens);
});
</script>
