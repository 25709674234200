<template>
  <td v-if="record" class="status-cell attendance-status-cell">
    <AppButton
      class="rounded text-white text-center pa-1 mb-1"
      :class="{ collision: record.haveCollision }"
      @click="setPopup(record)"
    >
      <p>поурочно</p>
    </AppButton>
    <v-select
      v-model="status"
      :items="visitStatusList"
      placeholder="Статус"
      hide-details
      density="compact"
      variant="outlined"

    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :text="item.raw.shortTitle"
          :class="{
            'bg-red': item.raw.value > 0,
            'bg-green': item.raw.value === 0,
          }"
        >
        </v-chip>
      </template>

      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="item.raw.title"></v-list-item>
      </template>
    </v-select>
  </td>
</template>

<script setup>
import AppButton from "@/elements/AppButton.vue";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { visitStatusList } from "@/util/common";
import { ref, defineProps, watch, defineEmits } from "vue";
import { useAttendanceStore } from "@/store/attendance-journal";
const { showError, showToastPopup, showInfoPopup, showWarningPopup } =
  usePopupStore();
const { setPopup } = useAttendanceStore();

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
});
const emits = defineEmits(["update"]);
const status = ref(props.record.recordValue);

watch(status, changeStatus);

async function changeStatus() {
  try {
    const r = await changeStatusHandler();

    if (r.status === 202) {
      showToastPopup("Посещение сохранено");
    } else if (r.status === 207) {
      showInfoPopup(r.data.message);
    } else {
      showWarningPopup("Что-то пошло не так");
    }

    emits("update");
  } catch (e) {
    showError(e);
  }
}

async function changeStatusHandler() {
  return customAxios.put("/attendance/day/record/" + props.record.id, {
    recordValue: status.value,
  });
}
</script>

<style lang="scss">
.attendance-status-cell {
  button {
    width: 100%;
    height: 42px;
    background-image: $positive;
    text-transform: uppercase;
    background-size: 200% auto;

    &.collision {
      background-image: $negative;
    }

    &:hover {
      background-position: right center;
    }
  }
}
</style>
