import HomePage from "@/components/HomePage.vue";
import { createRouter, createWebHistory } from "vue-router";

import { employes } from "./employesRoutes";
import { users } from "./usersRoutes";
import { common } from "./commonRoutes";

import UserLoginVue from "@/user/UserLogin.vue";
import PersonalAgreement from "@/user/PersonalAgreement.vue";
import UserAgreement from "@/user/UserAgreement.vue";
import NotFound from "@/pages/NotFound.vue";
import AccountForm from "@/user/AccountForm.vue";
import LoginWithUUID from "@/user/LoginWithUUID.vue";

export const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },

  routes: [
    { name: "home", path: "/", component: HomePage },
    { path: "/login", component: UserLoginVue },
    { path: "/login/:uuid", component: LoginWithUUID },
    { path: "/profile", component: AccountForm },
    { path: "/personal-agreement", component: PersonalAgreement },
    { path: "/user-agreement", component: UserAgreement },

    ...employes,
    ...users,
    ...common,

    { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/personal_data_politic",
    "/user_agreement",
    "/login",
  ];
  let authRequired = !publicPages.includes(to.path);
  if (to.path.includes("/login/")) {
    authRequired = false;
  }
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/");
  }
  next();
});

export function pushFilterToURL(options) {
  const filters = Array.isArray(options) ? options : [options];
  const paramsToAdd = filters.reduce((acc, filter) => {
    acc[filter.name] = filter.value;
    return acc;
  }, {});

  const currentRoute = router.currentRoute.value;
  const query = { ...currentRoute.query, ...paramsToAdd };

  router.replace({ query });
}

export function removeFilterFromURL(filterName) {
  const currentRoute = router.currentRoute;
  const query = { ...currentRoute.value.query };

  if (query[filterName]) {
    delete query[filterName];
  }

  router.replace({ query });
}
