<template>
  <div v-if="sheet">
    <h3>Критерии оценочного листа</h3>

    <div class="page-wrapper my-3">
      <h4 class="mb-3">Speaking</h4>
      <ChildCompetenceEditor v-model="speakingCompetence" />
    </div>
    <div class="page-wrapper my-3">
      <h4 class="mb-3">Listening</h4>
      <ChildCompetenceEditor v-model="listeningCompetence" />
    </div>
    <div class="page-wrapper my-3">
      <h4 class="mb-3">Writing/Reading</h4>
      <ChildCompetenceEditor v-model="writingReadingCompetence" />
    </div>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";

import { ref, watch, defineProps } from "vue";
import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";

import ChildCompetenceEditor from "@/components/ChildCompetenceEditor.vue";

const { showError, showToastPopup } = usePopupStore();

const props = defineProps({ sheet: { type: Object, required: true } });

const route = useRoute();

const writingReadingCompetence = ref(props.sheet.writingReadingCompetences);
const speakingCompetence = ref(props.sheet.speakingCompetences);
const listeningCompetence = ref(props.sheet.listeningCompetences);

watch(
  speakingCompetence,
  (v) => {
    if (v.length === 0) {
      return;
    }
    save();
  },
  { deep: true }
);

watch(
  writingReadingCompetence,
  (v) => {
    if (v.length === 0) {
      return;
    }
    save();
  },
  { deep: true }
);

watch(
  listeningCompetence,
  (v) => {
    if (v.length === 0) {
      return;
    }
    save();
  },
  { deep: true }
);

async function save() {
  if (!props.sheet) {
    return;
  }

  try {
    await saveList();
    showToastPopup("Критерии сохранены");
  } catch (e) {
    showError(e);
  }
}

async function saveList() {
  return customAxios.put("/foreign_checklist/" + route.query.listId, {
    speakingCompetences: speakingCompetence.value,
    writingReadingCompetences: writingReadingCompetence.value,
    listeningCompetences: listeningCompetence.value,
    listDescription: props.sheet.listDescription,
  });
}
</script>
