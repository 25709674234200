import { customAxios } from "@/service/customAxios";
import { capitalizeFirstLetter, areTwoDatesEqual } from "@/util/common";
import { useRoute } from "vue-router";
import { pushFilterToURL } from "@/service/router";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { usePopupStore } from "@/store/popup";
import { ref } from "vue";
import { storeToRefs } from "pinia";

export function useJournalData() {
  const loadingStatus = ref("nothing");

  const route = useRoute();
  const { setDictations, setJournal, setJournalDaysWithIds, setRatingSheets } =
    useSubjectsJournalStore();
  const { isForeignJournal } = storeToRefs(useSubjectsJournalStore());
  const { showError } = usePopupStore();

  function getDayIdByLogDays(journalDaysWithIds, date) {
    return journalDaysWithIds.filter((v) =>
      areTwoDatesEqual(v.teacherLogDayDate, date)
    )[0].teacherLogDayId;
  }

  function getDayDateByLogDayId(journalDaysWithIds, dayId) {
    const isCurrentDayDate = journalDaysWithIds.filter(
      (v) => v.teacherLogDayId === dayId
    )[0].teacherLogDayDate;

    return isCurrentDayDate;
  }

  async function saveEditedJournal({
    journalName,
    journalDescription,
    isActive,
    id,
  }) {
    const options = {
      teacherLogName: capitalizeFirstLetter(journalName),
      teacherLogDescription: capitalizeFirstLetter(journalDescription),
      isActive: isActive,
    };
    await customAxios.put(`/teacher/log/${id}`, options);
  }

  function removeEmptyArrays(arr) {
    if (!arr.length) {
      return [];
    }

    let list = [];

    arr.forEach((v) => {
      if (!v.data.length) {
        return;
      }

      list = [...list, ...v.data];
    });

    return list;
  }

  function cachSearchingData() {
    if (route.query.filterByGroupId) {
      localStorage.setItem(
        "ss-search-by-journals-children",
        route.query.filterByGroupId
      );
    }
    if (route.query.filterBySubjectId) {
      localStorage.setItem(
        "ss-search-by-journals-subject",
        route.query.filterBySubjectId
      );
    }
  }

  function parseLocalStoreSearchingData() {
    const localGroupId = localStorage.getItem("ss-search-by-journals-children");
    const localSubjectId = localStorage.getItem(
      "ss-search-by-journals-subject"
    );

    const queries = [];

    if (!route.query.filterByGroupId && localGroupId) {
      queries.push({ name: "filterByGroupId", value: localGroupId });
    }
    if (!route.query.filterBySubjectId && localSubjectId) {
      queries.push({ name: "filterBySubjectId", value: localSubjectId });
    }

    pushFilterToURL(queries);
  }

  async function updateJournal() {
    if (route.query.filterByJournalId === undefined) {
      return;
    }

    clear();
    try {
      const r = await getJournalByPeriodIdHandler();
      setJournal(r.data);
      const r2 = await getDaysIdsByJournalIdHandler();
      setJournalDaysWithIds(r2.data);
    } catch (e) {
      showError(e);
    }
  }

  async function getDaysIdsByJournalIdHandler() {
    return customAxios.get(
      `/teacher/log/log_days_by_log_id/${route.query.filterByJournalId}`
    );
  }

  async function getJournalByPeriodIdHandler() {
    return customAxios.get(
      `/teacher/log/by_id/${route.query.filterByJournalId}`
    );
  }

  function clear() {
    setJournal(null);
    setJournalDaysWithIds([]);
  }

  async function updateRatingSheets() {
    if (!route.query.dayId) {
      return;
    }
    loadingStatus.value = "loading";

    try {
      await updateJournal();
      const r = await getSheetsHandler();
      setRatingSheets(r.data);
    } catch (e) {
      showError(e);
    } finally {
      loadingStatus.value = "loaded";
    }
  }

  async function getSheetsHandler() {
    return isForeignJournal.value
      ? customAxios.get(
          `/foreign_checklist/by_teacher_log_day_id/${route.query.dayId}`
        )
      : customAxios.get(
          `/teacher/log/skill_list/by_teacher_log_day/${route.query.dayId}`
        );
  }

  async function updateDictations() {
    if (!route.query.dayId) {
      return;
    }
    loadingStatus.value = "loading";

    try {
      await updateJournal();
      const r = await getDictationsHandler();
      setDictations(r.data);
    } catch (e) {
      showError(e);
    } finally {
      loadingStatus.value = "loaded";
    }
  }

  async function getDictationsHandler() {
    return isForeignJournal.value
      ? customAxios.get(`/foreign_dictation/by_log_day/${route.query.dayId}`)
      : customAxios.get(`/dictation/by_log_day/${route.query.dayId}`);
  }

  return {
    updateDictations,
    updateRatingSheets,
    loadingStatus,
    updateJournal,
    parseLocalStoreSearchingData,
    cachSearchingData,
    removeEmptyArrays,
    saveEditedJournal,
    getDayIdByLogDays,
    getDayDateByLogDayId,
  };
}
