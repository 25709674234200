<template>
  <v-select
    v-if="periodsSelector.length"
    v-model="period"
    :items="periodsSelector"
    variant="outlined"
    density="compact"
    hide-details
    placeholder="Период не выбран"
    clearable
    persistent-clear
    bg-color="white"
  ></v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { customAxios } from "@/service/customAxios";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";

import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

const { getDateFormatWithoutTime } = useDatesChanger();
const route = useRoute();

const periods = ref([]);
const period = ref(null);

const periodsSelector = computed(() => {
  if (!periods.value.length) {
    return [];
  }
  return periods.value.map((v) => {
    return {
      value: v.id,
      title: `${getDateFormatWithoutTime(
        v.beginDateTime
      )} - ${getDateFormatWithoutTime(v.endDateTime)}`,
    };
  });
});

watch(period, (id) => {
  id
    ? pushFilterToURL({ name: "filterByJournalId", value: id })
    : removeFilterFromURL("filterByJournalId");
});

watch(() => route.query.filterByYear, getJournalsPeriods, {
  immediate: true,
});

watch(() => route.query.filterByJournalId, getJournalsPeriods, {
  immediate: true,
});
watch(() => route.query.filterByGroupId, getJournalsPeriods, {
  immediate: true,
});
watch(() => route.query.filterBySubjectId, getJournalsPeriods, {
  immediate: true,
});

function setCurrentFilter() {
  if (!route.query.filterByJournalId || !periods.value.length) {
    return;
  }

  const isExisted =
    periods.value.findIndex(
      (v) => v.id === Number(route.query.filterByJournalId)
    ) >= 0;

  period.value = isExisted ? Number(route.query.filterByJournalId) : null;
}

async function getJournalsPeriods() {
  if (!route.query.filterByGroupId || !route.query.filterBySubjectId || !route.query.filterByYear) {
    return;
  }

  try {
    const r = await customAxios.post(`/teacher/log/for_req`, {
      personGroupId: Number(route.query.filterByGroupId),
      scheduleClassId: Number(route.query.filterBySubjectId),
      studyYearId: Number(route.query.filterByYear),
    });

    periods.value = r.data;
    setCurrentFilter();
  } catch (e) {
    console.log(e);
  }
}
</script>
