<template>
  <div>
    <PageTitle title="Я-студия" />

    <div class="d-flex my-3 justify-end">
      <router-link :to="{ name: 'studio-create' }" class="accent-button"
        >Добавить студию</router-link
      >
    </div>

    <FilterByYearAndGroup />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>

      <template v-slot:content>
        <p class="mb-3">Здесь отобразится таблица.</p>
        <v-label> Выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>год</strong></li>
          <li><strong>класс</strong></li>
        </ol>

        <p class="mb-3">
          Меняйте настройки фильтра <strong>месяц</strong>, чтобы смотреть
          данные за разные месяцы
        </p>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper :loadingStatus="loading" :isDataExisted="events.length > 0">
      <div class="g-col g-col--1fr-max mt-10">
        <v-autocomplete
          :items="filteredMonth"
          v-model="selectedMonth"
          density="compact"
          variant="outlined"
          hide-details
          :style="{ 'max-width': '150px' }"
          bg-color="white"
        ></v-autocomplete>

        <TableLegend
          :titles="{
            positive: 'Зачет',
            negative: 'Незачет',
          }"
        />
      </div>

      <div class="page-wrapper mt-2">
        <div v-if="filteredEvents.length" class="table-wrapper">
          <table class="table-custom">
            <thead>
              <tr>
                <th :colspan="filteredEvents.length + 1" class="text-center">
                  <span>{{ monthDict[filteredEvents[0].monthIndex] }}</span>
                </th>
              </tr>

              <tr class="table-custom__headers">
                <th></th>
                <th
                  v-for="e in filteredEvents"
                  :key="e.id"
                  class="table-simple__result-column"
                >
                  <span v-if="e.eventName">{{ e.eventName }}</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="child in table" :key="child.childId">
                <td>{{ child.lastname }} {{ child.firstname }}</td>
                <td
                  v-for="(childEvent, i) in child.events"
                  :key="`${child.childId}-${childEvent ? childEvent.id : i}`"
                >
                  <CommentStatusCell
                    v-if="childEvent"
                    :comment="
                      childEvent.masterComment === null
                        ? ''
                        : childEvent.masterComment
                    "
                    :isChecked="childEvent.isSuccessResult"
                    :id="childEvent.id"
                    :childEvent="childEvent"
                    @editComment="editComment"
                    @editStatus="editStatus"
                  />
                  <div v-else class="d-flex justify-center align-center">
                    <CIcon class="text-grey" icon="minus-icon" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-else class="py-4">
          Нет данных для месяца '{{ selectedMonthTitle }}'
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { sortByLastName } from "@/util/common";
import { storeToRefs } from "pinia";

import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";
import { computed } from "vue";

import { useIamStudioStore } from "@/store/iamstudio";
import { useDatesData } from "@/composables/datesData";
import { useRoute } from "vue-router";

import { monthDict } from "@/util/common";

import CommentStatusCell from "@/components/CommentStatusCell.vue";
import FilterByYearAndGroup from "@/components/filtersElements/FilterByYearAndGroup.vue";
import TableLegend from "@/components/elements/TableLegend.vue";

import CIcon from "@/elements/CIcon.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";

import PageTitle from "@/elements/PageTitle.vue";

const { getEventsByMonthId, setEvents } = useIamStudioStore();
const { events } = storeToRefs(useIamStudioStore());
const { showToastPopup, showError } = usePopupStore();
const { filteredMonth, getCurrentMonth } = useDatesData();

const loading = ref("nothing");
const selectedMonth = ref(setMonth());
const route = useRoute();

watch(() => route.query.filterByYear, getData);
watch(() => route.query.filterByGroupId, getData);
watch(selectedMonth, showLoader);

function showLoader() {
  loading.value = "loading";
  setTimeout(() => {
    loading.value = "loaded";
  }, 200);
}

onMounted(getData);

const filteredEvents = computed(() => {
  if (selectedMonth.value === null) {
    return [];
  }
  return getEventsByMonthId(selectedMonth.value);
});

const table = computed(() => {
  if (!filteredEvents.value.length) {
    return [];
  }

  const children = [];

  filteredEvents.value.forEach((v) => {
    v.eventChildList.forEach((c1) => {
      if (children.find((c2) => c2.childId === c1.childId) === undefined) {
        children.push({
          firstname: c1.child.firstname,
          lastname: c1.child.lastname,
          childId: c1.child.id,
          events: [],
        });
      }
    });
  });

  const childrenWithEvents = children.map((child) => {
    filteredEvents.value.forEach((event) => {
      const childEvent = event.eventChildList.find(
        (e) => e.child.id === child.childId
      );

      if (childEvent) {
        childEvent.eventName = event.eventName;
        child.events.push(childEvent);
      } else {
        child.events.push(null);
      }
    });

    return child;
  });

  return sortByLastName(childrenWithEvents);
});

function setMonth() {
  const current = getCurrentMonth();
  let month;
  if (current === 5 || current === 6 || current === 7) {
    month = 8;
  } else {
    month = current;
  }

  return month;
}

const selectedMonthTitle = computed(() => {
  const month = filteredMonth.find(
    (v) => v.value === Number(selectedMonth.value)
  );

  return month.title;
});

async function editStatus(data) {
  try {
    await editStatusHandler(data);
    showToastPopup("Результат сохранен");
  } catch (e) {
    showError(e);
  }
}

function editStatusHandler(data) {
  return customAxios.put("/i_studio/event/child/status/" + data.id, {
    isSuccessResult: data.isChecked,
  });
}

async function editComment(data) {
  try {
    await editCommentHandler(data);
    showToastPopup("Комментарий сохранен");
  } catch (e) {
    showError(e);
  }
}

function editCommentHandler(data) {
  return customAxios.put("/i_studio/event/child/comment/" + data.id, {
    masterComment: data.comment,
  });
}

async function getData() {
  if (!route.query.filterByYear || !route.query.filterByGroupId) {
    return;
  }

  loading.value = "loading";

  try {
    const res = await getDataHandler();
    setEvents(res.data);
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getDataHandler() {
  return customAxios.post("/i_studio/event/by_study_year_and_person_group", {
    studyYearId: Number(route.query.filterByYear),
    userGroupId: Number(route.query.filterByGroupId),
  });
}
</script>
