import { customAxios } from "@/service/customAxios";
import { sortByLastName } from "@/util/common";
import { ref, computed } from "vue";

export function useChildrenGroupsData() {
  const selectedChildrenGroupId = ref(null);
  const childrenGroups = ref([]);

  const selectedChild = ref(null);

  const childrenGroupsSelector = computed(() => {
    if (!childrenGroups.value.length) {
      return [];
    }

    return childrenGroups.value.map((v) => ({
      title: v.personGroupName,
      value: v.id,
      id: v.id,
    }));
  });

  const childrenList = computed(() => {
    if (!selectedChildrenGroupId.value || !childrenGroups.value.length) {
      return [];
    }

    const children = childrenGroups.value.find(
      (v) => v.id === Number(selectedChildrenGroupId.value)
    );

    if (!children) {
      return [];
    }
    return sortByLastName(children.groupPersons);
  });

  const sortedActualGroupList = computed(() => {
    if (!childrenList.value.length) {
      return [];
    }

    return childrenList.value.map((v) => ({
      value: v.id,
      title: `${v.lastname} ${v.firstname}`,
    }));
  });

  async function getChildrenGroups() {
    try {
      const response = await customAxios.get("/user_group/childs");
      childrenGroups.value = filterByLockedstatus(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getAllChildrenGroups() {
    try {
      const response = await customAxios.get("/user_group/childs");
      childrenGroups.value = response.data;
    } catch (e) {
      console.log(e);
    }
  }

  function filterByLockedstatus(arr) {
    return arr.filter((v) => v.isLocked === false);
  }

  const existedChildrenFromCurrentGroup = computed(() => {
    if (!childrenGroups.value.length || !selectedChildrenGroupId.value) {
      return [];
    } else {
      let tempChildren = childrenGroups.value.find(
        (v) => v.id === Number(selectedChildrenGroupId.value)
      ).groupPersons;

      return tempChildren.sort(function (a, b) {
        let result =
          a.lastname < b.lastname ? -1 : a.lastname > b.lastname ? 1 : 0;
        return result;
      });
    }
  });

  async function getActiveChildGroups() {
    try {
      const r = await getActiveChildGroupsHandler();
      childrenGroups.value = filterByLockedstatus(r.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getActiveChildGroupsHandler() {
    return customAxios.get(`/user_group/childs/active`);
  }

  return {
    getAllChildrenGroups,
    getActiveChildGroups,
    childrenGroups,
    selectedChildrenGroupId,
    getChildrenGroups,
    childrenGroupsSelector,
    existedChildrenFromCurrentGroup,
    childrenList,
    sortedActualGroupList,
    selectedChild,
  };
}
