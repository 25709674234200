<template>
  <div>
    <div class="d-flex justify-end">
      <AppButton @click="updateChildren" class="accent-button mb-3"
        >Добавить ребенка</AppButton
      >
    </div>

    <div class="page-wrapper">
      <h3>Дети</h3>

      <ul class="list">
        <li
          v-for="(c, i) in sortedChildren"
          :key="c.id"
          class="d-flex align-center my-4"
        >
          <p class="mr-3">
            {{ i + 1 }}. {{ c.child.lastname }} {{ c.child.firstname }}
          </p>
          <AppButton
            @click="deleteChild(c.id)"
            class="bordered-item bordered-item--danger"
            >Удалить</AppButton
          >
        </li>
      </ul>
    </div>
    <v-dialog v-model="visible">
      <v-card class="pa-2">
        <h4 class="mb-3 text-center">Добавление нового ребенка в студию</h4>
        <div v-if="sortedActualGroupList.length">
          <v-label class="mb-2"
            >Начните вводить имя или выберите из списка</v-label
          >
          <div class="g-col g-col--2-1fr mb-4">
            <v-autocomplete
              placeholder="Ребенок"
              variant="outlined"
              :items="sortedActualGroupList"
              v-model="addedChild"
              density="compact"
            ></v-autocomplete>
          </div>

          <div class="d-flex justify-center">
            <AppButton
              @click="addChild"
              class="bordered-item mb-3"
              :disabled="!addedChild"
              >Добавить</AppButton
            >
          </div>
        </div>
        <p v-else>Список пуст...</p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import AppButton from "@/elements/AppButton.vue";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { ref, defineEmits, computed, defineProps } from "vue";

import { useChildrenGroupsData } from "@/composables/childrenGroups";

const props = defineProps({
  sortedChildren: { type: Array, required: true },
  event: { type: Object, required: true },
});
const emits = defineEmits(["update"]);

const { getChildrenGroups, childrenList, selectedChildrenGroupId } =
  useChildrenGroupsData();

const { showError, showToastPopup, showConfirmPopup, closePopup } =
  usePopupStore();

const visible = ref(false);
const addedChild = ref(null);

const sortedActualGroupList = computed(() => {
  if (!childrenList.value.length) {
    return [];
  }

  return childrenList.value.map((v) => ({
    value: v.id,
    title: `${v.lastname} ${v.firstname}`,
  }));
});

async function deleteChild(id) {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();

      await deleteChildFromStudio(id);
      showToastPopup("Ребенок удален из студии");
      emits("update");
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить ребенка из студии?");
}

async function deleteChildFromStudio(id) {
  return customAxios.delete(`/i_studio/event/child/${id}`);
}

async function updateChildren() {
  selectedChildrenGroupId.value = props.event.personGroupId;
  try {
    await getChildren();
    visible.value = true;
  } catch (e) {
    showError(e);
  }
}

async function getChildren() {
  try {
    await getChildrenGroups();
  } catch (e) {
    showError(e);
  }
}

async function addChild() {
  try {
    await addChildToEvent();
    visible.value = false;
    addedChild.value = null;
    emits("update");
  } catch (e) {
    showError(e);
  }
}

async function addChildToEvent() {
  return customAxios.post("/i_studio/event/child", {
    studioEventId: props.event.id,
    childId: addedChild.value,
    isSuccessResult: false,
  });
}
</script>
