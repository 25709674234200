import { RouterView } from "vue-router";
import { h } from "vue";
import { journal } from "./teacherJournal";

// Я студия
import IamStudioLayoutVue from "@/IamStudio/IamStudioLayout.vue";
import IStudioTableVue from "@/IamStudio/IStudioTable.vue";
import IamStudioListVue from "@/IamStudio/IamStudioList.vue";
import IamStudioEditorVue from "@/IamStudio/IamStudioEditor.vue";
import IamStudioPageVue from "@/IamStudio/IamStudioPage.vue";
import IamStudioEventEditorVue from "@/IamStudio/IamStudioEventEditor.vue";
import IamStudioEventPageVue from "@/IamStudio/IamStudioEventPage.vue";

// Расписания
import CommonSchedule from "@/components/schedule/CommonSchedule.vue";
import EmployeeSchedule from "@/components/schedule/EmployeeSchedule.vue";
import TutorChildCardVue from "@/components/tutor/TutorChildCard.vue";
import ClassesListVue from "@/components/tutor/ClassesList.vue";
import TutorChildDynamicMetricVue from "@/components/tutor/TutorChildDynamicMetric.vue";
import TutorChildSubjectMetricDynamicVue from "@/components/tutor/TutorChildSubjectMetricDynamic.vue";

// Редакторы
import AdminNewsVue from "@/components/Admin/AdminNews.vue";
import AdminPhotoVue from "@/components/Admin/AdminPhoto.vue";
// Админское
import AdminUsersPageVue from "@/components/Admin/AdminUsersPage.vue";
import AdminUserChildPageVue from "@/components/Admin/AdminUserChildPage.vue";
import AdminChecklistVue from "@/components/Admin/AdminChecklist.vue";
import CompetenceLinePageVue from "@/components/Admin/CompetenceLinePage.vue";
import ProfileChildClassLevelsVue from "@/components/Profile/ProfileChildClassLevels.vue";

// Тьютор
import ReportWorkshopByGroup from "@/reports/workshops/ReportWorkshopByGroup.vue";
import AdminCognation from "@/components/Admin/AdminCognation.vue";

import ScheduleEventList from "@/components/Admin/scheduleAdmin/ScheduleEventList.vue";
import ScheduleList from "@/components/Admin/scheduleAdmin/ScheduleList.vue";
import ScheduleStudyYearList from "@/components/Admin/scheduleAdmin/ScheduleStudyYearList.vue";
import ScheduleLocationList from "@/components/Admin/scheduleAdmin/ScheduleLocationList.vue";
import ScheduleLocationOptionList from "@/components/Admin/scheduleAdmin/ScheduleLocationOptionList.vue";
import ScheduleClassList from "@/components/Admin/scheduleAdmin/ScheduleClassList.vue";
import ScheduleClassOptionList from "@/components/Admin/scheduleAdmin/ScheduleClassOptionList.vue";
import ScheduleLocationTicketList from "@/components/Admin/scheduleAdmin/ScheduleLocationTicketList.vue";
import TutorLayout from "@/tutor/TutorLayout.vue";
import MyGroup from "@/tutor/MyGroup.vue";
import ChildProfile from "@/tutor/ChildProfile.vue";
import ReportDictation from "@/components/teacher/reports/ReportDictation.vue";
import AttendanceJournal from "@/components/tutor/attendance/AttendanceJournal.vue";
import AttendanceJournalList from "@/components/tutor/attendance/AttendanceJournalList.vue";
// import AttendanceEdit from "@/components/tutor/attendance/AttendanceEdit.vue";

// Отчёты
import ReportWorkshopBooking from "@/reports/workshops/ReportWorkshopBooking.vue";
import IStudioReportByMonth from "@/reports/i-studio/IStudioReportByMonth";
import IStudioReportByStudio from "@/reports/i-studio/IStudioReportByStudio";
import IStudioReportByChild from "@/reports/i-studio/IStudioReportByChild";
import EDictationsByGroup from "@/reports/english/EDictationsByGroup.vue";
import EDictationsByChild from "@/reports/english/EDictationsByChild.vue";
import EReportBySkill from "@/reports/english/EReportBySkill.vue";
import ChildProfileEditor from "@/tutor/ChildProfileEditor.vue";
import RReport from "@/reports/russian-literature/RReport.vue";

// Заметки
import TutorNotesLayout from "@/components/notes/TutorNotesLayout.vue";
import NoteEditor from "@/components/notes/NoteEditor.vue";
import GroupNotesLayout from "@/components/notes/GroupNotesLayout.vue";
import GroupsNotesList from "@/components/notes/GroupsNotesList.vue";
import GroupNotePage from "@/components/notes/GroupNotePage.vue";
import GroupNoteEditor from "@/components/notes/GroupNoteEditor.vue";
import ChildrenNotesList from "@/components/notes/ChildrenNotesList.vue";

// swot
import SReport from "@/swot/SReport.vue";
import SLayout from "@/swot/SLayout.vue";
import SChildSwots from "@/swot/SChildSwots.vue";

import ChildSwotsVue from "@/components/tutor/ChildSwots.vue";

import EReportByWork from "@/reports/english/EReportByWork.vue";
import EReportByChild from "@/reports/english/EReportByChild.vue";
import DReport from "@/reports/russian-literature/DReport.vue";
import TutorNotePage from "@/components/notes/TutorNotePage.vue";
import AdminUserGroupPage from "@/components/Admin/AdminUserGroupPage.vue";

// Workshop
import MWorkshopsList from "@/workshops/master/MWorkshopsList.vue";
import MWorkshopsLayout from "@/workshops/master/MWorkshopsLayout.vue";
import MWorkshopPage from "@/workshops/master/MWorkshopPage.vue";
import ThemeEditor from "@/workshops/master/ThemeEditor.vue";
import WorkshopEditor from "@/workshops/master/WorkshopEditor.vue";
import WorkshopsJournal from "@/workshops/master/WorkshopsJournal.vue";

const iStudio = {
  path: "/iamstudio",
  component: IamStudioLayoutVue,
  children: [
    {
      name: "studio-table",
      path: "studio-table",
      component: IStudioTableVue,
    },
    {
      path: "studios",
      component: IamStudioListVue,
      name: "studios",
    },
    {
      path: "studio/create",
      component: IamStudioEditorVue,
      name: "studio-create",
    },
    {
      path: "studio/edit/:id",
      component: IamStudioPageVue,
      name: "studio-edit",
    },

    {
      path: "studio/edit/:id/event/create",
      component: IamStudioEventEditorVue,
      name: "studio-event-create",
    },

    {
      path: "studio/edit/:id/event/edit/:eventId",
      component: IamStudioEventPageVue,
      name: "studio-event-edit",
    },
  ],
};

const groupsList = {
  name: "groups",
  path: "/groups",
  component: {
    render() {
      return h(`div`, [h(RouterView)]);
    },
  },
  children: [
    { path: "child/:id", component: ChildSwotsVue },
    { path: "child_card/:id", component: TutorChildCardVue },
    { name: "groups-lists", path: "list", component: ClassesListVue },
    { path: "metrics/:id", component: TutorChildDynamicMetricVue },
    {
      path: "metrics_by_subject/:id",
      component: TutorChildSubjectMetricDynamicVue,
    },
  ],
};

const admin = {
  path: "/admin",
  component: {
    render() {
      return h(`div`, [h(RouterView)]);
    },
  },
  children: [
    {
      name: "admin-cognation",
      path: "admin-cognation",
      component: AdminCognation,
    },

    { name: "users", path: "users", component: AdminUsersPageVue },
    { name: "user_group", path: "user_group", component: AdminUserGroupPage },
    { name: "child", path: "child", component: AdminUserChildPageVue },
    {
      name: "admin-schedule",
      path: "schedule",

      children: [
        {
          path: "events",
          component: ScheduleEventList,
          name: "events",
        },
        {
          path: "schedules",
          component: ScheduleList,
          name: "schedules",
        },
        {
          path: "study_years",
          component: ScheduleStudyYearList,
          name: "study_years",
        },
        {
          path: "location",
          component: ScheduleLocationList,
          name: "location",
        },
        {
          path: "location_option",
          component: ScheduleLocationOptionList,
          name: "location_option",
        },
        {
          path: "class",
          component: ScheduleClassList,
          name: "class",
        },
        {
          path: "class_types",
          component: ScheduleClassOptionList,
          name: "class_types",
        },
        {
          path: "ticket",
          component: ScheduleLocationTicketList,
          name: "ticket",
        },
      ],
    },
    { name: "checklists", path: "checklists", component: AdminChecklistVue },
    {
      name: "сompetence",
      path: "сompetence",
      component: CompetenceLinePageVue,
    },
    {
      name: "child-metrics-admin",
      path: "child-metrics-admin",
      component: ProfileChildClassLevelsVue,
    },
  ],
};

const reports = [
  {
    name: "i-studio-report-by-studio",
    path: "/i-studio-report-by-studio",
    component: IStudioReportByStudio,
  },
  {
    name: "i-studio-report-by-month",
    path: "/i-studio-report-by-month",
    component: IStudioReportByMonth,
  },
  {
    name: "i-studio-report-by-child",
    path: "/i-studio-report-by-child",
    component: IStudioReportByChild,
  },
  {
    name: "teacher_dictation_report",
    path: "/teacher_dictation_report",
    component: ReportDictation,
  },
  {
    name: "english-dictations-by-group",
    path: "/english-dictations-by-group",
    component: EDictationsByGroup,
  },
  {
    name: "english-dictations-by-child",
    path: "/english-dictations-by-child",
    component: EDictationsByChild,
  },

  {
    name: "english-by-skill-reports",
    path: "/by-skill",
    component: EReportBySkill,
  },
  {
    name: "english-by-work-reports",

    path: "/by-work",
    component: EReportByWork,

    props: (route) => ({
      id: route.query.id,
    }),
  },
  {
    name: "english-by-child-reports",
    path: "/by-child",
    component: EReportByChild,
  },

  {
    name: "russian-literature-report",
    path: "/report/:subjectId",
    component: RReport,
  },
  {
    name: "russian-literature-dictations-report",
    path: "/dictations-report/:subjectId",
    component: DReport,
  },
];

const workshops = [
  {
    name: "workshop-booking-report",
    path: "/workshop-booking-report",
    component: ReportWorkshopBooking,
  },
];

const masterWorkshop = {
  path: "/workshops-master",
  component: MWorkshopsLayout,
  children: [
    {
      name: "workshops-master-list",
      path: "/workshops-master-list",
      component: MWorkshopsList,
    },
    {
      path: "workshop/:workshopId",
      component: MWorkshopPage,
      name: "workshop-edit",
    },
    {
      path: "workshop/:workshopId/theme",
      component: ThemeEditor,
      name: "theme-create",
    },
    {
      path: "workshop/:workshopId/theme/:themeId",
      component: ThemeEditor,
      name: "theme-edit",
    },
    {
      path: "create",
      component: WorkshopEditor,
      name: "workshop-create",
    },
    {
      name: "workshop-journal",
      path: "workshop-journal/:id",
      component: WorkshopsJournal,
    },
  ],
};

const swot = {
  path: "swot-analysis",
  name: "swot-analysis",
  component: SLayout,
  children: [
    { path: "child-swot/:childId", name: "child-swot", component: SReport },
    {
      path: "list/:childId",
      name: "child-swots",
      component: SChildSwots,
    },
  ],
};

const tutorMyGroup = {
  path: "/tutor",
  name: "tutor",
  component: TutorLayout,
  children: [
    { path: "my-group", name: "tutor-group", component: MyGroup },
    {
      path: "group/tutor-child/:childId",
      name: "tutor-child",
      component: ChildProfile,
    },

    {
      path: "group/tutor-child/:childId/edit",
      name: "tutor-child-edit",
      component: ChildProfileEditor,
    },
    swot,
  ],
};

const tutor = [
  {
    path: "/attendance-journal-list",
    name: "attendance-journal-list",
    component: AttendanceJournalList,
  },
  {
    name: "attendance-journal",
    path: "/attendance-journal/:id",
    component: AttendanceJournal,
  },

  {
    name: "group-notes",
    path: "/group-notes",
    component: GroupNotesLayout,
    children: [
      {
        name: "group-note-edit",
        path: "group-note/edit",
        component: GroupNoteEditor,

      },
      {
        name: "group-note-create",
        path: "group-note/create",
        component: GroupNoteEditor,
      },
      {
        name: "group-notes-list",
        path: "list",
        component: GroupsNotesList,
 
      },

      {
        name: "group-note-page",
        path: "group-note",
        component: GroupNotePage,
      },
    ],
  },

  {
    name: "tutor-notes",
    path: "/tutor-notes",
    component: TutorNotesLayout,
    children: [
      {
        name: "tutor-note-edit",
        path: "note/edit",
        component: NoteEditor,
  
      },
      { name: "note-create", path: "note/create", component: NoteEditor },
      {
        name: "tutor-child-notes",
        path: "child-notes",
        component: ChildrenNotesList,
      },

      {
        name: "tutor-note-page",
        path: "note",
        component: TutorNotePage,
      },
    ],
  },
  {
    name: "tutor-workshop-reports",
    path: "/workshop-reports",
    component: ReportWorkshopByGroup,
  },
];

const schedules = [
  {
    name: "employee-schedule",
    path: "/employee-schedule",
    component: EmployeeSchedule,

  },
  {
    name: "common-schedule",
    path: "/common-schedule",
    component: CommonSchedule,

  },
];

const mediaEditors = [
  { name: "admin-albums", path: "/admin-albums", component: AdminPhotoVue },
  { name: "admin-news", path: "/admin-news", component: AdminNewsVue },
];

export const employes = [
  masterWorkshop,
  ...journal,
  iStudio,
  groupsList,
  admin,
  tutorMyGroup,
  ...workshops,
  ...tutor,
  ...schedules,
  ...mediaEditors,
  ...reports,
];
