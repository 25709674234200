<template>
  <div>
    <PageTitle title="Журнал посещения" />

    <div v-if="journal">
      <div class="page-wrapper">
        <h3 class="mb-3">{{ journal.name }}</h3>

        <p v-if="journal.teacherLogDescription">
          {{ journal.description }}
        </p>

        <ul class="list">
          <div class="d-flex my-2">
            <v-label class="mr-2">Класс</v-label>
            <p>{{ journal.group.personGroupName }}</p>
          </div>

          <div class="d-flex my-2">
            <v-label class="mr-2">Кол-во дней</v-label>
            <p>{{ journal.attendanceDays.length }}</p>
          </div>

          <div class="d-flex my-2">
            <v-label class="mr-2">Период</v-label>
            <p>
              {{ getDateFormatWithoutTime(journal.beginDateTime) }} -
              {{ getDateFormatWithoutTime(journal.endDateTime) }}
            </p>
          </div>
        </ul>
      </div>

      <TableLegend
        class="my-3"
        :titles="{
          positive: 'Коллизий нет',
          negative: 'Коллизии присутствуют (есть расхождения журналов)',
        }"
      />
      <div class="page-wrapper">
        <AttendanceTable :journal="journal" @update="getJournalData" />
      </div>
    </div>
    <LoaderRing v-else />

    <v-dialog v-model="isPopupVisible" scrollable persistent>
      <AttendancePopup @update="getJournalData" />
    </v-dialog>
  </div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { useRoute } from "vue-router";
import { customAxios } from "@/service/customAxios";
import { onMounted, ref } from "vue";

import TableLegend from "@/components/elements/TableLegend.vue";
import PageTitle from "@/elements/PageTitle.vue";
import AttendanceTable from "./AttendanceTable.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import AttendancePopup from "./AttendancePopup.vue";
import { useAttendanceStore } from "@/store/attendance-journal";
import { storeToRefs } from "pinia";

const { isPopupVisible } = storeToRefs(useAttendanceStore());

const route = useRoute();
const journal = ref("");

onMounted(getJournalData);

async function getJournalData() {
  try {
    const r = await getJournalHandler();
    journal.value = r.data;
  } catch (e) {
    console.log(e);
  }
}

async function getJournalHandler() {
  return customAxios.get(`/attendance/${route.params.id}`);
}

const { getDateFormatWithoutTime } = useDatesChanger();
</script>
