<template>
  <div class="table-wrapper">
    <table class="table-custom">
      <thead>
        <tr class="table-custom__headers">
          <th></th>
          <th v-for="d in props.journal.attendanceDays" :key="d.id">
            {{ getDateFormatWithoutTime(d.attendanceDayDate) }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="c in props.journal.attendanceChilds" :key="c.id">
          <td>{{ c.child.lastname }} {{ c.child.firstname }}</td>

          <AttendanceStatusCell
            v-for="d in c.dayRecords"
            :key="d.id"
            :record="d"
            @update="emits('update')"
            @openPopup="emits('openPopup', $event)"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import AttendanceStatusCell from "./AttendanceStatusCell.vue";
const {getDateFormatWithoutTime} =useDatesChanger()
const props = defineProps({ journal: { type: Object } });
const emits = defineEmits(["update", "openPopup"]);
</script>
