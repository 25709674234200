<template>
    <v-autocomplete
      v-if="mastersOptions.length"
      :items="mastersOptions"
      variant="outlined"
      placeholder="Мастер"
      item-value="value"
      density="compact"
      hide-details
      bg-color="white"
      @update:model-value="$emit('update:modelValue', $event)"
      :modelValue="modelValue"
    ></v-autocomplete>
    <div v-else class="empty-select"></div>
  </template>
  
  <script setup>
  import { useEmployeeData } from "@/composables/employeeData";
  import { onMounted, defineProps, defineEmits } from "vue";
  
  defineProps(["modelValue"]);
  defineEmits(["update:modelValue"]);
  
  const { getMasters, mastersOptions } = useEmployeeData();
  
  onMounted(getMasters);
  </script>
  