<template>
  <div v-if="journalDaysWithIds.length>0" class="page-wrapper">
    <v-label class="mb-3"
      >Для ОТОБРАЖЕНИЯ или ДОБАВЛЕНИЯ данных выберите день из списка</v-label
    >
    <div>
      <v-chip
        v-for="d in journalDaysWithIds"
        :key="d.id"
        class="ma-1"
        @click="dateInput = d.teacherLogDayId"
        :variant="d.teacherLogDayId === Number($route.query.dayId) ? 'tonal' : 'outlined'"
        color="#cc8cb5"
      >
        {{ getDateFormatWithTime(d.teacherLogDayDate) }}
      </v-chip>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";

import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useRoute } from "vue-router";

const route = useRoute();

const { getDateFormatWithTime } = useDatesChanger();
const { journalDaysWithIds } = storeToRefs(useSubjectsJournalStore());

const dateInput = ref(route.query.dayId ? Number(route.query.dayId) : null);

watch(dateInput, (day) => {
  day
    ? pushFilterToURL({ name: "dayId", value: day })
    : removeFilterFromURL("dayId");
});
</script>
