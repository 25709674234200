<template>
  <div>
    <PageTitle title="Общее расписание" />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>

      <template v-slot:content>
        <p class="mb-3">Здесь отобразится расписание.</p>

        <v-label>Выставьте</v-label>
        <ol class="pl-5">
          <li><strong>класс</strong></li>
          <li><strong>неделю</strong></li>
          <li><strong>тип занятия</strong> (необязательно)</li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <div class="g-col g-col--2-1fr mb-2">
      <FilterByGroupId />
      <WeekRangeFilter v-model="range" />
    </div>

    <div class="g-col g-col--2-1fr">
      <FilterByEventType />
    </div>

    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="weekSchedule.length > 0"
    >
      <div class="page-wrapper mt-4">
        <TimeTable :headers="daysHeaders" :weekSchedule="weekSchedule" />
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useScheduleData } from "@/composables/scheduleData";

import { usePopupStore } from "@/store/popup";
import { useDatesChanger } from "@/composables/datesChanger";

import WeekRangeFilter from "../WeekRangeFilter.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";
import TimeTable from "@/components/TimeTable.vue";

import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";
import FilterByEventType from "../filtersElements/FilterByEventType.vue";
import PageTitle from "@/elements/PageTitle.vue";

import TogglerOfWrapper from "../elements/TogglerOfWrapper.vue";

const { getCurrentWeek } = useDatesChanger();
const { getEventsByRange, getCurrentWeekEvents, weekSchedule, daysHeaders } =
  useScheduleData();
const { showError } = usePopupStore();

const loadingStatus = ref("nothing");
const range = ref(getCurrentWeek());

const route = useRoute();

watch(range, updateTable);
watch(() => route.query.filterByEventType, updateTable);
watch(() => route.query.filterByGroupId, updateTable);

onMounted(updateTable);

async function updateTable() {
  if (!route.query.filterByGroupId) {
    return;
  }

  loadingStatus.value = "loading";

  try {
    await updateTableHandler();
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function updateTableHandler() {
  const data = {
    childGroups: [Number(route.query.filterByGroupId)],
    employees: [],
  };

  if (route.query.filterByEventType) {
    data.classTypes = [Number(route.query.filterByEventType)];
  }

  if (range.value && range.value.length) {
    return getEventsByRange({
      ...data,
      beginDateTime: range.value[0],
      endDateTime: range.value[1],
    });
  } else {
    return getCurrentWeekEvents(data);
  }
}
</script>
