<template>
  <div>
    <div class="g-col g-col--2-1fr">
      <FilterByYear />
      <FilterBySubject />
      <FilterByGroupId />
      <FilterByJournalId />
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useJournalData } from "@/composables/journalData";

import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";
import FilterBySubject from "@/components/filtersElements/FilterBySubject.vue";
import FilterByJournalId from "@/components/filtersElements/FilterByJournalId.vue";
import FilterByYear from "@/components/filtersElements/FilterByYear.vue";

const route = useRoute();

const { cachSearchingData, parseLocalStoreSearchingData } = useJournalData();

onMounted(parseLocalStoreSearchingData);

watch(() => route.query, cachSearchingData);
</script>
